.news-container {
  margin-top: 3%;
  max-width: 100%;
  /* margin-left: 9%;
  margin-right: 8%; */

  background-image: url(../../../public/img/contact/contact-bars-bg.png);
  background-repeat: no-repeat;
  background-position: top;
}
.news-container .topSection .leftshadow {
  display: block;
  position: absolute;
  right: -30%;
  width: 100%;
  top: 14%;
  height: 60%;
  background-color: #ffffff57;
  opacity: 0.15;
  filter: blur(80px);
  border-radius: 50%;
  transform: rotate(355deg);
  z-index: -1;
}
@media screen and (max-width: 991px) {
  .news-container {
    width: 93%;
  }
  .news-container .topSection .leftshadow {
    right: 5%;
    width: 66%;
    top: 17%;
    height: 41%;
  }
}

/* news section -1*/
.news-header h1 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 5.5rem;
  line-height: 7.12rem;
  font-family: DrukWide-Medium-Trial;
}
.news-header h1.stext {
  padding-left: 6rem;
}
@media screen and (max-width: 991px) {
  .news-header h1 {
    font-size: 6vw;
    line-height: 1.2;
  }
  .news-header h1.stext {
    padding-left: 0rem;
  }
  .news-header h1.ftext {
    padding-left: 3rem;
  }
}
.news-section-1 {
  margin-bottom: 5%;
  padding-top: 6rem;
}
@media screen and (max-width: 991px) {
  .news-section-1 {
    padding-top: 2rem;
  }
}
.news-header-content {
  display: flex;
  justify-content: end;
  margin-right: 10rem;
}
@media screen and (max-width: 991px) {
  .news-header-content {
    margin-right: 0rem;
  }
}
.news-header-content p {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.2rem;
  font-family: IBMPlexMono-Regular;
}
.news-header-content span {
  display: block;
  width: 1.62rem;
  height: 0.1rem;

  background: #ffffff;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
}

/* news section -2*/

.news-section2-item1 {
  flex: 1;
  padding: 2rem;
}
.news-section2-item2 {
  padding: 2rem;
  flex: 1;
}

.news-section2-item3 {
  padding: 2rem;

  flex: 1;
}

.news-section2-item4 {
  padding: 2rem;

  flex: 1;
}

.news-section-2 {
  margin-bottom: 5%;
  display: flex;
  justify-content: end;
}

.item1 {
  flex: 1;
  padding: 2rem;
  /* display: grid;
  height: 22rem; */
  /* border-right: 1px solid;

  border-image: linear-gradient(64deg, #262626 94%, #0c0c0c 100%) 1; */
}
.item2 {
  padding: 2rem;
  /* display: grid;
  height: 22rem; */
  flex: 1;
  /* border-right: 1px solid;

  border-image: linear-gradient(64deg, #262626 94%, #0c0c0c 100%) 1; */
}
.item3 {
  padding: 2rem;
  /* display: grid;
  height: 22rem; */
  flex: 1;
  /* border-right: 1px solid;

  border-image: linear-gradient(64deg, #262626 94%, #0c0c0c 100%) 1; */
}
.item4 {
  padding: 2rem;
  /* display: grid;
  height: 22rem; */

  flex: 1;
}

@media screen and (max-width: 991px) {
  .item1,
  .item2,
  .item3,
  .item4 {
    padding: 1.2rem;
  }
}

/* news section-3 start */
.news-section-3 {
  display: flex;
}

.news-section-3 a {
  color: #3d580c;
  text-decoration: none;
}
.news-section-3 .twitter {
  margin-top: 3rem;
}
.box-1 {
  margin-right: 1.5%;
  padding: 2%;
  height: 17rem;
  width: 14rem;
  border: 0.5px solid transparent;
  border-image: linear-gradient(
    to bottom,
    #ffffffcc,
    #ffffffd4,
    #ffffff87,
    #ffffff6b,
    #ffffff3f
  );
  border-image-slice: 1;
  box-shadow: 0 15px 25px rgba(15, 15, 15, 0.2);
}
.box-2 {
  margin-top: 5%;
  margin-right: 1.5%;
  padding: 2%;
  height: 17rem;
  width: 14rem;
  border: 0.5px solid transparent;
  border-image: linear-gradient(
    to bottom,
    #ffffffcc,
    #ffffffd4,
    #ffffff87,
    #ffffff6b,
    #ffffff3f
  );
  border-image-slice: 1;
  box-shadow: 0 15px 25px rgba(15, 15, 15, 0.2);
}
.box-3 {
  margin-top: 2%;
  margin-right: 1.5%;
  padding: 2%;
  height: 17rem;
  width: 14rem;
  border: 0.5px solid transparent;
  border-image: linear-gradient(
    to bottom,
    #ffffffcc,
    #ffffffd4,
    #ffffff87,
    #ffffff6b,
    #ffffff3f
  );
  border-image-slice: 1;
  box-shadow: 0 15px 25px rgba(15, 15, 15, 0.2);
}
.box-4 {
  margin-top: 8%;
  padding: 2%;
  height: 17rem;
  width: 14rem;
  border: 0.5px solid transparent;
  border-image: linear-gradient(
    to bottom,
    #ffffffcc,
    #ffffffd4,
    #ffffff87,
    #ffffff6b,
    #ffffff3f
  );
  border-image-slice: 1;
  display: flex;

  flex-wrap: wrap;
  box-shadow: 0 15px 25px rgba(15, 15, 15, 0.2);
}
.box-4 .socials {
  color: #3d580c;

  font-family: DrukWide-Medium-Trial;
  font-weight: 500;
  font-size: 1.5rem;
  text-transform: uppercase;
}
.social-icon {
  margin-left: auto;
  align-items: flex-end;
  margin-bottom: auto;
}
.social-icon p {
  margin-top: 2rem;
  text-transform: uppercase;
  color: #3d580c;
  text-align: right;
}
.social-icon img {
  width: 15%;
  height: 10%;
}

/* news section-3 end */

/*news section-4 */
.news-section-4 {
  margin-top: 10%;
}
.latest {
  padding-left: 2rem;
}
.latest h3 {
  font-family: DrukWide-Medium-Trial;
  font-weight: 500;
  font-size: 1.5rem;
  text-transform: uppercase;
}
.latest-news-desc {
  display: flex;
  margin: 5%;
}
.latest-news-desc-subsec-1 {
  flex: 1;
  margin-right: 5%;
  padding: 1%;
  background:
    url(/public/temp_img/news-desc-border-1.svg) left top no-repeat,
    url(/public/temp_img/news-desc-border-2.svg) right top no-repeat,
    url(/public/temp_img/news-desc-border-3.svg) left bottom no-repeat,
    url(/public/temp_img/news-desc-border-4.svg) right bottom no-repeat;
}
.latest-news-desc-subsec-2 {
  flex: 1;
}
.latest-news-desc-date {
  display: flex;
}
.latest-news-desc-subsec-1 img {
  width: 100%;
}
.latest-news-desc-date p {
  margin-right: 2rem;
  color: #ffffff;
  opacity: 0.5;
  text-transform: uppercase;
  font-size: 1rem;
  font-family: IBMPlexMono-Regular;
}
.latest-news-desc-detail h4 {
  font-weight: 500;
  font-family: IBMPlexMono-Regular;
  font-size: 2.3rem;
  line-height: 3rem;
}
.latest-news-link {
  margin-top: 5%;
}
.latest-news-link a {
  color: #94db16;
}
.newslist_section .newslist_border.top_border {
  border-image: linear-gradient(90deg, #0c0c0c 0%, #424242 60%, #0c0c0c 100%) 1;
  left: 0;
  top: 2%;
  height: 1px;
  width: 100%;
  border-top: 1px solid;
}
.newslist_section .newslist_border.bottom_border {
  border-image: linear-gradient(90deg, #0c0c0c 0%, #424242 60%, #0c0c0c 100%) 1;
  left: 0;
  top: 2%;
  height: 1px;
  width: 100%;
  border-bottom: 1px solid;
}
.newslist_section .newslist_border.left_border {
  border-image: linear-gradient(92deg, #0c0c0c 0%, #424242 60%, #0c0c0c 100%) 1;
  left: 0;
  top: -13%;
  height: 120%;
  width: 1px;
  border-left: 1px solid;
  position: absolute;
}
.newslist_section .newslist_border.left_border_2 {
  border-image: linear-gradient(92deg, #0c0c0c 0%, #424242 60%, #0c0c0c 100%) 1;
  left: 24.5%;
  height: 100%;
  width: 1px;
  border-left: 1px solid;
  position: absolute;
}
.newslist_section .newslist_border.left_border_2_three_cards {
  border-image: linear-gradient(92deg, #0c0c0c 0%, #424242 60%, #0c0c0c 100%) 1;
  left: 33%;
  height: 100%;
  width: 1px;
  border-left: 1px solid;
  position: absolute;
}
.newslist_section .newslist_border.left_border_3 {
  border-image: linear-gradient(92deg, #0c0c0c 0%, #424242 60%, #0c0c0c 100%) 1;
  left: 50%;
  height: 100%;
  width: 1px;
  border-left: 1px solid;
  position: absolute;
}
.newslist_section .newslist_border.left_border_3_three_cards {
  border-image: linear-gradient(92deg, #0c0c0c 0%, #424242 60%, #0c0c0c 100%) 1;
  left: 66%;
  height: 100%;
  width: 1px;
  border-left: 1px solid;
  position: absolute;
}
.newslist_section .newslist_border.left_border_4 {
  border-image: linear-gradient(92deg, #0c0c0c 0%, #424242 60%, #0c0c0c 100%) 1;
  left: 75%;
  height: 35%;
  width: 1px;
  border-left: 1px solid;
  position: absolute;
}
.newslist_section .newslist_border.right_border {
  border-image: linear-gradient(92deg, #0c0c0c 0%, #424242 60%, #0c0c0c 100%) 1;
  right: 0;
  top: -13%;
  height: 120%;
  width: 1px;
  border-right: 1px solid;
  position: absolute;
}
@media screen and (max-width: 991px) {
  .newslist_section .newslist_border.left_border,
  .newslist_section .newslist_border.right_border,
  .newslist_section .newslist_border.bottom_border {
    display: none;
  }
}
/*news section-5 */
.news-section-5 {
  display: flex;
  border-bottom: 1px solid;
  border-image: linear-gradient(113deg, #0c0c0c 0%, #262626 80%, #0c0c0c 100%) 1;
}
.news-image {
  height: 50%;
  padding: 0.5rem;
  background:
    url(/public/temp_img/news-desc-border-1.svg) left top no-repeat,
    url(/public/temp_img/news-desc-border-2.svg) right top no-repeat,
    url(/public/temp_img/news-desc-border-3.svg) left bottom no-repeat,
    url(/public/temp_img/news-desc-border-4.svg) right bottom no-repeat;
}
.news-image img {
  /* object-fit: cover; */
  background-size: contain;
  height: 100%;
}
.news-details h4 {
  font-family: IBMPlexMono-Regular;
  font-size: 1rem;
  margin-top: 0.7rem;
  min-height: 7rem;
  font-weight: 500;
  text-align: left;
  /* text-transform: uppercase; */
}
.news-footer {
  display: flex;

  align-self: flex-end;
}
.read {
  text-align: right;
}
.date {
  flex: 1;
  font-style: normal !important;
  font-size: 0.75rem;
  font-family: IBMPlexMono-Regular;
  color: white;
  font-weight: 400;
  font-size: 1rem;
}

.read a {
  color: #94db16;
  text-decoration: underline;
}
.read button {
  background: #c7dddb38;
  font-family: IBMPlexMono-Regular;
  border: none;
  color: white;
  padding: 0.8rem;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.75rem;
  border-radius: 2.5rem;
}

.news-tag {
  margin-top: 5%;
  margin-left: 3%;
  display: flex;
}
.all button {
  border: 1px solid black;
  background-color: black;
  padding: 0.2rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  border-color: #94db16;
  color: #94db16;
  border-radius: 2.5rem;
}
.all {
  margin-right: 1rem;
}
.category {
  margin-right: 1rem;
}
.category button {
  border: 1px solid black;
  background-color: black;
  padding: 0.2rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  border-color: #929292;
  color: #929292;
  border-radius: 2.5rem;
}

/* news section-6 */

.news-section-6 {
  margin-top: 1%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  /* border-top: 1px solid;
   border-image: linear-gradient(103deg, #262626 83%, #0c0c0c 100%) 1; */
}
.news-section-6 .top_border {
  position: absolute;
  border-top: 1px solid;
  border-image: linear-gradient(90deg, #0c0c0c 0%, #424242 60%, #0c0c0c 100%) 1;
}
.news-sec-subsec-1 {
  display: grid;
  /* grid-template-row: 1fr 1fr; */
  height: 50rem;
  /* background-color:red; */
}
.newslist_section .newsection_border.left_border_3 {
  border-image: linear-gradient(23deg, #0c0c0c 0%, #424242 40%, #0c0c0c 100%) 1;
  left: 50%;
  height: 100%;
  width: 1px;
  border-left: 1px solid;
  position: absolute;
}
.newslist_section .newsection_border.left_border_4 {
  border-image: linear-gradient(23deg, #0c0c0c 0%, #424242 40%, #0c0c0c 100%) 1;
  left: 75%;
  height: 100%;
  width: 1px;
  border-left: 1px solid;
  position: absolute;
}
.news-sec-subsec-2 {
  margin-top: 4%;
  height: 50rem;
}
@media screen and (max-width: 991px) {
  .news-sec-subsec-2 {
    height: unset;
    padding: 0;
  }
}
.news-sec-subsec-1-box-1 {
  display: flex;
  height: 25rem;
  /* border-bottom: 1px solid;
  border-image: linear-gradient(103deg, #262626 83%, #0c0c0c 100%) 1; */
  /* background-color:blue; */
}
.news-sec-subsec-1-box-1 .news-sec-sub_border.bottom_border {
  border-image: linear-gradient(92deg, #0c0c0c 0%, #424242 60%, #0c0c0c 100%) 1;
  height: 1px;
  width: 50%;
  border-bottom: 1px solid;
  position: absolute;
  top: 48%;
}
.news-sec-subsec-1-box-2 {
  display: flex;

  height: 25rem;
  /* background-color:pink; */
}
.news-sec-subsec-2-box-1 {
  display: flex;
  margin-left: 2rem;
}
.news-sec-subsec-2-box-1 p {
  margin-right: 2rem;
  color: #ffffff;
  opacity: 0.5;
  text-transform: uppercase;
  font-size: 1rem;
  font-family: IBMPlexMono-Regular;
}
.news-sec-subsec-2-box-2 {
  height: 50rem;
}
@media screen and (max-width: 991px) {
  .news-sec-subsec-2-box-2 {
    height: unset;
  }
}
.news-details-large h4 {
  margin-top: 5%;
  font-family: IBMPlexMono-Regular;
  font-size: 2.4rem;
  line-height: 3.1rem;
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;
  min-height: 8rem;
}
.item3-large {
  padding: 2rem;
  /* display: grid;
  height: 40rem; */
  flex: 1;
}
.item3-large .news-footer {
  margin-top: 2rem;
}
@media screen and (max-width: 991px) {
  .item3-large {
    padding: 0;
  }
}

/* news section-6 end */

/*pagination */
.news-container .pagination {
  margin: 2rem 0;
  text-align: center;
  padding-bottom: 4rem;
}
.news-container .pagination a {
  margin-right: 2rem;
  margin-bottom: 2rem;
  font-size: 1rem;
  font-family: IBMPlexMono-Regular;
  font-weight: 400;
  line-height: 1.2rem;
  text-decoration: none;
  color: #ffffff;
  margin: 0 0.5rem !important;
}
.news-container .pagination-left-button {
  background-color: transparent;
  cursor: pointer;
  color: inherit;
  border: none;
  background-image: url(/public/img/leftarrow.svg);
  background-position: 1rem 0.8rem;
  background-repeat: no-repeat;
  padding: 2.5rem;
}
.news-container .pagination-right-button {
  background-color: transparent;
  cursor: pointer;
  color: inherit;
  border: none;
  background-image: url(/public/img/rightarrow.svg);
  background-position: 1rem 0.8rem;
  background-repeat: no-repeat;
  padding: 2.5rem;
}
.news-container .pagination img {
  justify-self: end;
  padding-top: 5rem;
  height: 1.5rem;
}

@media screen and (max-width: 991px) {
  .item1 {
    flex: 0 0 auto;
    height: unset;
  }
  .item2 {
    flex: 0 0 auto;
    height: unset;
  }
  .item3 {
    flex: 0 0 auto;
    height: unset;
  }
  .item4 {
    flex: 0 0 auto;
    height: unset;
  }

  .news-section-6 {
    display: flex;
    justify-content: center;
    margin-right: 0px;
    margin-left: 0px;
  }

  .news-sec-subsec-1 {
    height: unset;
    padding: 0px;
  }

  .news-sec-subsec-1-box-1 {
    display: flex;
    height: unset;
    padding: 0px;
  }

  .news-sec-subsec-1-box-2 {
    display: flex;
    height: unset;
  }

  .news-header {
    text-align: left;
  }
  .news-section2-item3 {
    flex: 3 0;
  }
}
/*pagination end */

.newspage .section8 {
  margin-top: 10rem;
}
.newspage .section8 .verticalLine.left {
  border-image: linear-gradient(0deg, #262626 30%, #0c0c0c 50%) 1;
  height: 110rem;
}
.newspage .section8 .leftcontent .verticalLine.center {
  height: 54rem;
}
.newspage .section8 .rightcontent .verticalLine.right {
  height: 50rem;
}

/* aryan css code */

.news--bigcard-border {
  border-image: linear-gradient(92deg, #262626 30%, #0c0c0c 50%, #262626 30%) 1;
  border-left: 2px solid;
  height: 1.5rem;
  margin-right: 1rem;
}
