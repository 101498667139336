.news_desc_page {
  margin-top: 3%;
  /* margin-left: 9%;
    margin-right: 8%; */

  background-image: url(../../../public/img/contact/contact-bars-bg.png);
  background-repeat: no-repeat;
  background-position: top;
}
.news-desc-section-1 {
  margin-bottom: 5%;
  padding-top: 6rem;
}
.news-desc-heading h1 {
  text-transform: uppercase;
  font-family: DrukWide-Medium-Trial;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 1.3;
  letter-spacing: 1px;
}

.news-desc-section-2 {
  display: float;
  margin-top: 5%;
  padding: 0 1rem;
}
.news-desc-section-2 h4 {
  color: #32470b;
  text-transform: uppercase;
  margin-bottom: 2%;
  font-family: DrukWide-Medium-Trial;
}

.zh-chs .news-desc-heading h1 {
  letter-spacing: 0.2rem;
}
.zh-cht .news-desc-heading h1 {
  letter-spacing: 0.2rem;
}
/* .news-desc-date{
    float: left;
    margin-right: 5%;
    
}
.news-desc-author{
    float: left;
} */
.news-desc-social-icon {
  /* float: right; */
  display: flex;
  /* margin: 20px auto; */
}
.news-desc-social-icon .icon {
  height: 40%;
}
.news-desc-social-icon a:not(:last-of-type) {
  margin-right: 1rem;
}

.zh-chs .news-desc-sec-4 p {
  letter-spacing: 0.2rem;
  line-height: 1.8rem;
}
.zh-cht .news-desc-sec-4 p {
  letter-spacing: 0.2rem;
  line-height: 1.8rem;
}
.news-desc-sec-4 a {
  color: var(--primary-color);
  text-decoration: none;
}
.news-desc-sec-4 p {
  line-height: 1.3rem;
  font-size: 1rem;
  font-family: IBMPlexMono-Regular;
  font-weight: 400;
  color: #ffffff;
}
.news-desc-sec-4 .tagline {
  display: flex;
  margin-top: 1rem;
}

.news-desc-sect-3 .imgorvid {
  margin: 1rem 0;
  position: relative;
}
.news-desc-sect-3 .imgorvid .top_border {
  border-image: linear-gradient(90deg, #0c0c0c 0%, #424242 60%, #0c0c0c 100%) 1;
  /* left: 0;
    top: 2%; */
  height: 1px;
  width: 100%;
  border-top: 1px solid;
  position: absolute;
}
@media screen and (min-width: 1700px) {
  .news-desc-sect-3 .imgorvid .top_border {
    width: 120%;
  }
}
.news-desc-sect-3 .imgorvid .right_border {
  border-image: linear-gradient(300deg, #424242 30%, #0c0c0c 100%) 1;
  height: 128%;
  top: -20%;
  width: 1px;
  border-right: 1px solid;
  position: absolute;
  right: 0;
}
.news-desc-sect-3 .imgorvid .bottom_border {
  border-image: linear-gradient(90deg, #0c0c0c 0%, #424242 60%, #0c0c0c 100%) 1;
  /* left: 0;
    top: 2%; */
  height: 1px;
  width: 120%;
  border-bottom: 1px solid;
  position: absolute;
  bottom: 0;
  right: 0;
}
.news-desc-sect-3 .imgorvid .left_border {
  border-image: linear-gradient(39deg, #0c0c0c 0%, #424242 60%, #0c0c0c 100%) 1;
  height: 190%;
  width: 1px;
  border-left: 1px solid;
  position: absolute;
}
@media screen and (max-width: 991px) {
  .news-desc-sect-3 .imgorvid .top_border,
  .news-desc-sect-3 .imgorvid .right_border,
  .news-desc-sect-3 .imgorvid .bottom_border,
  .news-desc-sect-3 .imgorvid .left_border {
    display: block;
  }
  .news-desc-sect-3 .imgorvid .left_border {
    height: 135%;
  }
  .news-desc-sect-3 .imgorvid .right_border {
    border-image: linear-gradient(39deg, #0c0c0c 0%, #424242 60%, #0c0c0c 100%)
      1;
    height: 145%;
    top: -20%;
    width: 1px;
    border-right: 1px solid;
    position: absolute;
    right: 0;
  }
}
.news-desc-sect-3 img {
  width: 100%;
}
.news-desc-sect-3 .caption {
  font-size: 1rem;
  font-family: IBMPlexMono-Regular;
  font-weight: 400;
  line-height: 1.18rem;
  padding: 0 1rem;
}

.news-desc-sec-4 {
  padding: 3rem 8rem 3rem 8rem;
  position: relative;
}
@media screen and (max-width: 991px) {
  .news-desc-sec-4 {
    padding: 3rem 0;
  }
}
.news-desc-sec-4 .right_border {
  border-image: linear-gradient(102deg, #424242 30%, #0c0c0c 100%) 1;
  height: 50%;
  top: 0%;
  width: 1px;
  border-right: 1px solid;
  position: absolute;
  right: 0;
}
.news-desc-sec-4 .desc_imgorvideo {
  margin-bottom: 2%;
  margin-top: 5%;
  padding: 0.5rem;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  background:
    url(/public/temp_img/Rectangle\ 229.svg) left top no-repeat,
    url(/public/temp_img/Rectangle\ 230.svg) right top no-repeat,
    url(/public/temp_img/Rectangle\ 231.svg) left bottom no-repeat,
    url(/public/temp_img/Rectangle\ 232.svg) right bottom no-repeat;
}
.news-desc-sec-4 img {
  width: 100%;
}
.news-desc-sec-4 .caption {
  margin-bottom: 5%;
}
.news-desc-sec-4 h3 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.9rem;
  font-family: DrukWide-Medium-Trial;
  margin-top: 5%;
  margin-bottom: 5%;
}
.news-desc-sec-4 ul {
  margin-left: 1.5%;
  margin-bottom: 5%;
}
@media screen and (max-width: 991px) {
  .news-desc-sec-4 ul {
    padding-left: 1rem;
  }
}
.news-desc-sec-4 li {
  margin-top: 2%;
}

.backtonews_desk {
  margin-top: 1rem;
  display: flex;
}

.dot {
  height: 15px;
  width: 15px;
  background-color: #94db16;
  border-radius: 50%;
  display: inline-block;
}

.dot-1 {
  height: 6.3rem;
  width: 6.3rem;
  background-image: url(/public/temp_img/Group\ 270.svg);
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  background-color: #94db16;
  border-radius: 50%;
  display: inline-block;
  z-index: 9;
  border: unset;
  cursor: pointer;
}
.news-desc-sec-4-quote {
  margin-top: -2rem;
  margin-bottom: 5%;
  margin-left: 3%;
}
@media screen and (max-width: 991px) {
  .news-desc-sec-4-quote {
    margin-top: 2rem;
  }
}
.news-desc-sec-4-quote {
  content: '';
  background-image: url(/public/img/quote.svg);

  background-repeat: no-repeat;
  background-position: left top;
}
.news-desc-sec-4-quote .content {
  margin-left: 4%;
}
.news-desc-sec-4-quote .tagline span {
  display: block;
  width: 2.5rem;
  height: 0.1rem;
  color: #fff;
  background: #94db16;
  margin-right: 0.5rem;
}

.news_desc_page .section8 .verticalLine.left {
  border-image: linear-gradient(0deg, #262626 30%, #0c0c0c 50%) 1;
  height: 110rem;
}
.news_desc_page .section8 .leftcontent .verticalLine.center {
  height: 54rem;
}
.news_desc_page .section8 .rightcontent .verticalLine.right {
  height: 50rem;
}

.news-desc-sec-4 .desc_imgorvideo iframe {
  width: 100% !important;
}

.news-desc-sec-4 .desc_imgorvideo video {
  width: 100%;
}

.news_desc_page .leftshadow {
  display: block;
  position: absolute;

  width: 100%;
  top: 25%;
  height: 30%;
  background-color: #ffffff57;
  opacity: 0.25;
  right: 40%;
  filter: blur(80px);
  border-radius: 50%;
  transform: rotate(355deg);
  z-index: -1;
}
@media screen and (max-width: 768px) {
  .news_desc_page .leftshadow {
    top: 10%;
    opacity: 0.45;
    width: 100%;
    height: 40%;
    right: 10%;
  }
}
