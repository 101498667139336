.privacyPage {
  background-image: url(../../../public/img/contact/contact-bars-bg.png);
  background-repeat: no-repeat;
  background-position: 0% 7rem;
  padding-top: 17rem;
  background-size: 100%;
}
@media screen and (max-width: 991px) {
  .privacyPage {
    padding-top: 3rem;
  }
}
.privacyPage .privacyContainer {
  padding-bottom: 10rem;
}
.privacyPage .headingTag {
  font-family: DrukWide-Medium-Trial;
  font-size: 2rem;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin-bottom: 3rem;
}
.privacyPage .subHeadingTag {
  font-family: DrukWide-Medium-Trial;
  font-size: 1.7rem;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.privacyPage .innerwidth {
  padding: 0 10rem;
  position: relative;
}
@media screen and (max-width: 991px) {
  .privacyPage .innerwidth {
    padding: 0 0.5rem;
  }
}
.privacyPage .listElements {
  padding-left: 1.3rem;
  line-height: 1.8;
}

.privacyPage .innerwidth .verticalLine.left {
  border-image: linear-gradient(0deg, #0c0c0c 0%, #262626 70%, #0c0c0c 100%) 1;
  border-left: 1px solid;
  height: 100%;
  left: 0;
  position: absolute;
}
.privacyPage .innerwidth .verticalLine {
  border-image: linear-gradient(0deg, #0c0c0c 0%, #262626 70%, #0c0c0c 100%) 1;
  border-left: 1px solid;
  height: 100%;
  right: 0;
  position: absolute;
}
@media screen and (max-width: 991px) {
  .privacyPage .innerwidth .verticalLine {
    display: none;
  }
}


.privacyPage .leftshadow {
  display: block;
  position: absolute;
 
  width: 100%;
  top: 20%;
  height: 60%;
  background-color: #ffffff57;
  opacity: 0.15;
  right:20%;
  filter: blur(80px);
  border-radius: 50%;
  transform: rotate(355deg);
  z-index: -1;
}
