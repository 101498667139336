/* Common font size for page */

/* banner corner */
.main-project-banner,
.main-project-banner-video {
  max-width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  padding: 0 1rem;
}

.scale-image {
  transition: transform 1s ease; /* Animation property for scaling */
  transform: scale(0); /* Initial scale to 0 */
}

.dontScale {
  transition: transform 0.1s ease;
  transform: scale(1);
}

.scale-image.scaled {
  transform: scale(1) !important; /* Scaled to 1 after onload */
  transition: transform 1.5s ease !important; /* Animation property for scaling */
}

.project-banner,
.project-banner-video {
  position: relative;
  padding: 0.5rem;
  margin: 0 0rem;
  flex-direction: column;
  height: auto; /* Divakara changed this */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  background:
    url(/public/temp_img/Rectangle\ 229.svg) left top no-repeat,
    url(/public/temp_img/Rectangle\ 230.svg) right top no-repeat,
    url(/public/temp_img/Rectangle\ 231.svg) left bottom no-repeat,
    url(/public/temp_img/Rectangle\ 232.svg) right bottom no-repeat;
}
.main-project-banner-video .project-banner-video .project-video-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 1500px) {
  .project-banner {
    height: auto;
  }
}
@media screen and (max-width: 991px) {
  .project-banner {
    height: unset;
  }
}
/* .project-banner.transformed{
    height: 100vh;
    width: 100vw;
    top:0;
    bottom:0;
    left: 0;
    right: 0;
} */

.project-banner img {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  display: block;
  /* object-fit: cover; */
  background-size: contain;
  /* transition: all .5s ease-in-out; */
}

.project-banner .bg_fill {
  display: block;
  background-color: var(--primary-color);
  width: 100%;
  position: absolute;
  height: 100%;
  bottom: -10rem;
  transition: 0.2s all ease-in-out;
  border-radius: 50%;
  z-index: -1;
}

.side-right-arrow-circle .bg_fill:first-child {
  right: 0;
}
.project-banner img.transformed {
  width: 100vw;
}

.project-banner .project-banner-button {
  border-radius: 50%;
  height: 7rem;
  width: 7rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: 400;
  font-family: IBMPlexMono-Regular;
  font-size: 1rem;
  line-height: 1.1rem;
  border-style: none;
  background-color: #fff;
  overflow: hidden;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.project-banner-button:hover .bg_fill:first-child {
  bottom: 0;
}
.project-banner-button:hover {
  color: white;
}

img {
  width: 100%;
}

/* project-desc-sec-1 start */
.project-desc-sec-1 {
  position: relative;
  padding: 7rem 0 4.6875rem;
}
.project-desc-sec-1 a {
  color: var(--primary-color);
  text-decoration: none;
}
.project-desc-subheading {
  color: #fefbf7;
  opacity: 0.6;
  text-transform: capitalize;
  line-height: 1.5;
}
@media screen and (min-width: 769px) {
  .project-desc-subheading {
    width: 80%;
  }
}
.project-desc-sec-1-subsec-2-subhead-1 .project-desc-subheading {
  text-transform: capitalize;
}
.project-desc-project-name {
  overflow: hidden;
}
.animated-text-box {
  overflow: hidden;
}
.animated-text-box p {
  overflow: hidden;
}
.animated-my-text {
  display: inline-block;
  /* transition: opacity 2s; */
}
.project-desc-project-name h1 {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 3rem;
  line-height: 3.87rem;
  font-family: DrukWide-Medium-Trial;
}
.project-desc-heading {
  font-weight: 500;
  font-size: 1.25rem;
  font-family: IBMPlexMono-Regular;
  line-height: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.project-desc-sec-1-subsec-1 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 5%;
  grid-gap: 8%;
}

.project-desc-sec-1-subsec-2 {
  display: grid;
  grid-gap: 8%;
  grid-template-columns: 1fr 2fr;
}
.project-desc-sec-1-subsec-2-subhead-1 {
  display: grid;
  grid-template-rows: 2fr 1fr;
}
.project-desc-sec-1-subsec-2-subhead-2 {
  display: grid;
  grid-template-rows: 2fr 1fr;
  grid-gap: 10%;
}
.project-desc-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.project-desc-subhead-2-content p {
  margin-bottom: 2rem;
  white-space: break-spaces;
  word-break: break-word;
}

@media screen and (max-width: 768px) {
  /* banner */
  /* project desc-sec-1 */
  .project-desc-project-name h1 {
    cursor: pointer;
  }

  img {
    width: 100%;
  }

  /* project-desc-sec-1 start */
  .project-desc-subheading {
    color: #fefbf7;
    opacity: 0.6;
    /* text-transform: uppercase; */
  }
  .project-desc-sec-1-subsec-2-subhead-1 .project-desc-subheading {
    text-transform: capitalize;
  }
  .project-desc-project-name h1 {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 3rem;
    line-height: 3.87rem;
    font-family: DrukWide-Medium-Trial;
    margin-bottom: 5%;
  }
  .project-desc-sec-1-subsec-1 {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 5%;
    grid-gap: 8%;
  }

  .project-desc-sec-1-subsec-2 {
    display: grid;
    grid-gap: 8%;
    grid-template-columns: 1fr;
    margin-bottom: 5%;
  }
  .project-desc-sec-1-subsec-2-subhead-1 {
    display: grid;
    grid-template-rows: 1fr;
    order: 2;
    margin-bottom: 5%;
  }
  .project-desc-sec-1-subsec-2-subhead-2 {
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 10%;
    order: 1;
    margin-bottom: 5%;
  }
  .project-desc-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 5%;
  }
  .services {
    margin-bottom: 5%;
  }
  .languages {
    margin-bottom: 5%;
  }

  /* project desc-sec-2 */

  .project-desc-sec-2-subsec-2 p:last-child {
    display: none;
  }

  /* project desc-sec-3 start */
  /* .project-desc-sec-3 div{
        display: none;
    } */
  .project-desc-sec-3 span {
    display: none;
  }
  .project-desc-sec-3 div {
    justify-content: center;
  }
  .project-desc-sec-3 div:first-child {
    background-image: url(/public/img/leftarrow.svg) !important;
    color: transparent !important;
    background-repeat: no-repeat;
    height: 10rem;
    width: 20rem;
    margin-left: 50%;
    cursor: pointer;
  }
  .project-desc-sec-3 div:last-child {
    background-image: url(/public/img/rightarrow.svg) !important;
    color: transparent !important;
    background-repeat: no-repeat;
    height: 10rem;
    width: 20rem;
    cursor: pointer;
  }

  /* project-desc-sec-4 */
  .death-stranding h4 {
    display: none;
  }

  .death-stranding button {
    border: 1px solid white !important;
    background-image: url(/public/temp_img/mobile-navigation-prev.svg) !important;
    background-color: transparent !important;
  }
  .god-of-war button {
    border: 1px solid white !important;
    background-color: transparent !important;
    background-image: url(/public/temp_img/mobile-navigation-next.svg) !important;
  }
  .god-of-war h4 {
    display: none;
  }
}

/* project -desc -sec-1 end */

/* project-desc-sec-2 start */
.project-desc-sec-2 {
  position: relative;
  padding: 3 0 0;
}
.btn-flip {
  opacity: 1;
  outline: 0;
  color: #94db16;
  position: relative;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
}
.btn-flip:hover:after {
  opacity: 1;
  transform: translateY(0) rotateX(0);
}
.btn-flip:hover:before {
  opacity: 0;
  transform: translateY(50%) rotateX(90deg);
}
.btn-flip:after {
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  color: #94db16;
  display: block;
  transition: 0.5s;
  position: absolute;
  content: attr(data-back);
  transform: translateY(-50%) rotateX(90deg);
}
.btn-flip:before {
  top: 0;
  left: 0;
  opacity: 1;
  color: #94db16;
  display: block;
  transition: 0.5s;
  position: relative;
  content: attr(data-front);
  transform: translateY(0) rotateX(0);
}

.project-category-subsec-2 button:hover .animate-img {
}
.project-desc-sec-2 .pr-desc-item {
  /* display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column; */
}
.project-desc-sec-2 .pr-desc-item .img {
  width: 100%;
  position: relative;
  /* overflow: hidden; */
  display: flex;
  justify-content: center;
  /* height: 30rem; */
}

@media screen and (max-width: 991px) {
  .project-desc-sec-2 .pr-desc-item .img {
    height: 25rem;
  }
}

.project-desc-sec-2 .pr-desc-item img {
  margin-bottom: 0%;
  margin-top: 0%;
  max-width: 100%;
  width: auto;
  height: auto;
  object-fit: cover;
  position: relative;
  display: block;
}
.project-desc-sec-2-subsec-1 {
  /* margin-top: 10rem; */
  padding: 0 3rem;
}
.project-desc-sec-2-subsec-1 p {
  font-family: IBMPlexMono-Regular;
  line-height: 2.25rem;
  font-weight: 500;
  opacity: 1;
}
.project-desc-sec-2-subsec-1 p.tagline {
  display: flex;
}
.project-desc-sec-2-subsec-1 p.tagline .testimonial-animetext {
  /* position: relative; */
}

.project-desc-sec-2-subsec-1 .testimonial-message p {
  opacity: 1;
}
.project-desc-sec-2-subsec-2 {
  margin-top: 2%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.project-desc-sec-2-subsec-2 p:last-child {
  color: #476513;

  text-align: right;
  cursor: pointer;
  margin-top: 2.2rem;
}
.project-desc-sec-2-subsec-2 .lefttest {
  background-image: url(/public/temp_img/Group\ 328.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.project-desc-sec-2-subsec-2 .lefttest-active {
  background-image: url(/public/temp_img/Group\ 328A.svg);
  cursor: pointer;
}
.project-desc-sec-2-subsec-2 .lefttest-inactive {
  background-image: url(/public/temp_img/Group\ 328.svg);
}
.project-desc-sec-2-subsec-2 .righttest {
  background-repeat: no-repeat;
  background-position: center;
  padding-right: 1rem;
}

.project-desc-sec-2-subsec-2 .righttest-active {
  background-image: url(/public/temp_img/Group\ 329A.svg);
  cursor: pointer;
}
.project-desc-sec-2-subsec-2 .righttest-inactive {
  background-image: url(/public/temp_img/Group\ 329.svg);
}
.tagline {
  font-size: 0.875rem;
  margin: 0;
  text-transform: uppercase;
  /* display: flex; */
  align-items: center;
  font-style: normal !important;
  opacity: 0.6;
  mix-blend-mode: difference;
}
.tagline .horiz-line {
  display: block;
  width: 2.5rem;
  height: 0.1rem;
  opacity: 0.5;
  background: #94db16;
  margin-right: 0.5rem;
  margin-top: 2.2rem;
}
/* .testimonial-animation{
  overflow: hidden;
}
.testimonial-letters{
  display: inline-block;
}
.company-letters{
  display: inline-block;
} */
.active {
  color: #94db16;
}

.pr-desc-item video {
  margin-top: 5%;
  width: 100%;
  margin-bottom: 5%;
}
.iframe_video.projectdesc {
  position: relative;
  width: 100%;
  padding-top: 56%;
  margin-bottom: 6rem;
}
.projectdesc iframe {
  margin-top: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* project-desc-sec-2 end */

/* project-desc-sec-3 start */

.project-desc-sec-3 {
  margin-top: 11.25rem;
  display: flex;
  justify-content: space-between;
}
.dot {
  /* opacity: 1;
  transition: opacity 2s; */
  height: 15px;
  width: 15px;
  background-color: #94db16;
  border-radius: 50%;
  display: inline-block;
}

/* project-desc-sec-3 end */

/* project-desc-sec-4 start */
.project-desc-sec-4 {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 0.625rem;
  margin-bottom: 2%;
  margin-bottom: 10%;
}
.project-desc-sec-4 h4 {
  font-family: DrukWide-Medium-Trial;
  text-transform: uppercase;

  font-size: 1.5rem;
}
.verticalLine.center {
  /* display: none; */
}

.project-desc-sec-4 .project-desc-left {
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-desc-sec-4 .project-desc-left,
.project-desc-sec-4 .project-desc-right {
  position: relative;
  max-width: 55%;
}
.project-desc-sec-4 .project-desc-left:hover .btn-left-side_desk,
.project-desc-sec-4 .project-desc-right:hover .btn-right-side_desk {
  opacity: 1;
}
@media screen and (max-width: 991px) {
  .project-desc-sec-4 .project-desc-left,
  .project-desc-sec-4 .project-desc-right {
    display: block;
  }

  .project-desc-sec-4 .project-desc-left .btn-left-side_mobile,
  .project-desc-sec-4 .project-desc-right .btn-right-side_mobile {
    display: block !important;
    opacity: 1 !important;
    color: #fff;
    position: relative;
    top: inherit;
    left: inherit;
    right: inherit;
    transform: inherit;
    font-size: 1rem;
    background-color: transparent;
    width: 8.125rem;
    height: 8.125rem;
    background-size: contain;
    border-radius: 50%;
    border: 1px solid white;
    font-weight: 400;
    line-height: 1.6rem;
    letter-spacing: 1px;
  }
  .btn-right-side_desk,
  .btn-left-side_desk {
    display: none !important;
  }
}
.btn-right-side_desk,
.btn-left-side_desk {
  display: block;
  transition: all 0.5s;
  border-radius: 50%;
  right: 26%;
  top: 0;
  opacity: 0;
  background-color: #94db16;
  position: absolute;
  height: 6.3rem;
  width: 6.3rem;
  cursor: pointer;
  border: none;
  background-image: url('/public/temp_img/right-swipe-arrow.svg');

  background-repeat: no-repeat;
  background-position: center;
}

.btn-left-side_desk {
  left: unset;
  right: unset;
  /* left: 43%; */
  top: 50%;
  transform: translateY(-50%);
  background-image: url('/public/temp_img/left-swipe-arrow.svg');
}
.btn-right-side_desk {
  /* left: unset;
  right: unset; */
  top: 47%;
  /* right: 26%; */

  transform: translateY(-50%);
}
.project-desc-sec-4 .project-desc-left .btn-left-side_mobile,
.project-desc-sec-4 .project-desc-right .btn-right-side_mobile {
  display: none;
  opacity: 0;
  cursor: pointer;
}
/* project-desc-sec-4 end */

/* mobile responsive */
@media screen and (max-width: 768px) {
  /* banner */
  .project-banner .project-banner-button {
    height: 6rem;
    width: 6rem;
  }

  /* project desc-sec-1 */
  .project-desc-project-name h1 {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 3rem;
    line-height: 3.87rem;
    font-family: DrukWide-Medium-Trial;
    margin-bottom: 5%;
  }
  .project-desc-sec-1-subsec-1 {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 5%;
    grid-gap: 8%;
  }

  .project-desc-sec-1-subsec-2 {
    display: grid;
    grid-gap: 8%;
    grid-template-columns: 1fr;
    margin-bottom: 5%;
  }
  .project-desc-sec-1-subsec-2-subhead-1 {
    display: grid;
    grid-template-rows: 1fr;
    order: 2;
    margin-bottom: 5%;
  }
  .project-desc-sec-1-subsec-2-subhead-2 {
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 10%;
    order: 1;
    margin-bottom: 5%;
  }
  .project-desc-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 5%;
  }
  .services {
    margin-bottom: 5%;
  }
  .languages {
    margin-bottom: 5%;
  }

  /* project desc-sec-2 */

  .project-desc-sec-2-subsec-2 p:last-child {
    display: none;
  }

  .projectdesc iframe {
    margin-top: 5%;
    width: 100% !important;
  }

  .project-desc-sec-3 span {
    display: none;
  }
  .project-desc-sec-3 div {
    justify-content: center;
    align-items: center;
  }
  .project-desc-sec-3 div:first-child {
    background-image: url(/public/img/leftarrow.svg) !important;
    color: transparent !important;
    background-repeat: no-repeat;
    height: 10rem;
    width: 20rem;
    margin-left: 65%;
    cursor: pointer;
  }
  .project-desc-sec-3 div:last-child {
    background-image: url(/public/img/rightarrow.svg) !important;
    color: transparent !important;
    background-repeat: no-repeat;
    height: 10rem;
    width: 20rem;
    cursor: pointer;
  }
  /* project-desc-sec-4 */
  .death-stranding h4 {
    display: none;
  }

  .death-stranding button {
    border: 1px solid white !important;
    background-image: url(/public/temp_img/mobile-navigation-prev.svg) !important;
    background-color: transparent !important;
  }
  .god-of-war button {
    border: 1px solid white !important;
    background-color: transparent !important;
    background-image: url(/public/temp_img/mobile-navigation-next.svg) !important;
  }
  .god-of-war h4 {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .main-project-content {
    padding: 0 1rem;
  }
  .main-project-content .container {
    padding: 0;
  }
  .project-banner {
    height: auto;
    margin: 0;
  }
  .project-desc-sec-1 {
    padding: 2rem 0;
  }
  .project-desc-heading {
    font-size: 0.75rem;
    line-height: 1rem;
    margin: 0;
  }
  .project-desc-project-name h1 {
    font-size: 1.375rem;
    line-height: 1.5rem;
    margin: 0;
  }
  .project-desc-sec-2 {
    padding: 0;
  }
  .project-desc-sec-1-subsec-1,
  .project-desc-sec-1-subsec-2 {
    display: block;
    grid-template-columns: inherit;
    grid-gap: inherit;
  }
  .project-desc-sec-2-subsec-1 {
    margin-top: 3.75rem;
    padding: 0.5rem;
  }

  .project-desc-subheading {
    white-space: break-spaces;
    word-break: break-word;
  }
  .project-desc-sec-3 {
    margin-top: 3.75rem;
    margin-bottom: 5.5rem;
  }

  .project-desc-sec-3 div:first-child,
  .project-desc-sec-3 div:last-child {
    height: 4rem;
    width: 4rem;
    background-size: contain;
    margin: 0;
  }
  .project-desc-sec-3 div:first-child {
    margin: 0 1.625rem 0 auto;
  }
  .project-desc-sec-4 {
    align-items: center;
  }
  .project-desc-sec-2 .pr-desc-item {
    display: block;
  }
  .project-desc-sec-2-subsec-2 {
    width: auto;
  }
}

@media screen and (max-width: 300px) {
  .project-desc-project-name h1 {
    font-size: 2rem;
    line-height: 2.87rem;
  }
  .project-desc-sec-4 {
    margin-left: 5%;
    margin-right: 5%;
  }
  .project-desc-sec-3 {
    margin-left: 5%;
    margin-right: 5%;
  }
  .project-desc-sec-2 {
    margin-left: 5%;
    margin-right: 5%;
  }
  .project-desc-sec-1 {
    margin-left: 5%;
    margin-right: 5%;
  }
  .project-banner {
    margin-left: 5%;
    margin-right: 5%;
  }
  .project-banner button {
    top: 15% !important;
    left: 35% !important;
  }

  .project-desc-heading {
    font-size: 1.2rem;
  }
  .project-desc-sec-3 div:first-child {
    margin-left: 50%;
  }
}
.wrap {
  position: relative;
  /* overflow: hidden; */
}
.testimonial-animetext {
  display: flex;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
}
.testi {
  display: inline-block;
  opacity: 0;
}
.testimonial-letters1 {
  opacity: 1;
}
/* aryan code */

.main-project-content .section8 .verticalLine.left {
  border-image: linear-gradient(0deg, #262626 50%, #0c0c0c 100%) 1;
  height: 60rem;
}
.main-project-content .section8 .leftcontent .verticalLine.center {
  height: 60rem;
}
.main-project-content .section8 .rightcontent .verticalLine.right {
  height: 70rem;
}

.main-project-content .borderLines {
  /* position: absolute;
  width: auto;
  background: transparent; */
}
@media screen and (max-width: 991px) {
  .main-project-content .borderLines {
    display: none;
  }
}
.main-project-content .img .borderLines::before,
.main-project-content .img .borderLines::after {
  content: '';
  border-image: linear-gradient(355deg, #0c0c0c 0%, #262626 60%, #0c0c0c 100%) 1;
  position: absolute;
  display: block;
  border-top: 1px solid;
}
.main-project-content .borderLines.top::before {
  left: -11%;
  top: 0;
  height: 1px;
  width: 25rem;
}
.main-project-content .borderLines.top::after {
  right: -11%;
  top: 0;
  height: 1px;
  width: 25rem;
}
.main-project-content .borderLines.bottom::before {
  left: -11%;
  bottom: 0;
  height: 1px;
  width: 25rem;
}
.main-project-content .borderLines.bottom::after {
  right: -11%;
  bottom: 0;
  height: 1px;
  width: 25rem;
}
.main-project-content .borderLines.left::before {
  left: 0;
  top: -23%;
  height: 25rem;
  width: 1px;
  border-left: 1px solid;
}
.main-project-content .borderLines.left::after {
  left: 0;
  bottom: -23%;
  height: 25rem;
  width: 1px;
  border-left: 1px solid;
}
.main-project-content .borderLines.right::before {
  right: 0;
  top: -23%;
  height: 25rem;
  width: 1px;
  border-right: 1px solid;
}
.main-project-content .borderLines.right::after {
  right: 0;
  bottom: -23%;
  height: 25rem;
  width: 1px;
  border-right: 1px solid;
}

#cursor-project {
  z-index: 99;

  position: fixed;
  /* pointer-events: none; */
  /* position:absolute; */
  transform: translate(-50%, -50%);

  will-change: transform;
}
