.about_page .Headsection {
  padding-top: 10rem;
  justify-content: center;
}
.about-container {
  margin-top: 3%;
  max-width: 100%;
  background-image: url(../../../public/img/contact/contact-bars-bg.png);
  background-repeat: no-repeat;
  background-position: top;
}
@media screen and (max-width: 991px) {
  .about_page .Headsection {
    flex-wrap: wrap;
    padding: 0;
    padding-top: 6rem;
  }
}
@media screen and (max-width: 991px) {
  .about_page .head_title {
    width: 100%;
  }
}
.about_page .head_title h1 {
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
  font-size: 5rem;
  line-height: 1.2;
  font-family: DrukWide-Medium-Trial;
}
@media screen and (max-width: 1300px) {
  .about_page .head_title h1 {
    font-size: 5.5vw;
  }
}
@media screen and (max-width: 768px) {
  .about_page .head_title h1 {
    font-size: 3.5rem;
  }
}
.about_page .Headsection .line_before {
  margin-bottom: 0.3rem;
  display: block;
  width: 2.1rem;
  height: 0.1rem;
  opacity: 0.5;
  background: #ffffff;
  margin-right: 0.5rem;
}
@media screen and (max-width: 991px) {
  .about_page .Headsection .line_before {
    display: flex;
    align-self: flex-start;
  }
}
.about_page .Headsection .sub_title {
  padding-left: 9rem;
}
@media screen and (min-width: 1500px) {
  .about_page .Headsection .sub_title {
    padding-left: 15rem;
  }
}
@media screen and (max-width: 991px) {
  .about_page .Headsection .sub_title {
    width: auto;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }
}
.about_page .ourteams_section {
  padding-top: 10rem;
  position: relative;
}

@media screen and (max-width: 991px) {
  .about_page .ourteams_section {
    padding-top: 3rem;
  }
}

.about_page .ourteams_section .teamslideup_line {
  position: absolute;
  left: -14rem;
  top: 33%;
  z-index: 11;
  width: 30rem;
}
.about_page .ourteams_section .ourteam_swiper {
  position: relative;
  width: 75%;
  margin-left: auto;
  padding-right: 4rem;
}
@media screen and (max-width: 991px) {
  .about_page .ourteams_section .ourteam_swiper {
    width: 80%;
    /* width: 27rem; */
    padding-right: 2rem;
  }
}
.about_page .ourteams_section .swiper-wrapper {
  padding: 2rem 0;
}
.about_page .ourteams_section .swipercontent {
  /* width: 85%; */
  margin: 0 auto;
  padding: 0 0.6rem;
}
.about_page .ourteams_section .swipercontent img {
  height: calc(100vh - 40vh);
  object-fit: cover;
}
.about_page .ourteams_section .swipercontent button {
  background-color: #94db16;
  border: unset;
  border-radius: 2rem;
  color: black;
  height: auto;
  width: auto;
  cursor: pointer;
  transition: opacity 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 2rem;
  font-family: IBMPlexMono-Regular;
  font-weight: 400;

  position: absolute;
  bottom: 11%;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 1500px) {
  .about_page .ourteams_section .swipercontent img {
    /* height: 29rem; */
  }
}
.about_page .ourteams_section .swipercontent h5 {
  text-transform: uppercase;
  font-weight: 100;
  font-size: 1.5rem;
  font-family: DrukWide-Medium-Trial;
}
.about_page .ourteams_section .swiper-slide-active h5 {
  color: #71a513;
}
.about_page .ourteams_section .swiper-slide {
  filter: grayscale(1);
  opacity: 0.6;
}
@media screen and (max-width: 1500px) {
  .about_page .ourteams_section .swiper-slide {
    /* margin-right: 3rem !important; */
  }
}
.about_page .ourteams_section .swiper-slide-active {
  filter: grayscale(0);
  opacity: 1;
}
.about_page .ourteams_section .active-main {
  filter: grayscale(0);
  opacity: 1;
}
.about_page .ourteams_section .swiper-slide-active .swipercontent::before {
  content: '';
  display: block;
  width: 100%;
  height: 2rem;
  background-image: url(../../../public/img/topleftborder.svg),
    url(../../../public/img/toprightborder.svg);
  background-position:
    top left,
    top right;
  position: absolute;
  top: -0.5rem;
  right: 0;
  background-repeat: no-repeat;
}
.about_page .ourteams_section .swiper-slide-active .swipercontent::after {
  content: '';
  display: block;
  width: 100%;
  height: 2rem;
  background-image: url(../../../public/img/bottomlefttborder.svg),
    url(../../../public/img/bottomrightborder.svg);
  background-position:
    bottom left,
    bottom right;
  position: absolute;
  bottom: -0.5rem;
  right: 0;
  background-repeat: no-repeat;
}
.about_page .ourteams_section .swiper_arrows {
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  z-index: 11;
}
@media screen and (max-width: 991px) {
  .about_page .ourteams_section .swiper_arrows {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
.about_page .ourteams_section .swiper_arrows button {
  margin: 1rem;
  width: 6rem;
  height: 6rem;
  background-color: #fff;
  border: unset;
  /* border: 1px solid #fefbf7; */
}
@media screen and (max-width: 991px) {
  .about_page .ourteams_section .swiper_arrows button {
    margin: 0rem;
    width: 4rem;
    height: 4rem;
  }
}
.about_page .ourteams_section .swiper_arrows button.disabled {
  opacity: 0.5;
  cursor: default;
}
@media screen and (max-width: 991px) {
  .about_page .ourteams_section .swiper_arrows button.disabled {
    opacity: 0.5;
    cursor: default;
    /* display: none; */
  }
}
.about_page .ourteams_section .swiper_arrows button.disabled .bg_fill {
  display: none;
}
.about_page .ourteams_section .swiper_arrows button svg {
  position: relative;
  height: 1.2rem;
  width: 2.5rem;
}
.about_page .ourservices_title {
  margin-top: 10rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 991px) {
  .about_page .ourservices_title {
    flex-direction: column;
    margin-top: 4rem;
  }
}
.about_page .ourservices_title h1 {
  color: #263310;
  margin: 0;
  margin-left: -1rem !important;
  overflow: hidden;
  font-size: 9.6vw;
  margin-left: 0rem;
  white-space: nowrap;
  text-transform: uppercase;
  font-family: 'DrukWide-Medium-Trial';
  text-align: center;
  line-height: 1;
  text-shadow: 0 0 5px #263310;
  font-weight: 100;
}
.about_page .ourservices_title h2 {
  color: #fff;
  margin: 0;
  font-size: 4rem;
  text-transform: uppercase;
  font-family: 'DrukWide-Medium-Trial';
  text-align: left;
  line-height: 1;
  font-weight: 100;
  margin-bottom: 2rem;
}
@media screen and (max-width: 991px) {
  .about_page .ourservices_title h2 {
    flex-direction: column;
    justify-content: flex-start;
    width: 75%;
  }
}
.about_page .ourservices_title h2 span {
  display: block;
}
.about_page .ourservices_title p {
  color: #fefbf7;
  margin: 0;
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.4;
  font-weight: 100;
  position: absolute;
  top: 57%;
  width: 40%;
  margin: 0 auto;
}
@media screen and (max-width: 991px) {
  .about_page .ourservices_title p {
    position: relative;
    width: 75%;
    text-align: left;
    font-size: 1.2rem;
    margin: 0 auto;
  }
}
.about_page .services_list {
  margin-top: 10rem;
  padding-bottom: 2rem;
  position: relative;
}
@media screen and (max-width: 991px) {
  .about_page .services_list {
    margin-top: 5rem;
  }
}
.about_page .services_list .container {
  justify-content: center;
  margin: 0 auto;
}
@media screen and (max-width: 991px) {
  .about_page .services_list .container {
    max-width: 85%;
    margin: 0 auto 0 1.2rem;
  }
}
.about_page .services_list .service_block {
  display: flex;
  position: relative;
  transition: all 2s ease-in;
  height: 25rem;
}
.about_page .services_list .service_block_last {
  display: flex;
  position: relative;
  transition: all 2s ease-in;
  /* height: 1rem; */
}
@media screen and (max-width: 991px) {
  .about_page .services_list .service_block::before {
    content: '';
    width: 100%;
    position: absolute;
    top: 0;
    display: block;
    border-top: 2px solid;
    border-image: linear-gradient(
        90deg,
        #0c0c0c -9%,
        #ffffff4d 90%,
        #0c0c0c 100%
      )
      1;
  }
}
.about_page .services_list .service_block h2 {
  font-size: 2.5rem;
  margin: 0;
  font-family: 'DrukWide-Medium-Trial';
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.3;
}
@media screen and (max-width: 768px) {
  .about_page .services_list .service_block h2 {
    font-size: 2rem;
  }
}
.about_page .services_list .service_block p {
  font-size: 1rem;
  padding-left: 3rem;
  line-height: 1.3;
  margin-top: 1.5rem;
}
@media screen and (max-width: 991px) {
  .about_page .services_list .service_block p {
    display: none;
  }
}
.about_page .services_list .service_block .hover_img {
  opacity: 0;
  position: absolute;
  width: 62%;
  transition: all 0.5s ease-in;
}
@media screen and (max-width: 991px) {
  .about_page .services_list .service_block .hover_img {
    display: none !important;
  }
}
.about_page .services_list .service_block.rightblock .hover_img {
  right: 0;
}
.about_page .services_list .service_block:hover .hover_img {
  opacity: 1;
}
.about_page .services_list .service_block.leftblock .hover_img {
}
.about_page .services_list .service_block.block1 {
  /* background-repeat: no-repeat;
    height: 25rem;
    background-size: contain;
    background-position: bottom left; */
}
.about_page .services_list .service_block:hover.block1 {
  /* background-image: url(../../../public/img/services/service1_bg.png); */
}
.about_page .services_list .service_block.block2 {
  /* align-items: end;
    background-repeat: no-repeat;
    height: 25rem;
    background-size: contain;
    background-position: top right; */
}
.about_page .services_list .service_block:hover.block2 {
  /* background-image: url(../../../public/img/services/service2_bg.png); */
}
.about_page .services_list .service_block.block3 {
  /* background-repeat: no-repeat;
    height: 25rem;
    background-size: contain;
    background-position: bottom left;
    margin-top: 2rem; */
}
.about_page .services_list .service_block:hover.block3 {
  /* background-image: url(../../../public/img/services/service3_bg.png); */
}
.about_page .services_list .service_block.block4 {
  /* align-items: end;
    background-repeat: no-repeat;
    height: 25rem;
    background-size: contain;
    background-position: top right;
    margin-top: 2rem; */
}
.about_page .services_list .service_block:hover.block4 {
  /* background-image: url(../../../public/img/services/service4_bg.png); */
}
.about_page .services_list .service_block.rightblock {
  align-items: end;
}
.about_page .services_list .service_block .content {
  width: 85%;
  padding-block: 2rem;
  padding-inline: 2rem;
  margin-left: auto;
  position: relative;
  height: max-content;
}
@media screen and (max-width: 991px) {
  .about_page .services_list .service_block .content {
    margin: 0;
  }
}
.about_page .services_list .service_block.leftblock .content {
  margin-left: 0;
}
.about_page .services_list .service_block .content button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #94db16;
  height: 6.3rem;
  width: 6.3rem;
  cursor: pointer;
  border-radius: 50%;
  border: unset;
  transition: all 0.5s ease-in;
  position: absolute;
}
@media screen and (max-width: 991px) {
  .about_page .services_list .service_block .content button {
    display: none;
  }
}
.about_page .services_list .service_block .content button {
  opacity: 0;
}
.about_page .services_list .service_block:hover .content button {
  opacity: 1;
}
.about_page .services_list .service_block.leftblock .content button {
  top: 80%;
  right: -10%;
}
.about_page .services_list .service_block.rightblock .content button {
  top: 60%;
  left: -11%;
}
@media screen and (max-width: 991px) {
  .about_page .services_list .service_block {
    background-image: unset !important;
    height: unset !important;
    margin: 0 !important;
    padding: 1rem 0;
    align-items: center !important;
    justify-content: space-between;
  }
}
.about_page .services_list .serviceslideup_line {
  position: absolute;
  left: -14rem;
  top: 50%;
  z-index: 11;
  width: 30rem;
}
@media screen and (max-width: 991px) {
  .about_page .services_list .serviceslideup_line {
    top: -37%;
  }
}
.about_page .reelsection {
  position: relative;
  margin-top: 3rem;
}
@media screen and (max-width: 991px) {
  .about_page .reelsection {
    margin-top: 0rem;
  }
  .about_page .reelsection .container {
    max-width: 85%;
  }
}
.about_page .reelsection .reelslideup_line {
  position: absolute;
  left: -14rem;
  top: 50%;
  z-index: 11;
  width: 30rem;
}
.about_page .reelsection h1 {
  text-align: center;
  text-transform: uppercase;
  font-size: 6rem;
  margin: 0;
  color: #ffffff14;
  font-family: 'DrukWide-Medium-Trial';
  font-weight: 400;
}
/* @media screen and (max-width: 768px) {
  .about_page .reelsection h1 {
    font-size: 9vw;
    margin-bottom: -6.4%;
  }
} */

/* aryah css code */
@media screen and (max-width: 768px) {
  .about_page .reelsection h1 {
    font-size: 9vw;
    margin-bottom: 2.6%;
  }
}
@media screen and (max-width: 576px) {
  .about_page .reelsection h1 {
    font-size: 9vw;
    margin-bottom: 4.6%;
  }
}
@media screen and (max-width: 300px) {
  .about_page .reelsection h1 {
    font-size: 9vw;
    margin-bottom: 5.6%;
  }
}

.about_page .reelsection .reeliframe_main {
  position: relative;
  overflow: hidden;
  padding: 1rem;

  margin-top: -3.3rem;
}
.about_page .reelsection .reeliframe_main .reeliframe-border {
  padding: 1rem;
  background:
    url(/public/temp_img/Rectangle\ 229.svg) left top no-repeat,
    url(/public/temp_img/Rectangle\ 230.svg) right top no-repeat,
    url(/public/temp_img/Rectangle\ 231.svg) left bottom no-repeat,
    url(/public/temp_img/Rectangle\ 232.svg) right bottom no-repeat;
}
.about_page .reelsection .reeliframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}
.about_page .reelsection .reeliframe_main::before {
  content: '';
  display: block;
  width: 100%;
  height: 3rem;
  /* background-image: url(../../../public/img/topleftborder.svg),
    url(../../../public/img/toprightborder.svg);
  background-position:
    top left,
    top right;
  position: absolute; */
  top: 0rem;
  right: 0;
  background-repeat: no-repeat;
  background-size: 2.3%;
}
.about_page .reelsection .reeliframe_main::after {
  content: '';
  display: block;
  width: 100%;
  height: 3rem;
  /* background-image: url(../../../public/img/bottomlefttborder.svg),
    url(../../../public/img/bottomrightborder.svg);
  background-position:
    bottom left,
    bottom right;
  position: absolute; */
  bottom: 0rem;
  right: 0;
  background-repeat: no-repeat;
  background-size: 2.3%;
}
.about_page .reelsection iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.about_page .reelsection .reelvideo {
  padding: 1rem;
  margin-top: -3.3rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .about_page .reelsection .reelvideo {
    margin-top: 0rem;
  }
}
.about_page .reelsection .reelvideo video {
  width: 100%;
}
.about_page .reelsection .reelvideo .playbutton {
  position: absolute;
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.about_page .reelsection .reelvideo .playbutton p {
  color: #000;
  text-align: center;
}
.about_page .reelsection .reelvideo::before {
  content: '';
  display: block;
  width: 100%;
  height: 3rem;
  background-image: url(../../../public/img/topleftborder.svg),
    url(../../../public/img/toprightborder.svg);
  background-position:
    top left,
    top right;
  position: absolute;
  top: 0rem;
  right: 0;
  background-repeat: no-repeat;
  background-size: 2.3%;
}
.about_page .reelsection .reelvideo::after {
  content: '';
  display: block;
  width: 100%;
  height: 3rem;
  background-image: url(../../../public/img/bottomlefttborder.svg),
    url(../../../public/img/bottomrightborder.svg);
  background-position:
    bottom left,
    bottom right;
  position: absolute;
  bottom: 0rem;
  right: 0;
  background-repeat: no-repeat;
  background-size: 2.3%;
}
.about_page .ourstorySection {
  margin-top: 5rem;
}
.about_page .ourstorySection .headline {
  font-family: DrukWide-Medium-Trial;
  font-size: 5rem;
  color: #fff;
  letter-spacing: 3px;
  text-transform: uppercase;
  line-height: 1.1;
  display: inline-flex;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 991px) {
  .about_page .ourstorySection .headline {
    font-size: 4rem;
    padding: 0 1rem;
  }
  .about_page .ourstorySection .container {
    padding: 0;
  }
}
@media screen and (max-width: 576px) {
  .about_page .ourstorySection .headline {
    font-size: 3rem;
  }
}
.about_page .ourstorySection .headline:first-child {
  padding-left: 3rem;
  width: 100%;
}
@media screen and (max-width: 991px) {
  .about_page .ourstorySection .headline:first-child {
    padding-left: 1.5rem;
    width: unset;
  }
}
.about_page .ourstorySection .headline span {
  display: block;
  font-size: 4rem;
  background: #fff;
  width: 6rem;
  height: 0.2rem;
  background-color: #fff;
  margin-left: 1.5rem;
  margin-top: 1rem;
}
.wrapup-timeline {
  background-image: url(https://devsidecms.ptw.com/wordpress/wp-content/uploads/2024/01/ourstory_background.png);
  background-repeat: no-repeat;
  background-position-x: 32%;

  background-size: 80%;

  position: relative;
}
.wrapup-timeline .leftshadow {
  width: 50% !important;
  right: -10%;
  top: 10%;
  opacity: 0.25;
  filter: blur(40px);
}
.wrapup-timeline .Rightshadow {
  display: block;
  position: absolute;

  width: 50%;
  top: 18%;
  height: 90%;
  background-color: #ffffff57;
  opacity: 0.15;
  filter: blur(65px);
  border-radius: 50%;
  transform: rotate(355deg);
  z-index: -1;
  left: -7%;
}
.about_page .timelineSection {
  margin-top: 6rem;
  margin-bottom: 17rem;
  /* background-image: url(https://devsidecms.ptw.com/wordpress/wp-content/uploads/2024/01/ourstory_background.png);
  background-repeat: no-repeat;
  background-position: top;
  position: relative; */
}
.about_page .timelineSection .topshadow {
  position: absolute;
  width: 100%;
  right: 0;
  top: 40%;
  height: 40%;
  background-color: #ffffff57;
  opacity: 0.15;
  filter: blur(80px);
  border-radius: 50%;
  z-index: -1;
}
.about_page .timelineSection .bottomshadow {
  position: absolute;
  width: 100%;
  left: 5%;
  bottom: -10%;
  height: 40%;
  background-color: #ffffff57;
  opacity: 0.15;
  filter: blur(80px);
  border-radius: 50%;
  /* transform: rotate(355deg); */
  z-index: -1;
}
@media screen and (max-width: 991px) {
  .about_page .timelineSection .container {
    padding: 0;
  }
  .about_page .timelineSection {
    margin-bottom: 0;
    border-bottom: 1px solid #ffffff40;
    padding-bottom: 6rem;
  }
}
.about_page .timelineSection .sofarSection {
  padding-left: 6rem;
}
@media screen and (max-width: 991px) {
  .about_page .timelineSection .sofarSection {
    padding-left: 0;
    width: 90%;
    margin: 0 auto;
  }
}
.about_page .timelineSection .sofarSection h2 {
  font-family: DrukWide-Medium-Trial;
  font-size: 3rem;
  color: #fff;
  letter-spacing: 3px;
  text-transform: uppercase;
  line-height: 1.1;
}
@media screen and (max-width: 991px) {
  .about_page .timelineSection .sofarSection h2 {
    font-size: 2rem;
  }
}
.about_page .timelineSection .sofarSection p {
  /* width: 25%; */
  margin-top: 2rem;
  padding-left: 2rem;
  height: 12rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.about_page .timelineSection .sofarSection p::-webkit-scrollbar {
  /* visibility: hidden; */
  background-color: #fff;
  width: 2px;
  border-radius: 6px;
}

.about_page .timelineSection .sofarSection p::-webkit-scrollbar-thumb {
  background-color: #94db16;
  border-radius: 6px;
}
.about_page .timelineSection .sofarSection p::-moz-scrollbar {
  background-color: #fff;
  width: 2px;
  border-radius: 6px;
}

.about_page .timelineSection .sofarSection p::-moz-scrollbar-thumb {
  background-color: #94db16;

  border-radius: 6px;
}

.about_page .timelineSection .sofarSection p::-ms-scrollbar {
  background-color: #fff;

  width: 2px;
  border-radius: 6px;
}

.about_page .timelineSection .sofarSection p::-ms-scrollbar-thumb {
  background-color: #94db16;

  border-radius: 6px;
}

.about_page .timelineSection .sofarSection .arrows {
  margin-top: 1.5rem;
  padding-left: 2rem;
}

/* aryan css code */

.about_page .about-heading {
  overflow: hidden;
}
.about_page .about-subheading {
  opacity: 0;
  margin: 0 1rem;
}
/* .about_page .ourteam_swiper .swiper-slide-prev {
  width: 20% !important;
} */
/* new code css start */
.about_page .ourteam_swiper .swiper-slide-prev {
  width: 50% !important;
}
@media screen and (min-width: 1030px) {
  .about_page .ourteams_section .swiper-slide-prev h5 {
    text-align: end;
    margin-right: 10%;
  }
}
/* new code css end */

@media screen and (max-width: 1030px) {
  .about_page .ourteam_swiper .swiper-slide-prev {
    width: 100% !important;
  }
}
@media screen and (max-width: 768px) {
  .about_page .ourteam_swiper .swiper-slide-prev {
    /* width: 320px !important; */
  }
  .about_page .timeline_graph .detail_content {
    height: 9rem !important;
  }
}

.about_page .timeline_graph {
  width: 100%;
  /* margin: 0 auto; */
  height: 28vh;
  display: flex;
  align-items: end;
  justify-content: center;
  position: relative;
}
@media screen and (max-width: 991px) {
  .about_page .timeline_graph {
    height: unset;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    overflow: hidden;
    padding-bottom: 3rem;
  }
  .about_page .timeline_graph .swiper_arrows {
    width: 100%;
    justify-content: space-between !important;
    width: 90%;
    margin: 5rem auto 2rem auto;
  }
  .about_page .timeline_graph .timeline_points .leftShadow {
    display: none;
  }
  .about_page .timeline_graph .timeline_points {
    margin-right: 230rem !important;
  }
}
@media screen and (max-width: 576px) {
  .about_page .timeline_graph {
    width: 90%;
    overflow: hidden;
    padding-bottom: 4rem;
  }
  .about_page .timeline_graph .timeline_points {
    margin-right: 255rem !important;
  }
  .about_page .timeline_graph .detail_content {
    height: 12rem !important;
  }
  .about_page .timeline_graph .swiper_arrows {
    width: 100%;
    justify-content: space-between !important;
    width: 90%;
    margin: 2rem auto;
  }
}
.about_page .timeline_graph .timeline_points {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  position: relative;
  margin-right: 28rem;
}
.about_page .timeline_graph .timeline_points .point {
  border-radius: 50%;
  margin: 0 1.5rem;
  width: 0.45rem;
  min-width: 0.45rem;
  height: 0.45rem;
  /* opacity: 30%; */
  background-color: #555555;
}
.about_page .timeline_graph .point_wrap_active .point {
  border-radius: 50%;
  margin: 0 1.5rem;
  width: 0.45rem;
  min-width: 0.45rem;
  height: 0.45rem;
  /* opacity: 30%; */
  /* background-color: #555555; */
}
@media screen and (max-width: 1300px) {
  .about_page .timeline_graph .timeline_points .point {
    margin: 0 1.2rem;
  }
  .about_page .timeline_graph .point_wrap_active .point {
    margin: 0 1.2rem;
  }
}
.about_page .timeline_graph .point_wrap_active .point.highlight {
  width: 0.55rem;
  min-width: 0.55rem;
  height: 0.55rem;
  background-color: var(--primary-color);
  cursor: pointer;
  opacity: 1;
}
.about_page .timeline_graph .timeline_points .point.highlight {
  width: 0.55rem;
  min-width: 0.55rem;
  height: 0.55rem;
  background-color: var(--primary-color);
  cursor: pointer;
  opacity: 1;
}
.about_page .timeline_graph .point_wrap_active .point.highlight.active {
  width: 0.75rem;
  min-width: 0.75rem;
  height: 0.75rem;
  outline: 1px solid #fff;
  border: 4px solid #0c0c0c;
  position: relative;
}
.about_page .timeline_graph .timeline_points .point.highlight.active {
  width: 0.75rem;
  min-width: 0.75rem;
  height: 0.75rem;
  outline: 1px solid #fff;
  border: 4px solid #0c0c0c;
  position: relative;
  right: 0.69rem;
  top: 6px;
}
.about_page .timeline_graph .point_wrap_active .point.highlight.active::before {
  content: '';
  display: block;
  border-left: 1px solid #fff;
  border-image: linear-gradient(0deg, #ffffffbf 70%, #0c0c0c 100%) 1;
  height: 40vh;
  position: absolute;
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}
/* .about_page .timeline_graph .timeline_points .point.highlight.active::before {
    content: "";
    display: block;
    border-left: 1px solid #fff;
    border-image: linear-gradient(0deg, #ffffffbf 70%, #0c0c0c 100%) 1;
    height: 40vh;
    position: absolute;
    bottom: 1em;
    left: 50%;
    transform: translateX(-50%);
  } */
.about_page .timelineSection .swiper_arrows button.disabled {
  opacity: 0.3;
  cursor: default;
}

.about_page .swiper_arrows .nextarrow-last:hover {
  background-color: transparent !important;
}

@media screen and (max-width: 991px) {
  .about_page .timelineSections .swiper_arrows button.disabled {
    opacity: 0.3;
    cursor: default;
    display: none;
  }
}
.about_page .timelineSection .swiper_arrows button.disabled .bg_fill {
  display: none;
}
@media screen and (max-width: 991px) {
  .about_page
    .timeline_graph
    .point_wrap_active
    .point.highlight.active::before {
    content: none;
  }
  .about_page .timeline_graph .timeline_points .point.highlight.active::before {
    content: none;
  }
  .about_page .timeline_graph .point_wrap_active {
    top: -2.6rem !important;
  }
  .about_page .timeline_graph .detail_content p {
    height: 7.8rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.about_page .timeline_graph .point_wrap_active {
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 0.38rem;
}
.about_page .timeline_graph .timeline_points .point_wrap {
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  width: 5.7rem;
}
.about_page .timeline_graph .timeline_points .leftShadow {
  height: 100%;
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .about_page .timeline_graph .timeline_points .leftShadow {
    display: none;
  }
}
/* keeping code for future use */
/* .about_page .timeline_graph .timeline_points .point_wrap:nth-child(1) {
  opacity: 0.2;
}
.about_page .timeline_graph .timeline_points .point_wrap:nth-child(2) {
  opacity: 0.4;
}
.about_page .timeline_graph .timeline_points .point_wrap:nth-child(3) {
  opacity: 0.5;
}
.about_page .timeline_graph .timeline_points .point_wrap:nth-child(4) {
  opacity: 0.5;
}
.about_page .timeline_graph .timeline_points .point_wrap:nth-last-child(2) {
  opacity: 0.2;
}
.about_page .timeline_graph .timeline_points .point_wrap:nth-last-child(3) {
  opacity: 0.4;
} */
.about_page .timeline_graph .timeline_points .point_wrap .year {
  position: absolute;
  text-align: center;
  font-size: 0.8rem;
  margin-top: 4rem;
  bottom: -2rem;
}
.about_page .timeline_graph .detail_content {
  position: absolute;
  top: 0;
  left: 4rem;
  width: 20rem;
  text-align: left;
  z-index: 11;
}
.about_page .timeline_graph .point_wrap_active .detail_content_active {
  position: absolute;
  bottom: 6rem !important;
  left: 4rem;
  width: 20rem;
  text-align: left;
  z-index: 11;
}
.about_page .timeline_graph .point_wrap_active .detail_content_active p {
  height: 12rem;
  overflow-y: auto;
  overflow-x: hidden;
}
@media screen and (max-width: 576px) {
  .about_page .timeline_graph .detail_content p {
    height: 8.7rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .about_page .timelineSection .sofarSection p {
    /* width: 25%; */
    margin-top: 2rem;
    padding-left: 2rem;
    height: 8rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.about_page
  .timeline_graph
  .point_wrap_active
  .detail_content_active
  p::-webkit-scrollbar,
.about_page .timeline_graph .detail_content p::-webkit-scrollbar {
  /* visibility: hidden; */
  background-color: #fff;
  width: 2px;
  border-radius: 6px;
}

.about_page
  .timeline_graph
  .point_wrap_active
  .detail_content_active
  p::-webkit-scrollbar-thumb,
.about_page .timeline_graph .detail_content p::-webkit-scrollbar-thumb {
  background-color: #94db16;
  border-radius: 6px;
}
.about_page
  .timeline_graph
  .point_wrap_active
  .detail_content_active
  p::-moz-scrollbar,
.about_page .timeline_graph .detail_content p::-moz-scrollbar {
  background-color: #fff;
  width: 2px;
  border-radius: 6px;
}

.about_page
  .timeline_graph
  .point_wrap_active
  .detail_content_active
  p::-moz-scrollbar-thumb,
.about_page .timeline_graph .detail_content p::-moz-scrollbar-thumb {
  background-color: #94db16;

  border-radius: 6px;
}

.about_page
  .timeline_graph
  .point_wrap_active
  .detail_content_active
  p::-ms-scrollbar,
.about_page .timeline_graph .detail_content p::-ms-scrollbar {
  background-color: #fff;

  width: 2px;
  border-radius: 6px;
}

.about_page
  .timeline_graph
  .point_wrap_active
  .detail_content_active
  p::-ms-scrollbar-thumb,
.about_page .timeline_graph .detail_content p::-ms-scrollbar-thumb {
  background-color: #94db16;

  border-radius: 6px;
}

@media screen and (max-width: 991px) {
  .about_page .timeline_graph .detail_content {
    width: 90%;
    margin: 0 auto;
    margin-top: 5rem;
    position: relative;
    left: unset;
    height: 8rem;
  }
  .about_page .timeline_graph .detail_content .leftCorner {
    position: absolute;
    top: -0.9rem;
    left: -1.2rem;
  }
  .about_page .timeline_graph .detail_content .rightCorner {
    position: absolute;
    top: -0.9rem;
    right: -1.2rem;
  }
}
.about_page .timeline_graph .detail_content h5 {
  font-family: DrukWide-Medium-Trial;
  font-size: 1.3rem;
  font-weight: 100;
  color: #94db164d;

  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 1.1;
  margin-bottom: 0.5rem;
}

.about_page .timeline_graph .detail_content_active h5 {
  font-family: DrukWide-Medium-Trial;
  font-size: 1.3rem;
  font-weight: 100;
  color: #94db164d;

  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 1.1;
  margin-bottom: 0.5rem;
}
.about_page .timeline_graph .detail_content h6 {
  font-family: DrukWide-Medium-Trial;
  font-size: 1.3rem;
  font-weight: 100;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 1.1;
  margin-bottom: 1.2rem;
}
.about_page .timeline_graph .detail_content_active h6 {
  font-family: DrukWide-Medium-Trial;
  font-size: 1.3rem;
  font-weight: 100;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 1.1;
  margin-bottom: 1.2rem;
}
.about_page .timeline_graph .dragCursor {
  position: absolute;
  display: flex;
  align-items: center;
  /* right: 11rem;
      bottom: -5rem; */
}
@media screen and (max-width: 1300px) {
  .about_page .timeline_graph .dragCursor {
    left: 7rem;
    top: 0;
  }
}
.about_page .timeline_graph .dragCursor .dragCircle {
  width: 6rem;
  min-width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grabbing;
}
.about_page .timeline_graph .dragCursor .dragCircle p {
  color: #000;
  margin: 0;
}

.about_page .section8 .verticalLine.left {
  border-image: linear-gradient(0deg, #262626 50%, #0c0c0c 90%) 1;
  height: 60rem;
}
.about_page .section8 .rightcontent .verticalLine.right {
  height: 52rem;
  border-image: linear-gradient(0deg, #262626 80%, #0c0c0c 100%) 1;
}
#mainDiv {
  transition: transform 0.5s ease;
}

/* aryan code  */
.about_page .ourstorySection .timelineslideup_line {
  position: absolute;
  left: -14rem;
  top: 122%;
  width: 30rem;
  z-index: 11;
}
@media screen and (max-width: 576px) {
  .about_page .ourstorySection .timelineslideup_line {
    top: 260%;
  }
  .about_page .ourstorySection .timelineslideup_line .sidelineour {
    display: inline-block !important;
  }
  .about_page .timeline_graph .point_wrap_active {
    top: -3.6rem !important;
  }
}

.about_page .ourstorySection {
  position: relative;
}
.about_page .timeline_graph {
  position: relative;
}

.about_page .timeline_graph .ourclientslideup_line {
  position: absolute;
  left: -13rem;
  top: 28%;
  width: 30rem;
  z-index: 11;
}
#cursor-service {
  z-index: 99;
  position: fixed;
}
/* position: relative; */
/* pointer-events: none; */
/* position:absolute; */
/* transform: translate(-50%, -50%);

  /* will-change: transform;
} */

/* .leftblock{
  background-color: red;
}
.rightblock{
  background-color: green;
} */
@media (min-width: 1000px) and (max-width: 1280px) {
  .about_page .timeline_graph .timeline_points .leftShadow {
    width: 70% !important;
  }
}

.about_page .timelineSection .timeline-dragger {
  position: absolute;
  bottom: -2.4rem;
  /* background-color: red; */
  width: 100%;
  height: 2.2rem;
}
.service_block {
  margin-top: 2rem !important;
}
.about-container .leftshadow {
  display: block;
  position: absolute;

  width: 100%;
  top: 5%;
  height: 70%;
  background-color: #ffffff57;
  opacity: 0.15;
  filter: blur(80px);
  border-radius: 50%;
  transform: rotate(355deg);
  z-index: -1;
}
@media screen and (max-width: 768px) {
  .about-container .leftshadow {
    top: 14%;
    opacity: 0.65;
    width: 50%;
    height: 40%;
  }
  .wrapup-timeline .leftshadow {
    display: none !important;
  }
  .wrapup-timeline .Rightshadow {
    display: none !important;
  }
  .wrapup-timeline {
    background-image: none;
  }
}

.about_page .left_border1{
  position: absolute;
  border-image: linear-gradient(0deg, #262626 30%, #0c0c0c 50%, #262626 30%) 1;

  
  left: 16.6%;
  border-left: 1px solid;
  z-index:-20;
  bottom:-10%;
  height: 200rem;
}

.about_page .left_border2{
  position: absolute;
  border-image: linear-gradient(0deg, #262626 30%, #0c0c0c 50%, #262626 30%) 1;
  left: 33.2%;
  border-left: 1px solid;
  z-index:-20;
  bottom:30%;
  height: 150rem;
}

.about_page .left_border3{
  position: absolute;
  border-image: linear-gradient(0deg, #262626 30%, #0c0c0c 50%, #262626 30%) 1;
  left: 50%;
  border-left: 1px solid;
  z-index:-20;
  bottom:20%;
  height: 150rem;
}
.about_page .left_border4{
  position: absolute;
  border-image: linear-gradient(0deg, #262626 30%, #0c0c0c 50%, #262626 30%) 1;
  left: 66.6%;
  border-left: 1px solid;
  z-index:-20;
  bottom:40%;
  height: 150rem;
}
.about_page .left_border5{
  position: absolute;
  border-image: linear-gradient(0deg, #262626 30%, #0c0c0c 50%, #262626 30%) 1;
  left: 83.26%;
  border-left: 1px solid;
 z-index:-20;
  bottom:5%;
  height: 150rem;
}