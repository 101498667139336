.contactPage .TopSection {
  background-image: url(../../../public/img/contact/contact-bars-bg.png);
  background-repeat: no-repeat;
  background-position: 0% 20%;
}
.contactPage .TopSection .leftshadow {
  display: block;
  position: absolute;
  left: -30%;
  width: 100%;
  top: 30%;
  height: 100%;
  background-color: #ffffffa8;
  opacity: 0.15;
  filter: blur(80px);
  border-radius: 50%;
  transform: rotate(5deg);
  z-index: -1;
}
@media screen and (max-width: 991px) {
  .contactPage .TopSection .leftshadow {
    left: 0;
    width: 50%;
    top: 0%;
    height: 45%;
    opacity: 0.25;
  }
}
.contactPage .TopSection .mapbg {
  position: absolute;
  right: 0;
  z-index: -11;
  top: 13%;
  text-align: end;
}
.contactPage .TopSection .mapbg img {
  opacity: 0.4;
  width: 90%;
}
.contactPage .TopSection .rightshadow {
  display: block;
  position: absolute;
  right: 0%;
  width: 100%;
  top: 0;
  height: 100%;
  background: linear-gradient(#0c0c0ca6 40%, #000000b8 50%);
  opacity: 0.6;
  filter: blur(20px);
}
.contactPage .Headsection {
  padding-top: 10rem;
}

@media screen and (max-width: 991px) {
  .contactPage .Headsection {
    padding-left: 0;
    padding-right: 0;
    padding-top: 3rem;
  }
  .contactPage .Headsection .headTitle {
    text-align: center;
  }
}
.contactPage .Headsection h1 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 5rem;
  line-height: 1.2;
  font-family: DrukWide-Medium-Trial;
}
@media screen and (max-width: 991px) {
  .contactPage .Headsection h1 {
    font-size: 7vw;
  }
}
.contactPage .Headsection h1.second {
  padding-left: 5rem;
}
@media screen and (max-width: 991px) {
  .contactPage .Headsection h1.second {
    padding-left: 0rem;
  }
}
.contactPage .contact_card {
  margin-top: 5rem;
  position: relative;
}
@media screen and (max-width: 991px) {
  .contactPage .contact_card {
    display: flex;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
}
.location_container
{
 position: relative;
}
.contactPage .contact_card .container {
  display: flex;
}
.contactPage .contact_card .contactslideup_line {
  position: absolute;
  left: -14rem;
  top: 49%;
  z-index: 11;
  width: 30rem;

}
.contactPage .locationslideup_line {
  position: absolute;
  left: -14rem;
  top: 170%;
  z-index: 11;
  width: 30rem;

}

@media screen and (max-width: 991px) {
  .contactPage .contact_card .contactslideup_line {
    /* display: none; */
    top:15%;
  }
  .contactPage .locationslideup_line {
    position: absolute;
    left: -14rem;

    z-index: 11;
    width: 30rem;
  
  }
}
.contactPage .contact_card .contact_box {
  border: 1px solid #ffffff4d;
  /* opacity: 0.3; */
  background-color: #0c0c0c59;
  padding: 3rem;
  width: 75%;
  margin: 0 auto;
}
@media screen and (min-width: 1700px) {
  .contactPage .contact_card .contact_box {
    padding: 5rem;
    width: 80%;
  }
}
@media screen and (max-width: 991px) {
  .contactPage .contact_card .contact_box {
    padding: 2rem;
    /* earlier width 100% addec width for sideline */
    width: 75%;
  }
}
.contactPage .contact_box .contact_field {
  margin-bottom: 2rem;
  margin-top: 1rem;
}
.contactPage .contact_box .contact_field label {
  font-size: 1rem;
  margin: 0;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  cursor: pointer;
}
@media screen and (max-width: 576px) {
  .contactPage .contact_box .contact_field {
    font-size: 1rem;
    margin-top: 0.8rem;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .contactPage .contact_box .form-group {
    margin-top: 2rem !important ;
  }
}
.contactPage .contact_box .contact_field input {
  font-size: 0.875rem;
  background-color: transparent;
  border: unset;
  border-bottom: 1px solid #fefbf7;
  padding: 0 0 0.5rem;
  width: 100%;
  /* color: #fff; */
  color: rgba(254, 251, 247, 0.49);
  font-family: IBMPlexMono-Regular;
  height: 1.5rem;
  margin-bottom: 0.5rem;
}
.contactPage
  .contact_box
  .contact_field
  input[type='search']::-webkit-search-cancel-button,
.contactPage
  .contact_box
  .contact_field
  input[type='search']::-webkit-search-clear-button {
  -webkit-appearance: none;
  display: none;
}
.contactPage .contact_box .contact_field textarea {
  font-size: 0.875rem;
  background-color: transparent;
  border: unset;
  border-bottom: 1px solid #fefbf7;
  padding: 0 0 0.5rem;
  width: 100%;
  color: rgba(254, 251, 247, 0.49);
  font-family: IBMPlexMono-Regular;
  margin-bottom: 0.5rem;
  /* height: 1.5rem; */
}
.contactPage .contact_box .contact_field input:focus {
  border-bottom: 1px solid #90d510 !important;
  outline: unset;
}
.contactPage .contact_box .contact_field input.invalid {
  border-bottom: 1px solid #d12121 !important;
  outline: unset;
  transition: all 1s;
}

.contactPage .contact_box .contact_field textarea:focus {
  border-bottom: 1px solid #90d510 !important;

  outline: unset;
}
.contactPage .contact_box .contact_field textarea.invalid {
  border-bottom: 1px solid #d12121 !important;
  outline: unset;
  transition: all 1s;
}

.contactPage .contact_box .contact_field span.invalid {
  text-transform: uppercase;
  color: #d12121;
  position: absolute;
  left: 0;
  top: 100%;
  filter: brightness(100%);
  /* margin-top: 0.4rem; */
  font-size: 0.75rem;
  padding-top: 0.4rem;
}
.contactPage .contact_box .contact_field_dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #fefbf7;
  padding: 0 0 0.5rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
}
.contactPage .contact_box .contact_field_dropdown .dropdown {
  /* position: relative; */
  width: 100%;
}
.contactPage .contact_box .contact_field_dropdown .selected {
  height: 1.5rem;
  color: rgba(254, 251, 247, 0.49);
}
.contactPage .contact_box .contact_field_dropdown .dropdown_container {
  position: absolute;
  background: #0c0c0c;
  border: 1px solid #fff;
  top: 1.8rem;
  z-index: 11;
  padding: 0.5rem 0;
  width: 100%;
}
/* .contactPage .contact_box .contact_field_dropdown .dropdown_container .block {
  display: block;
}
.contactPage .contact_box .contact_field_dropdown .dropdown_container .hide {
  display: none;
} */
.contactPage .contact_box .contact_field_dropdown .dropdown_container input {
  border: none;
  outline: none;
  padding-left: 1rem;
  color: #fff;
  font-size: 1rem;
}
.contactPage
  .contact_box
  .contact_field_dropdown
  .dropdown_container
  input:focus {
  border: none !important;
  outline: none;
}
/* .contactPage .contact_box .contact_field_dropdown .dropdown_list {
  display: none;
  padding: 0.5rem 0;
  max-height: 10rem;
  overflow: auto;
  position: absolute;
  background: #0c0c0c;
  border: 1px solid #fff;
  top: 1.8rem;
  width: 100%;
  z-index: 11;
} */
.contactPage .contact_box .contact_field_dropdown .dropdown_list {
  max-height: 10rem;
  overflow: auto;
}

.contactPage
  .contact_box
  .contact_field_dropdown
  .dropdown_list::-webkit-scrollbar {
  background-color: #0c0c0c;
}

.contactPage
  .contact_box
  .contact_field_dropdown
  .dropdown_list::-webkit-scrollbar-thumb {
  background: #90d510;
  border-radius: 5px;
}
.contactPage .contact_box .contact_field_dropdown .dropdown_list p {
  color: #fff;
  padding: 0.4rem 1rem;
  cursor: pointer;
}

.contactPage .contact_box .contact_field_dropdown .dropdown_list p:hover,
.contactPage .contact_box .contact_field_dropdown .dropdown_list p.active {
  color: var(--primary-color);
}
.contactPage .contact_box .form-group {
  display: inline-flex;
  align-items: center;
  width: 100%;
  position: relative;
}
.contactPage .contact_box .form-group a {
  color: var(--primary-color);
  text-decoration: unset;
  margin-left: 0.5rem;
}
.contactPage .contact_box .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.contactPage .contact_box .form-group label {
  position: relative;
  display: inline-block;

  padding-left: 25px;
  cursor: pointer;
  font-size: 0.75rem;
  line-height: 2.3;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;

}
.contactPage .contact_box .form-group label:before {
  
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border-radius: 0.3rem;
  border: 1px solid var(--primary-color);
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05); */
  padding: 0.5rem;
  display: inline-block;
  position: absolute;
 left:0;
 top:7px;
  /* vertical-align: text-top; */
  /* vertical-align: middle; */
  cursor: pointer;
  margin-right: 0.7rem;
}
.contactPage .contact_box .form-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  /* vertical-align: text-bottom !important; */
  top: 0.55rem;
  left: 0.4rem;
  width: 0.3rem;
  height: 0.7rem;
  border: solid var(--primary-color);
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}
@media  (orientation:landscape) {
  .contactPage .contact_box .form-group input:checked + label:after {
  
    
    /* top: 0.7rem !important; */
  /* bottom: 0.2rem; */
  }
  .contactPage .contact_box .form-group label:before {
  /* vertical-align: baseline !important; */
  
  }

   }

.contactPage .contact_box .form-group span.invalid {
  text-transform: uppercase;
  color: #d12121;
  position: absolute;
  filter: brightness(100%);
  /* left: 0; */
  top: 100%;
  /* margin-top: 0.3rem; */
  font-size: 0.75rem;
  width: 100%;
}
.contactPage .LocationSection {
  margin-top: 10rem;
}
.contactPage .LocationSection .largeHead {
  color: #263310;
  margin: 0;
  font-size: 9vw;
  white-space: nowrap;
  text-transform: uppercase;
  font-family: 'DrukWide-Medium-Trial';
  text-align: center;
  line-height: 0.8;
  font-weight: 100;
  overflow: hidden;
}
@media screen and (max-width: 991px) {
  .contactPage .LocationSection {
    margin-top: 6rem;
    
  }
  .contactPage .LocationSection .largeHead {
    color: #263310;
    margin: 0;
    font-size: 10vw;
    white-space: nowrap;
    text-transform: uppercase;
    font-family: 'DrukWide-Medium-Trial';
    text-align: center;
    line-height: 0.34;
    font-weight: 100;
    overflow: hidden;
    padding-top: 3rem;
  }
  .contactPage .location_container {
    padding-left: 0;
    padding-right: 0;
    margin-left: 1rem;
  }
}
.contactPage .location_container .accordion {
  border-top: 1px solid;
  border-image: linear-gradient(160deg, #0c0c0c 0%, #262626 80%, #0c0c0c 100%) 1;
  background: #0c0c0c;
}
@media screen and (max-width: 991px) {
  .contactPage .location_container .accordion {
    width: 90%;
    margin: 0 auto;
  }
}
.contactPage .location_container .accordion-body {
  padding: 2rem 0;
}
@media screen and (max-width: 991px) {
  .contactPage .location_container .accordion-body {
    padding-bottom: 0;
  }
}
.contactPage .location_container .accordion-button {
  background: transparent;
  color: #fff;
  width: 100%;
  border: unset;
  font-weight: 100;
  padding: 1rem 0;
  border-bottom: 1px solid;
  border-image: linear-gradient(160deg, #0c0c0c 0%, #262626 80%, #0c0c0c 100%) 1;
}
.contactPage .location_container .accordion-button .row {
  width: 100%;
}
.contactPage .location_container .accordion-button h3 {
  color: #fff;
  font-size: 1.7rem;
  font-family: 'DrukWide-Medium-Trial';
  font-weight: 100;
  text-transform: uppercase;
}
.contactPage .location_container .accordion-button a,
.contactPage .location_container .mbl_links a {
  color: var(--primary-color);
  font-size: 1rem;
  font-weight: 100;
  text-transform: uppercase;
  text-decoration: unset;
}
.contactPage .location_container .accordion-button .collapse_icon {
  cursor: pointer;
  transform: rotate(0deg);
  transition: 0.2s all linear;
}
.contactPage .location_container .accordion-button .collapse_icon:hover {
  transform: rotate(45deg);
  transition: 0.2s all linear;
}
.contactPage .location_container .accordion-item.rotate-cross .collapse_icon {
  transform: rotate(45deg);
}

@media screen and (max-width: 991px) {
  .contactPage .locationSlider {
    flex-direction: column-reverse;
  }
}
.contactPage .locationSlider .locationSlider_swiper {
  width: 65%;
  margin-left: 0;
  margin-right: auto;
  padding-top: 0.25rem;
  padding-bottom: 0.4rem;
  background:
    url(/public/temp_img/Rectangle\ 229.svg) left top no-repeat,
    url(/public/temp_img/Rectangle\ 230.svg) right top no-repeat,
    url(/public/temp_img/Rectangle\ 231.svg) left bottom no-repeat,
    url(/public/temp_img/Rectangle\ 232.svg) right bottom no-repeat;
}
.contactPage .locationSlider .locationSlider_swiper .swiper {
  height: 100%;
}
.swiper_arrows1 {
  margin-top: 0.8rem !important;
}
@media screen and (max-width: 991px) {
  .contactPage .locationSlider .locationSlider_swiper {
    width: 90%;
    margin: 0 auto;
  }
}
.contactPage .locationSlider .swiper .swiper-slide {
  height: unset;
}
.contactPage .locationSlider .swiper .swiperImg {
  padding: 0.5rem;
  height: 96%;
}
.contactPage .locationSlider .swiper-slide img {
  height: 100%;
  object-fit: cover;
}
.contactPage .locationSlider .swiper .swiper-slide::before {
  content: '';
  display: block;
  width: 100%;
  height: 1rem;
  /* background-image: url(../../../public/img/topleftborder.svg),
    url(../../../public/img/toprightborder.svg);
  background-position:
    top left,
    top right; */
  position: absolute;
  top: 0rem;
  right: 0;
  background-repeat: no-repeat;
}
.contactPage .locationSlider .swiper .swiper-slide::after {
  content: '';
  display: block;
  width: 100%;
  height: 1rem;
  /* background-image: url(../../../public/img/bottomlefttborder.svg),
    url(../../../public/img/bottomrightborder.svg);
  background-position:
    bottom left,
    bottom right; */
  position: absolute;
  bottom: 0rem;
  right: 0;
  background-repeat: no-repeat;
}
.contactPage .locationSlider .right_location {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contactPage .locationSlider .right_location a {
  
  color: #90d510;
  text-decoration: none;

}
@media screen and (max-width: 991px) {
  .contactPage .locationSlider .right_location {
    width: 100%;
    margin-bottom: 2rem;
  }
}
.contactPage .locationSlider .right_location .swiper_arrows {
  justify-content: flex-start;
}
.contactPage .locationSlider .location_details p {
  text-transform: uppercase;
  line-height: 1.5;
}
@media screen and (max-width: 991px) {
  .contactPage .locationSlider .location_details {
    margin-top: 0 !important;
  }
  .contactPage .locationSlider .location_details p {
    line-height: 1.8;
  }
}
.contactPage .locationSlider .locationSlider_swiper {
  position: relative;
}
.contactPage .locationSlider .locationSlider_swiper .swiper_arrows button {
  position: absolute;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
}
.contactPage
  .locationSlider
  .locationSlider_swiper
  .swiper_arrows
  button.prevarrow {
  left: -1rem;
}
.contactPage
  .locationSlider
  .locationSlider_swiper
  .swiper_arrows
  button.nextarrow {
  right: -1rem;
}
.contactPage .cta_section {
  margin: 10rem 0 5rem;
  /* background-image: url(../../../public/img/ws-banner-bkp.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
  width: 100%;
  /* min-height: 43rem; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 991px) {
  .contactPage .cta_section {
    margin: 0;
  }
}
.contactPage .cta_section .blur_stripes {
  display: flex;
  gap: 1.4rem;
  justify-content: space-between;
  width: 100%;
}
.contactPage .cta_section .blur_stripes.left {
  padding-right: 2rem;
}
.contactPage .cta_section .blur_stripes.right {
  padding-left: 2rem;
}
.contactPage .cta_section .blur_stripes .stripe {
  width: 4.5rem;
  background-color: #1a1c1c;
  filter: blur(8px);
}
.contactPage .cta_section .blur_stripes.left .stripe:first-child {
  height: 18rem;
}
.contactPage .cta_section .blur_stripes.left .stripe:nth-child(2) {
  height: 11rem;
  align-self: end;
}
.contactPage .cta_section .blur_stripes.left .stripe:last-child {
  height: 20rem;
}
.contactPage .cta_section .blur_stripes.right .stripe:first-child {
  height: 18rem;
}
.contactPage .cta_section .blur_stripes.right .stripe:nth-child(2) {
  height: 22rem;
  align-self: start;
  margin-top: -6rem;
}
.contactPage .cta_section .blur_stripes.right .stripe:last-child {
  height: 33rem;
  margin-top: -13rem;
}
.contactPage .cta_section .hover_overlay_anim {
  position: absolute;
  width: 100%;
  height: 100%;
}
.contactPage .cta_section .hover_overlay_anim img {
  height: 100%;
  filter: grayscale(0.5);
  opacity: 20%;
}
.contactPage .cta_section .hover_overlay {
  /* background-color: #0c0c0c; */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(11, 1fr);
}
@media screen and (max-width: 576px) {
  .contactPage .cta_section .hover_overlay {
    grid-template-columns: repeat(5, 1fr);
  }
}
.contactPage .cta_section .hover_overlay .block {
  border-right: 15px solid #0c0c0c;
  border-left: 15px solid #0c0c0c;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 1500px) {
  .contactPage .cta_section .hover_overlay .block {
    border-right: 0;
  }
}
@media screen and (max-width: 576px) {
  .contactPage .cta_section .hover_overlay .block.mbl-none {
    display: none;
  }
  .contactPage .cta_section .hover_overlay .block {
    border-right: 0.8rem solid #0c0c0c;
    border-left: 0.8rem solid #0c0c0c;
  }
}
.contactPage .cta_section .hover_overlay span {
  display: block;
  width: 100%;
  background: #0c0c0c;
  animation: 2s;
  transition: all 0.3s ease-in-out;
  position: relative;
}
.contactPage .cta_section .hover_overlay span:first-child:before {
  content: '';
  position: absolute;
  bottom: -0.5rem;
  left: 0rem;
  width: 0.6rem;
  height: 0.6rem;
  /* z-index: 10; */
  background: transparent;
  border-bottom-left-radius: 0.4rem;
  box-shadow: -3px 3px #0c0c0c;
  transform: rotate(92deg);
}
.contactPage .cta_section .hover_overlay span:first-child:after {
  content: '';
  position: absolute;
  bottom: -0.55rem;
  right: -0.05rem;
  width: 0.6rem;
  height: 0.6rem;
  /* z-index: 10; */
  background: transparent;
  border-bottom-left-radius: 0.5rem;
  box-shadow: -3px 3px #0c0c0c;
  /* box-shadow: -3px 3px red; */
  transform: rotate(180deg);
}
.contactPage .cta_section .hover_overlay span:last-child:before {
  content: '';
  position: absolute;
  top: -0.6rem;
  left: 0rem;
  width: 0.6rem;
  height: 0.6rem;
  /* z-index: 10; */
  background: transparent;
  border-bottom-left-radius: 0.4rem;
  box-shadow: -3px 3px #0c0c0c;
  /* box-shadow: -3px 3px red; */
  transform: rotate(6deg);
}
.contactPage .cta_section .hover_overlay span:last-child:after {
  content: '';
  position: absolute;
  top: -0.55rem;
  right: -0.04rem;
  width: 0.6rem;
  height: 0.6rem;
  /* z-index: 10; */
  background: transparent;
  border-bottom-left-radius: 0.5rem;
  box-shadow: -3px 3px #0c0c0c;
  /* box-shadow: -3px 3px red; */
  transform: rotate(260deg);
}
.contactPage .cta_section .hover_overlay .trail-block1 span {
  height: 15%;
}
.contactPage .cta_section .hover_overlay .trail-block1 .contact-hover-1 {
  height: 10%;
}
.contactPage .cta_section .hover_overlay .trail-block1 .contact-hover-2 {
  height: 10%;
}
.contactPage .cta_section .hover_overlay .trail-block2 span {
  height: 5%;
}
.contactPage .cta_section .hover_overlay .trail-block2 .contact-hover-1 {
  height: 1%;
}
.contactPage .cta_section .hover_overlay .trail-block2 .contact-hover-2 {
  height: 1%;
}
.contactPage .cta_section .hover_overlay .trail-block1 .contact-hover span {
  height: 0%;
}
.contactPage .cta_section .hover_overlay .trail-block3 span:first-child {
  height: 10%;
}
.contactPage .cta_section .hover_overlay .trail-block3 .contact-hover-1 {
  height: 5% !important;
}
.contactPage .cta_section .hover_overlay .trail-block3 span:last-child {
  height: 17%;
}
.contactPage .cta_section .hover_overlay .trail-block3 .contact-hover-2 {
  height: 12% !important;
}
.contactPage .cta_section .hover_overlay .trail-block4 span {
  height: 25%;
}
.contactPage .cta_section .hover_overlay .trail-block4 .contact-hover-1 {
  height: 20% !important;
}
.contactPage .cta_section .hover_overlay .trail-block4 .contact-hover-2 {
  height: 20% !important;
}
@media screen and (max-width: 576px) {
  .contactPage .cta_section .hover_overlay .trail-block4 span {
    height: 20%;
  }
  .contactPage .cta_section .hover_overlay .trail-block4 .contact-hover-1 {
    height: 15% !important;
  }
  .contactPage .cta_section .hover_overlay .trail-block4 .contact-hover-2 {
    height: 15% !important;
  }
}
.contactPage .cta_section .hover_overlay .trail-block5 span {
  height: 30%;
}
.contactPage .cta_section .hover_overlay .trail-block5 .contact-hover-1 {
  height: 25% !important;
}
.contactPage .cta_section .hover_overlay .trail-block5 .contact-hover-2 {
  height: 25% !important;
}
.contactPage .cta_section .hover_overlay .trail-block6 span:first-child {
  height: 34%;
}
.contactPage .cta_section .hover_overlay .trail-block6 .contact-hover-1 {
  height: 29% !important;
}
.contactPage .cta_section .hover_overlay .trail-block6 span:last-child {
  height: 18%;
}
.contactPage .cta_section .hover_overlay .trail-block6 .contact-hover-2 {
  height: 12% !important;
}
.contactPage .cta_section .hover_overlay .trail-block7 span:first-child {
  height: 7%;
}
.contactPage .cta_section .hover_overlay .trail-block7 .contact-hover-1 {
  height: 2% !important;
}
@media screen and (max-width: 576px) {
  .contactPage .cta_section .hover_overlay .trail-block7 span:first-child {
    height: 30%;
  }
  .contactPage .cta_section .hover_overlay .trail-block7 .contact-hover-1 {
    height: 25% !important;
  }
  .contactPage .cta_section .hover_overlay .trail-block7 span:last-child {
    height: 14%;
  }
  .contactPage .cta_section .hover_overlay .trail-block7 .contact-hover-2 {
    height: 9% !important;
  }
}
.contactPage .cta_section .hover_overlay .trail-block7 span:last-child {
  height: 22%;
}
.contactPage .cta_section .hover_overlay .trail-block7 .contact-hover-2 {
  height: 17% !important;
}
.contactPage .cta_section .hover_overlay .trail-block8 span {
  height: 0%;
}
@media screen and (max-width: 576px) {
  .contactPage .cta_section .hover_overlay .trail-block8 span:first-child {
    height: 15%;
  }
  .contactPage .cta_section .hover_overlay .trail-block8 .contact-hover-1 {
    height: 10% !important;
  }
  .contactPage .cta_section .hover_overlay .trail-block8 span:last-child {
    height: 8%;
  }
  .contactPage .cta_section .hover_overlay .trail-block8 .contact-hover-2 {
    height: 3% !important;
  }
}
.contactPage .cta_section .hover_overlay .trail-block9 span {
  height: 15%;
}
.contactPage .cta_section .hover_overlay .trail-block9 .contact-hover-1 {
  height: 10% !important;
}
.contactPage .cta_section .hover_overlay .trail-block9 .contact-hover-2 {
  height: 10% !important;
}
.contactPage .cta_section .hover_overlay .trail-block10 span:first-child {
  height: 6%;
}
.contactPage .cta_section .hover_overlay .trail-block10 .contact-hover-1 {
  height: 2% !important;
}
.contactPage .cta_section .hover_overlay .trail-block10 span:last-child {
  height: 28%;
}
.contactPage .cta_section .hover_overlay .trail-block10 .contact-hover-2 {
  height: 23% !important;
}
.contactPage .cta_section .hover_overlay .trail-block11 span {
  height: 34%;
}
.contactPage .cta_section .hover_overlay .trail-block11 .contact-hover-1 {
  height: 29% !important;
}
.contactPage .cta_section .hover_overlay .trail-block11 .contact-hover-2 {
  height: 29% !important;
}
.contactPage .cta_section .cta_content {
  position: relative;
  pointer-events: none;

  /* background-image: url(../../../public/img/ws-banner-bkp.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
  padding: 10rem 0;
}
.contactPage .cta_section .cta_content .sub_tagline {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: #fff;
}
.contactPage .cta_section .cta_content h1 {
  color: #fff;
  margin: 0;
  font-size: 5rem;
  text-align: center;
  font-family: DrukWide-Medium-Trial;
  text-transform: uppercase;
  line-height: 1.2;
  letter-spacing: 2px;
}
@media screen and (max-width: 991px) {
  .contactPage .cta_section .cta_content h1 {
    font-size: 6vw;
  }
}
.contactPage .cta_section .cta_content h1 span {
  display: block;
}
@media screen and (max-width: 991px) {
  .contactPage .cta_section .cta_content h1 span {
    display: unset;
  }
}
.contactPage .cta_section .cta_circle {
  width: 10rem;
  min-width: 10rem;
  height: 10rem;
  border-radius: 50%;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  text-decoration: none;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  transition: all 0.2s ease-in;
  
}

@media screen and (max-width: 991px) {
  .contactPage .cta_section .cta_circle {
    cursor: pointer;
    opacity: 1;
    transform: scale(1);
    position: unset;
  }
}
.contactPage .cta_section .cta_circle p {
  color: #000;
  font-size: 1.2rem;
  margin: 0;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
}
.contactPage .cta_section .cta_circle p span {
  display: block;
}
.contactPage .cta_section .col-lg-8:hover .cta_circle {
  cursor: pointer;
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
  text-decoration: none;
}
/* aryan code  start*/
@media screen and (max-width: 991px) {
  .contactPage .cta_section .col-lg-8:hover .cta_circle {
    cursor: pointer;
    opacity: 1;
    transform: translate(0%, 0%) scale(1);
    /* transform: translate(-50%, -50%) scale(1); */
  }
}
/* aryan css code end */

/* aryan css for animation */

.contact-heading {
  overflow: hidden;
}

.required-field::after {
  content: '*';
  color: red;
  margin-left: 0.2rem;
  display: inline-block;
  vertical-align: top;
  line-height: 0.9rem;
  filter: brightness(100%);
}
