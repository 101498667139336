.teamsExpand {
  padding: 2rem 0;
  padding-top: 7rem;
  position: relative;
}
.teamsExpand .backtoabout {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.teamsExpand .backtoabout p {
  text-transform: uppercase;
}
.teamsExpand .topSection {
  padding-left: 10rem;
}
@media screen and (min-width: 1500px) {
  .teamsExpand .topSection {
    padding-left: 15rem;
  }
}
@media screen and (max-width: 1200px) {
  .teamsExpand .topSection {
    padding-left: 3rem;
  }
}
.teamsExpand .topSection h1 {
  display: block;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 4rem;
  font-family: DrukWide-Medium-Trial;
  margin: 0;
  line-height: 1.2;
}
@media screen and (max-width: 1850px) {
  .teamsExpand .topSection h1 {
    font-size: 3.5rem;
  }
}
@media screen and (max-width: 1400px) {
  .teamsExpand .topSection h1 {
    font-size: 3rem;
  }
}
@media screen and (max-width: 768px) {
  .teamsExpand .topSection h1 {
    font-size: 2.2rem;
  }
}
.teamsExpand .topSection .content {
  margin-top: 3rem;
  padding-left: 5rem;
}
@media screen and (max-width: 991px) {
  .teamsExpand .topSection .content {
    padding-left: 0;
  }
}
.teamsExpand .topSection .subcontent {
  margin-top: 1rem;
  color: #9e9e9e;
  padding-left: 5rem;
}
@media screen and (max-width: 991px) {
  .teamsExpand .topSection .subcontent {
    padding-left: 0;
  }
}
.teamsExpand .topSection .servicesList {
  margin-top: 4rem;
  padding-left: 10rem;
}
@media screen and (min-width: 1500px) {
  .teamsExpand .topSection .servicesList {
    padding-left: 15rem;
  }
}
@media screen and (max-width: 991px) {
  .teamsExpand .topSection .servicesList {
    padding-left: 3rem;
  }
}
.teamsExpand .topSection .servicesList .line {
  margin-bottom: 0.3rem;
  display: block;
  width: 2.1rem;
  height: 0.1rem;
  opacity: 0.5;
  background: #ffffff;
}
.teamsExpand .topSection .servicesList h5 {
  text-transform: uppercase;
  font-size: 1.2rem;
  margin-bottom: 0.3rem;
}
.teamsExpand .topSection .servicesList p {
  color: #9e9e9e;
  line-height: 1.5;
}
.teamsExpand .topSection .servicesList .viewall {
  display: flex;
  margin-top: 4rem;
  color: var(--primary-color);
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: unset;
}
.teamsExpand .topSection .servicesList .viewall img {
  width: auto;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

.teams_slider {
  padding-left: 5rem;
}
@media screen and (max-width: 1600px) {
  .teams_slider {
    padding-left: 2rem;
  }
}
@media screen and (max-width: 991px) {
  .teams_slider {
    padding-left: 0;
    justify-content: end !important;
    display: inline-flex;
    width: 30rem;
    margin-left: auto;
    position: relative;
    padding-right: 1rem;
    margin-top: 8rem;
  }
}
@media screen and (max-width: 576px) {
  .teams_slider {
    width: 80%;
  }
}
.teams_slider .teamlist-sec-2-subsec-2 {
  width: 55%;
  height: 37rem;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.2rem 0.5rem;
  /* width: 34rem;
   height: 46.25rem; */
}
@media screen and (max-width: 1600px) {
  .teams_slider .teamlist-sec-2-subsec-2 {
    height: 30rem;
  }
}
@media screen and (max-width: 1200px) {
  .teams_slider .teamlist-sec-2-subsec-2 {
    height: 26rem;
  }
}
@media screen and (max-width: 991px) {
  .teams_slider .teamlist-sec-2-subsec-2 {
    width: 100%;
    height: 38rem;
    display: flex;
    align-items: end;
    justify-content: center;
  }
}
@media screen and (max-width: 480px) {
  .teams_slider .teamlist-sec-2-subsec-2 {
    height: 30rem;
  }
}

.teams_slider .teamlist-sec-2-subsec-2::before {
  content: '';
  display: block;
  width: 100%;
  height: 1rem;
  background-image: url(/public/img/topleftborder.svg),
    url(/public/img/toprightborder.svg);
  background-position:
    top left,
    top right;
  position: absolute;
  top: -0.5rem;
  right: 0;
  background-repeat: no-repeat;
}
.teams_slider .teamlist-sec-2-subsec-2::after {
  content: '';
  display: block;
  width: 100%;
  height: 1rem;
  background-image: url(/public/img/bottomlefttborder.svg),
    url(/public/img/bottomrightborder.svg);
  background-position:
    bottom left,
    bottom right;
  position: absolute;
  bottom: -0.5rem;
  right: 0;
  background-repeat: no-repeat;
}
.teams_slider .teamlist-sec-2-subsec-2 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0;
}
.teams_slider .teamlist-sec-2-child {
  width: 9%;
  opacity: 0.4;
  height: 28rem;
}
@media screen and (max-width: 991px) {
  .teams_slider .teamlist-sec-2-child {
    display: none;
  }
}
.teams_slider .teamlist-sec-2-child:hover {
  opacity: 0.5;
}
.teams_slider .teamlist-sec-2-child:nth-child(3) {
  height: 21rem;
}
.teams_slider .teamlist-sec-2-child:nth-child(4) {
  opacity: 0.1 !important;
}
@media screen and (max-width: 1600px) {
  .teams_slider .teamlist-sec-2-child {
    height: 23rem;
  }
  .teams_slider .teamlist-sec-2-child:nth-child(3) {
    height: 14rem;
  }
}
.teams_slider .teamlist-sec-2-child img {
  filter: grayscale(100%);
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.teams_slider .teamlist-sec-2-child:hover img {
  filter: grayscale(0);
}

.teamsExpand .topSection .side-arrow {
  position: absolute;
  right: 0;
}
.teamsExpand .topSection .side-arrow p {
  margin: 2rem 3rem 2rem 1rem;
  text-align: center;
  color: var(--primary-color);
}
@media screen and (max-width: 991px) {
  .teamsExpand .topSection .side-arrow {
    left: -2.5rem;
    right: unset;
  }
}
@media screen and (max-width: 576px) {
  .teamsExpand .topSection .side-arrow {
    left: -2.5rem;
  }
}
.teamsExpand .topSection .side-arrow .arrow-circle {
  background-color: #ffffff;
  border-radius: 50%;
  height: 6rem;
  width: 6rem;
  min-width: 6rem;
  margin: 2rem 2rem 2rem 0rem;
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  overflow: hidden;
  cursor: pointer;
}
.teamsExpand .topSection .side-arrow .arrow-circle img {
  height: 1.5rem;
  width: 1.5rem;
  position: relative;
}
@media screen and (max-width: 1600px) {
  .teamsExpand .topSection .side-arrow .arrow-circle {
    height: 4rem;
    width: 4rem;
    min-width: 4rem;
  }
  .teamsExpand .topSection .side-arrow .arrow-circle img {
    height: 1rem;
    width: 1rem;
  }
}
@media screen and (max-width: 991px) {
  .teamsExpand .topSection .side-arrow .arrow-circle {
    height: 4.5rem;
    width: 4.5rem;
    min-width: 4.5rem;
  }
}
.teamsExpand .topSection .side-arrow .arrow-circle .bg_fill {
  display: block;
  background-color: var(--primary-color);
  width: 50%;
  position: absolute;
  height: 100%;
  bottom: -10rem;
  transition: 0.2s all ease-in-out;
}
.teamsExpand .topSection .side-arrow .arrow-circle .bg_fill:first-child {
  left: 0;
}
.teamsExpand .topSection .side-arrow .arrow-circle .bg_fill:nth-child(2) {
  right: 0;
  transition-delay: 0.05s;
}
.teamsExpand .topSection .side-arrow .arrow-circle:hover .bg_fill:first-child {
  bottom: 0;
}
.teamsExpand .topSection .side-arrow .arrow-circle:hover .bg_fill:nth-child(2) {
  bottom: 0;
}
.teamsExpand .topSection .side-arrow .arrow-circle p {
  color: #558007;
}

.wholeTeamSection {
  margin-top: 7rem;
  padding: 0 2rem;
}
.wholeTeamSection .container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 2rem 1rem;
}
@media screen and (max-width: 991px) {
  .wholeTeamSection .container {
    grid-template-columns: repeat(1, 1fr);
  }
}
.wholeTeamSection .teamCard {
  position: relative;
}
@media screen and (max-width: 991px) {
  .wholeTeamSection .teamCard {
    width: 60%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 576px) {
  .wholeTeamSection .teamCard {
    width: 90%;
  }
}
.wholeTeamSection .teamCard .hoverImg {
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 991px) {
  .wholeTeamSection .teamCard .hoverImg {
    margin-bottom: 0.4rem;
  }
}
.wholeTeamSection .teamCard .backImg {
  position: absolute;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  /* transform: scale(0.5); */
}
.wholeTeamSection .teamCard .hoverImg .frontImg {
  transition: all 0.3s ease-in-out;
}
.wholeTeamSection .teamCard .hoverImg:hover .frontImg {
  opacity: 0;
  /* transform: scale(0.5); */
}
.wholeTeamSection .teamCard .hoverImg:hover .backImg {
  opacity: 1;
  transform: scale(1);
}
.wholeTeamSection .teamCard span {
  font-size: 0.875rem;
  text-transform: uppercase;
  color: var(--primary-color);
}
.wholeTeamSection .teamCard p {
  font-size: 0.875rem;
  text-transform: uppercase;
  margin-top: 0.3rem;
}
@media screen and (max-width: 991px) {
  .wholeTeamSection .teamCard p {
    margin-top: 0;
  }
}
.wholeTeamSection .teamCard h6 {
  font-size: 0.875rem;
  text-transform: uppercase;
  font-family: DrukWide-Medium-Trial;
  font-weight: 100;
  margin-top: 0.3rem;
}

.TeamNavigation {
  margin: 10rem 0;
  padding: 0 2rem;
}
.TeamNavigation h4 {
  font-family: DrukWide-Medium-Trial;
  color: #fff;
  font-weight: 100;
  text-transform: uppercase;
  font-size: 1.5rem;
}
.TeamNavigation .prev span {
  color: #fff;
  font-weight: 100;
  text-transform: uppercase;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
}
.TeamNavigation .next span {
  color: #fff;
  font-weight: 100;
  text-transform: uppercase;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 6px;
}
.TeamNavigation span .dotblink {
  display: block;
  width: 0.6rem;
  min-width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background-color: var(--primary-color);
  animation: dotblink 1s ease infinite;
}
@keyframes dotblink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.TeamNavigation .navigation {
  position: relative;
  display: inline-block;
}
.TeamNavigation .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.TeamNavigation .onlynext {
  justify-content: flex-end;
}
.TeamNavigation .navigation .navButton {
  transition: all 0.5s;
  background-color: var(--primary-color);
  border-radius: 50%;
  height: 6.5rem;
  width: 6.5rem;
  min-width: 6.5rem;
  border: unset;
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  overflow: hidden;
  cursor: pointer;
  z-index: 111;
  opacity: 1;
}
/* .TeamNavigation .navigation:hover .navButton {
  opacity: 1;
} */
.TeamNavigation .navigation .navButton img {
  height: 1rem;
}
.TeamNavigation .navigation.prev .navButton {
  position: absolute;
  right: -7rem;
  top: 0;
}
.TeamNavigation .navigation.next .navButton {
  position: absolute;
  left: -7rem;
  top: 0;
}

.teamsExpand .section8 .verticalLine.left {
  border-image: linear-gradient(0deg, #262626 50%, #0c0c0c 90%) 1;
  height: 60rem;
}
.teamsExpand .section8 .rightcontent .verticalLine.right {
  height: 52rem;
  border-image: linear-gradient(0deg, #262626 80%, #0c0c0c 100%) 1;
}

/* aryan css */
.Team-heading {
  overflow: hidden;
}

.teamsExpand .wholeTeamSection .mbl_profileslider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}
.teamsExpand .wholeTeamSection .mbl_profileslider .IndicatorsPosition {
  position: unset;
  transform: unset;
}
.teamsExpand
  .wholeTeamSection
  .mbl_profileslider
  .IndicatorsPosition
  .crousal-center-button {
  border-radius: 0.3rem;
  width: 1.4rem;
}
.teamsExpand .wholeTeamSection .swiper_arrows button {
  background-color: #fff;
}
.teamsExpand .wholeTeamSection .swiper_arrows .prevarrow {
  margin-right: 2rem;
}
.teamsExpand .wholeTeamSection .swiper_arrows .nextarrow {
  margin-left: 2rem;
}
.teamsExpand .wholeTeamSection .mbl_teamslider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4rem 0;
}
.teamsExpand .wholeTeamSection .mbl_teamslider button {
  width: 8rem;
  min-width: 8rem;
  height: 8rem;
  padding: 0.5rem;
  border: 1px solid #fff;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  font-family: IBMPlexMono-Regular;
  font-size: 0.875rem;
  text-transform: uppercase;
  color: #fff;
}
.teamsExpand .wholeTeamSection .mbl_teamslider button span {
  margin-bottom: 0.5rem;
}
.service-wrap {
  display: block !important;
  position: relative;
  overflow: hidden;
}
.tryletter {
  display: inline-block;
}
.tryletter1 {
  display: inline-block;
}

#cursor-team {
  z-index: 99;
  position: fixed;
  /* transform: translate(-50%, -50%); */
  will-change: transform;
}
.teamsExpand .leftshadow{

  display: block;
  position: absolute;
 
  width: 100%;
  top: 2%;
  height: 8%;
  background-color: #ffffff57;
  opacity: 0.25;
  right:40%;
  filter: blur(80px);
  border-radius: 50%;
  transform: rotate(355deg);
  z-index: -1;
}
.teamsExpand .verticalLine.center {
height: 100% !important;
}
