/* general */

/* general end */

/* Project Page CSS Start*/
.projects-list-page {
  position: relative;
}
.projects-list-page::before {
  /* content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-image: url(../../../public/img/projectimg/project-list-bg.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 105% 100%;
  z-index: -1; */
}
/* Project Page CSS End*/

/* projectlist-sec-1 */

.projectlist-allprojects {
  overflow: hidden !important;
  margin-bottom: 2.125rem;
}
@media screen and (max-width: 991px) {
  .projectlist-allprojects {
    padding-top: 3rem;
  }
}
.allprojects-letters {
  display: inline-block;
}
.projectlist-sec-1 {
  position: relative;
  padding: 13.125rem 0;
}
@media screen and (max-width: 1600px) {
  .projectlist-sec-1 {
    position: relative;
    padding: 4rem 0;
  }
}
.projectlist-sec-1 h1 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 6rem;
  line-height: 5.875rem;
  font-family: DrukWide-Medium-Trial;
}

.projectlist-sec-1 h1 span {
  white-space: nowrap;
}

.projectlist-sec-1 h1 span.mre-15 {
  margin-right: -6rem;
}

.projectlist-sec-1-heading {
  position: relative;
  margin-left: 9rem;
  overflow: hidden;
}
.projectlist-sec-1-heading.first {
  margin-left: 8rem;
  display: flex;
  align-items: flex-end;
}
.projectlist-sec-1-heading.last {
  margin-left: 10rem;
  display: flex;
  align-items: flex-end;
}

@media screen and (max-width: 900px) {
  .projectlist-sec-1-heading.first {
    margin-left: 0rem;
  }
  .projectlist-sec-1-heading.last {
    margin-left: 0rem;
  }
  .projectlist-sec-1-heading {
    margin-left: 0rem;
  }
}
.projectlist-sec-1-subheading .line {
  margin-bottom: 0.3rem;
  display: block;
  width: 2.1rem;
  height: 0.1rem;
  opacity: 0.5;
  background: #ffffff;
  margin-right: 0.5rem;
}
.projectlist-sec-1-subheading {
  /* position: absolute;
  width: 32%;
  font-size: 1rem;
  line-height: 1.3rem;
  color: #fff;
  font-weight: 500;
  right: -3rem;
  top: 3.125rem; */
  margin-left: 7rem;
}
.projectlist-sec-1-subheading.mbl_display {
  display: none;
}

@media screen and (max-width: 991px) {
  .projectlist-sec-1-subheading.mbl_display {
    display: block;
    margin-top: 4rem;
    margin-left: 0;
  }

  .projectlist-sec-1-subheading.desc_display {
    display: none;
  }
}
.projectlist-sec-1-subheading .projectlist-sec-1-wrap {
  width: 50%;
}

@media screen and (max-width: 991px) {
  .projectlist-sec-1-subheading .projectlist-sec-1-wrap {
    width: 100%;
  }
}

/* .projectlist-sec-1-subheading:before,
.projectlist-2-unstyled::before {
  content: "";
  position: absolute;
  top: -0.625rem;
  left: 0;
  width: 1.25rem;
  height: 0.0625rem;
  background-color: #fefbf7;
  display: block;
} */
/* projectlist-sec-1 end */

/* project list -sec-2 */

.sidelinecontent-2 {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  position: absolute;
  left: -8rem;
  transform: rotate(90deg);
  top: 50%;
}

.fr-ca .sidelinecontent-2 {
  left: -8.1rem;
}
.zh-chs .sidelinecontent-2 {
  left: -5.4rem;
}
.ja .sidelinecontent-2 {
  left: -7.7rem;
}
.zh-cht .sidelinecontent-2 {
  left: -5.4rem;
}

.ko .sidelinecontent-2 {
  left: -6.3rem;
}
.sidelinecontent-2 p {
  text-transform: uppercase;
  font-size: 1.2rem;
}

@media screen and (max-width: 991px) {
  .sidelinecontent-2 {
    top: 21%;
  }
  .feature_sideline .sidelinecontent-2 {
    display: none;
  }
}

.line-1 {
  display: block;
  width: 2.5rem;
  height: 0.1rem;
  opacity: 0.5;
  background: #a9a9a9;
  margin-right: 0.5rem;
}
.outlinedot-1 {
  border-radius: 50%;
  border: 1px solid #fff;
  height: 0.5rem;
  width: 0.5rem;
}
.filldot-1 {
  background-color: #94db16;
  width: 7px;
  min-width: 7px;
  height: 7px;
  border: 1px solid #fff;
  border-radius: 50%;
}
.projectlist-sec-2 {
  display: flex;

  margin-top: 5rem;
  justify-content: center;
  align-items: center;
  margin-top: 3%;
  margin-left: 8%;
  margin-right: 8%;
  position: relative;
}
.projectlist-sec-2 .border_lines {
  position: absolute;
  width: auto;
  background: transparent;
}
@media screen and (max-width: 991px) {
  .border_lines {
    display: none;
  }
}
.projectlist-sec-2 .banner_topborder {
  border-image: linear-gradient(90deg, #0c0c0c 0%, #262626 60%, #0c0c0c 100%) 1;
  left: 0;
  top: 2%;
  height: 1px;
  width: 75%;
  border-top: 1px solid;
}
.projectlist-sec-2 .banner_bottomborder {
  border-image: linear-gradient(90deg, #262626 20%, #0c0c0c 100%) 1;
  left: 0;
  bottom: 2%;
  height: 1px;
  width: 75%;
  border-top: 1px solid;
}
.projectlist-sec-2 .banner_right_border {
  /* right: -6%; */
  right:0%;
  top: 15%;
  height: 43rem;
  border-left: 1px solid;
  border-image: linear-gradient(
      120deg,
      #262626 10%,
      #0c0c0c 20%,
      #262626 70%,
      #0c0c0c 100%
    )
    1;
}
/* .projectlist-sec-2 .side-arrow {
  position: absolute;
  right: 0;
} */
/* aryan code */
.projectlist-sec-2 .side-arrow {
  display: grid;
  position: absolute;
  right: 13%;
  width: 0%;
  justify-items: center;
}
.projectlist-sec-2 .side-arrow p {
  /* margin: 2rem 3rem 2rem 2.2rem; */
  text-align: center;
  justify-content: center;
  justify-self: center;
  color: var(--primary-color);
}
/* .projectlist-sec-2 .side-arrow p {
  margin: 2rem 3rem 2rem 1rem;
  text-align: center;
  color: var(--primary-color);
} */
@media screen and (max-width: 991px) {
  .projectlist-sec-2 .side-arrow {
    left: -2.5rem;
    right: unset;
  }
  /* aryan code */
  .projectlist-sec-2 .side-arrow p {
    /* margin: 2rem 3rem 2rem 1.5rem; */
  }
}
@media screen and (max-width: 576px) {
  .projectlist-sec-2 .side-arrow {
    left: -1.5rem;
  }
  /* aryan code */
  .projectlist-sec-2 .side-arrow p {
    margin: 2rem 3rem 2rem 1.5rem;
  }
}
.arrow-circle {
  background-color: #ffffff;
  border-radius: 50%;
  height: 6rem;
  width: 6rem;
  min-width: 6rem;
  margin-block: 3rem;
  /* margin: 2rem 2rem 2rem 0rem; */
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  overflow: hidden;
  cursor: pointer;
  /* background-image: url(/public/temp_img/left-arrow.svg); */
  /* background-position: center;
  background-repeat: no-repeat;
  background-size: 1rem, 2rem; */
}
.arrow-circle img {
  height: 1.5rem;
  width: 1.5rem;
  position: relative;
}
@media screen and (max-width: 1600px) {
  .arrow-circle {
    height: 4rem;
    width: 4rem;
    min-width: 4rem;
  }
  .arrow-circle img {
    height: 1rem;
    width: 1rem;
  }
}
@media screen and (max-width: 991px) {
  .arrow-circle {
    height: 4.5rem;
    width: 4.5rem;
    min-width: 4.5rem;
  }
}
.arrow-circle .bg_fill {
  display: block;
  background-color: var(--primary-color);
  width: 50%;
  position: absolute;
  height: 100%;
  bottom: -10rem;
  transition: 0.2s all ease-in-out;
}
.arrow-circle .bg_fill:first-child {
  left: 0;
}
.arrow-circle .bg_fill:nth-child(2) {
  right: 0;
  transition-delay: 0.05s;
}
.arrow-circle:hover .bg_fill:first-child {
  bottom: 0;
}
.arrow-circle:hover .bg_fill:nth-child(2) {
  bottom: 0;
}
.arrow-circle p {
  color: #558007;
}
/* .side-right {
  background-color: #ffffff;
  border-radius: 50%;
  height: 6rem;
  width: 6rem;
  min-width: 6rem;
  margin: 2rem;
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  overflow: hidden;
  cursor: pointer;
}
.side-right img{
  justify-content: center;
  align-items: center;
  height: 1rem;
  width: 1rem;
  position: relative;
} */
/* 
.side-right .bg_fill{
  display: block;
  background-color: var(--primary-color);
  width: 50%;
  position:absolute;
  height: 100%;
  bottom:-10rem;
  transition: 0.2s all ease-in-out;
}
.side-right .bg_fill:first-child{
  left: 0;
}
.side-right .bg_fill:nth-child(2) {
  right: 0;
  transition-delay: 0.05s;
}

.side-right:hover  .bg_fill:first-child {
  bottom: 0;
  
}
.side-right:hover  .bg_fill:nth-child(2) {
  bottom: 0;

} */

.view-all {
  align-self: flex-end;
  margin-top: 5rem;
  margin-left: 5rem;
  color: #94db16;
  cursor: pointer;
}
.view-all span {
  display: inline-block;
  vertical-align: top;
  margin-top: 1rem;
}
.view-all img {
  height: 1rem;
}

.project_slider {
  padding-left: 13rem;
}
@media screen and (max-width: 1600px) {
  .project_slider {
    padding-left: 9rem;
  }
}
@media screen and (max-width: 991px) {
  .project_slider {
    padding-left: 0;
    justify-content: end !important;
    display: inline-flex;
    width: 30rem;
    margin-left: auto;
  }
}
@media screen and (max-width: 576px) {
  .project_slider {
    width: 80%;
  }
}
.projectlist-sec-2-subsec-2 {
  width: 55%;
  height: 37rem;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.2rem 0.5rem;
  /* width: 34rem;
 height: 46.25rem; */
}
@media screen and (max-width: 1600px) {
  .projectlist-sec-2-subsec-2 {
    height: 30rem;
  }
}
@media screen and (max-width: 1200px) {
  .projectlist-sec-2-subsec-2 {
    height: 26rem;
  }
}
@media screen and (max-width: 991px) {
  .projectlist-sec-2-subsec-2 {
    width: 100%;
    height: 38rem;
    display: flex;
    align-items: end;
    justify-content: center;
  }
}
@media screen and (max-width: 480px) {
  .projectlist-sec-2-subsec-2 {
    height: 30rem;
  }
}

.projectlist-sec-2-subsec-2::before {
  content: '';
  display: block;
  width: 100%;
  height: 1rem;
  background-image: url(/public/img/topleftborder.svg),
    url(/public/img/toprightborder.svg);
  background-position:
    top left,
    top right;
  position: absolute;
  top: -0.5rem;
  right: 0;
  background-repeat: no-repeat;
}
.projectlist-sec-2-subsec-2::after {
  content: '';
  display: block;
  width: 100%;
  height: 1rem;
  background-image: url(/public/img/bottomlefttborder.svg),
    url(/public/img/bottomrightborder.svg);
  background-position:
    bottom left,
    bottom right;
  position: absolute;
  bottom: -0.5rem;
  right: 0;
  background-repeat: no-repeat;
}
.projectlist-sec-2-subsec-2 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.projectlist-sec-2-subsec-2 .view-project {
  border-radius: 50%;
  background-color: #94db16;
  color: black;
  height: 7.18rem;
  width: 7.18rem;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
  font-weight: 600;
  font-size: 1.1rem;
}
@media screen and (max-width: 1200px) {
  .projectlist-sec-2-subsec-2 .view-project {
    height: 5.5rem;
    width: 5.5rem;
  }
  .projectlist-sec-2-subsec-2 .view-project p {
    font-size: 0.7rem;
  }
}
@media screen and (max-width: 991px) {
  .projectlist-sec-2-subsec-2 .view-project {
    border-radius: 2rem;
    color: black;
    height: auto;
    width: auto;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    text-align: center;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0rem;
    margin-bottom: 2rem;
    padding: 0.8rem 2rem;
  }
  .projectlist-sec-2-subsec-2 .view-project p br {
    display: none;
  }
}
.projectlist-sec-2-subsec-2:hover .view-project {
  opacity: 1;
  visibility: visible;
}

.projectlist-sec-2-child {
  width: 9%;
  opacity: 0.4;
  height: 28rem;
}
@media screen and (max-width: 991px) {
  .projectlist-sec-2-child {
    display: none;
  }
}
.projectlist-sec-2-child:hover {
  opacity: 0.5 !important;
}
.projectlist-sec-2-child:nth-child(3) {
  height: 21rem;
}
/* aryan commented it */
/* .projectlist-sec-2-child:nth-child(4) {
  opacity: 0.1 !important;
} */
@media screen and (max-width: 1600px) {
  .projectlist-sec-2-child {
    height: 23rem;
  }
  .projectlist-sec-2-child:nth-child(3) {
    height: 14rem;
  }
}
.projectlist-sec-2-child img {
  filter: grayscale(100%);
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.projectlist-sec-2-child:hover img {
  filter: grayscale(0);
}

/* project list sec-3 start */

.sidelinecontent-1 {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  position: absolute;
  left: -12rem;
  transform: rotate(90deg);
  top: 50%;
}
.projectslist {
  display: flex;
  text-transform: uppercase;
}
.project-list-1 {
  flex: 1;
  margin: 1rem;
  background: url(/public/temp_img/news-desc-border-4.svg) right bottom
    no-repeat;
}
.projectslist {
  position: relative;
}
.projectslist .first {
  position: absolute;
}
.view-project {
  /* border-radius: 50%;
  background-color: #94db16;
  color: black;
  height: 6rem;
  width: 6rem;
  z-index: 2;
  position: absolute;
  top: 30%;
  left: 25%;

  display: grid;
  place-items: center;
  cursor: pointer;
  text-align: center; */
}
.project-list-2 {
  flex: 1;
  margin: 1rem;
  background: url(/public/temp_img/news-desc-border-4.svg) right bottom
    no-repeat;
}
.projectlist-heading {
  display: flex;
  justify-content: space-between;
}
.projectlist-heading h4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
}
.projectlist-sec-3 {
  margin-top: 5rem;
}
.projectlist-sec-3 .sidelinecontent-2 {
  left: -4rem;
}
.projectlist-sec-3 .border_lines {
  position: absolute;
  width: auto;
  background: transparent;
}
.projectlist-sec-3 .banner_bottomleft_border {
  left: 3%;
  bottom: 82%;
  height: 60rem;
  border-left: 1px solid;
  border-image: linear-gradient(0deg, #0c0c0c 0%, #262626 60%, #0c0c0c 100%) 1;
}
.projectlist-sec-3 .banner_bottomright_border {
  right: 3%;
  bottom: 65%;
  height: 60rem;
  border-left: 1px solid;
  border-image: linear-gradient(0deg, #0c0c0c 0%, #262626 60%, #0c0c0c 100%) 1;
}
.projectlist-sec-3 .card_row {
  padding: 0 2rem;
  text-transform: uppercase;
}

.all-project {
  margin-left: 1rem;
  margin-top: 1rem;
}
.project-category {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
}
.project-category-subsec-1 {
  display: flex;
}
.project-category-subsec-2 button {
  margin-right: 2rem;
  color: #94db16;
  text-transform: uppercase;
  background-color: transparent;
  border-style: none;
  cursor: pointer;
  display: flex;
}
.project-category-subsec-2 img {
  height: 1rem;

  margin-top: 0.5rem;
}
.project-category-subsec-2 {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.project-category h4 {
  margin-right: 2rem;
}
.project-category span {
  margin-left: 0.5rem;
  color: #558007;
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 2;
  border: 1px solid rgba(255, 255, 255, 0.46);
  background: #0f0f0f;
  margin: 0.875rem 0 0;
  min-width: 13.25rem;
  left: 60%;
  transform: translateX(-50%);
  padding: 0.25rem 0;
  top: 100%;
}

@media screen and (max-width: 991px) {
  .dropdown-content {
    left: unset;
    right: 0;
    transform: translateX(0);
  }
}

.dropdown-content .drpc-link {
  position: relative;
  text-transform: uppercase;
  color: #a9a9a9;
  display: block;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  line-height: 1.375rem;
  font-weight: 500;
  padding: 0.75rem;
  /* font-family: IBMPlexMono-Medium; */
}
.dropdown-content .drpc-link:hover,
.dropdown-content .drpc-link.active {
  color: #94db16;
}
.dropdown-content .drpc-link:after {
  content: '';
  opacity: 0.4;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    #fff 23.39%,
    rgba(255, 255, 255, 0) 110.48%
  );
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0.5px;
}

.dropdown-content .drpc-link:last-child::after {
  display: none;
}
/* pagination */
/* rest code of pagination is in news page */
/*pagination css start*/
.pagination {
  margin-top: 3%;
  margin-left: inherit;
  margin-right: inherit;
}
.pagination .pagination-left-button:disabled,
.pagination .pagination-left-button.disabled,
.pagination .pagination-right-button:disabled,
.pagination .pagination-right-button:disabled {
  opacity: 0.4;
  pointer-events: none;
}
.pagination .pagination-left-button,
.pagination .pagination-right-button,
.projectlist-sec-8 .pagination-left-button,
.projectlist-sec-8 .pagination-right-button {
  padding: 0;
  width: 3.125rem;
  height: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #fff;
  background: none;
  overflow: hidden;
  transition: all 250ms;
  cursor: pointer;
}
.pagination .pagination-left-button:hover,
.pagination .pagination-right-button:hover,
.projectlist-sec-8 .pagination-left-button:hover,
.projectlist-sec-8 .pagination-right-button:hover {
  box-shadow: inset 0 -5.25em 0 0 #94db16;
}
.pagination .pages-link {
  opacity: 0.5;
  font-size: 1rem;
  font-family: IBMPlexMono-Regular;
  font-weight: 400;
  line-height: 1.2rem;
  text-decoration: none;
  color: #ffffff;
  margin: 0 0.5rem !important;
}
.pagination .pages-link.active {
  opacity: 1;
}
.pagination .pages-link:first-of-type {
  margin: 0 0.53125rem 0 2.125rem;
}
.pagination .pages-link:last-of-type {
  margin: 0 2.125rem 0 0;
}
/*pagination css end*/

.trial-1,
.trial-2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0.625rem;
}

@media screen and (max-width: 991px) {
  .trial-1,
  .trial-2 {
    display: none;
  }
}

.trial-2 {
  left: inherit;
  right: 0.625rem;
}

.side_content .side_wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.side_content .split_images {
  display: grid;
  grid-template-columns: repeat(17, 1fr);
  grid-gap: 2rem;
  align-items: center;
  padding: 5rem 0;
  height: 36rem;
  overflow: hidden;
  /* background-color: #94DB16; */
}
.side_content .split_images .img_height {
  width: 2rem;
  transition: 0.3s all ease-in-out;
  position: relative;
}

/* project list -sec-2 Start*/
.projectlist-sec-2 {
  position: relative;
  padding: 3rem 0 3.25rem;
}
.projectlist-sec-2-subsec-1 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: end;
  width: 60%;
  margin-left: auto;
  padding-right: 2rem;
  padding-top: 4rem;
}
@media screen and (max-width: 991px) {
  .projectlist-sec-2-subsec-1 {
    width: 90%;
    padding: 3rem 0 0;
    margin-left: auto;
  }
}
.projectlist-sec-2-subsec-1 p {
  font-size: 1rem;
  line-height: 1.375rem;
  color: #fefbf7;
  font-weight: 500;
  /* font-family: IBMPlexMono-Medium; */
}
@media screen and (max-width: 991px) {
  .projectlist-sec-2-subsec-1 p {
    margin-bottom: 1rem;
  }
}
.projectlist-sec-2-subsec-1 h4 {
  font-weight: 500;
  font-size: 1.8rem;
  letter-spacing: 2px;
  font-weight: 100;
  font-family: DrukWide-Medium-Trial;
  margin: 0 0 3.4375rem;
  text-transform: uppercase;
  word-break: break-word;
}
@media screen and (max-width: 480px) {
  .projectlist-sec-2-subsec-1 h4 {
    font-size: 2rem;
  }
}
.projectlist-2-unstyled {
  list-style-type: none;
  position: relative;
  padding-top: 1.25rem;
  margin: 0 0 5.5rem;
  line-height: 1.5;
}
@media screen and (max-width: 991px) {
  .projectlist-2-unstyled {
    padding-left: 3rem;
    line-height: 1.7;
  }
}
.projectlist-2-unstyled .line {
  margin-bottom: 0.3rem;
  display: block;
  width: 2.1rem;
  height: 0.1rem;
  opacity: 0.5;
  background: #ffffff;
  margin-right: 0.5rem;
}
.projectlist-2-unstyled::before {
  top: 0;
}
.projectlist-2-unstyled li {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #fefbf7;
  font-weight: 500;
  margin: 0 0 0.75rem;
  /* font-family: IBMPlexMono-Medium; */
}
.projectlist-2-unstyled li:last-child {
  margin: 0;
}

.projectlist-sec-2-subsec-1 .view-all,
.project-category-subsec-2 .view-all {
  display: inline-flex;
  align-items: end;
  white-space: nowrap;
  margin: 0;
  padding: 1rem 0;
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 500;
  color: #94db16;
  text-decoration: none;
  cursor: pointer;
  /* font-family: IBMPlexMono-Medium; */
}
@media screen and (max-width: 991px) {
  .projectlist-sec-2-subsec-1 .view-all,
  .project-category-subsec-2 .view-all {
    align-self: flex-start;
    padding-left: 3rem;
  }
}
.projectlist-sec-2-subsec-1 .view-all .icon {
  margin: 0 0 -0.5125rem 0.75rem;
  width: unset;
}
.project-category-subsec-2 .view-all .icon {
  margin: 0 0 -0.8125rem 0.5rem;
}
.project-category-subsec-2 .view-all:hover .animate-img {
  transform: translateY(7px);
  transition: all 0.5s ease;
  /* transform: translateX(0); */
}
/* project list -sec-2 End*/

/* project list -sec-3 Start*/
.projectlist-sec-3 {
  position: relative;
  padding: 10.75rem 0 0rem;
}
@media screen and (max-width: 991px) {
  .projectlist-sec-3 {
    padding: 0rem;
  }
  .projectlist-sec-3 .container {
    width: 95%;
    max-width: 95%;
    margin-right: 0;
    margin-left: auto;
  }
}
@media screen and (max-width: 576px) {
  .projectlist-sec-3 .container {
    width: 90%;
    max-width: 90%;
  }
}
.projectlist-sec-3 h6 {
  font-size: 1rem;
  line-height: 1.375rem;
  color: #ffffff;
  font-weight: 500;
}

.projectlist-3-lists-box {
  position: relative;
  display: flex;
  align-items: center;
}

.projectlist-3-lists-box .projectlist-3-link {
  font-size: 1.5rem;
  line-height: 2rem;
  text-decoration: none;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin: 0 4.375rem 0 0;
  /* font-family: IBMPlexMono-Medium; */
}
.projectlist-3-link#myreset {
  margin: 0;
  font-size: 1.1rem;
  color: #94db16;
  cursor: pointer;
}
.projectlist-3-lists-box .projectlist-3-link span {
  color: #32470b;
  font-size: 1rem;
  margin: 0 0 0 0.3125rem;
  transition: all 0.2s;
}

.projectlist-3-lists-box .projectlist-3-link.active {
  color: #94db16;
}

.projectlist-3-lists-box .projectlist-3-link.active span {
  color: #94db16;
  transform: rotate(45deg);
}

.all-projects-list-items {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.all-projects-list-items .apl-link {
  color: #929292;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  font-family: IBMPlexMono-Regular;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #929292;
  border-radius: 1.875rem;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
  cursor: pointer;
  margin: 0 1.25rem 0.875rem 0;
}
.all-projects-list-items .apl-link:hover,
.all-projects-list-items .apl-link.active {
  border-color: #94db16;
  color: #94db16;
}

.project-category-subsec-2 .view-all {
  background-color: transparent;
  height: auto;
  padding: 0;
  margin: 0;
}

.projectlist-sec-3-item {
  position: relative;
  background: url(/public/temp_img/news-desc-border-4.svg) right bottom
    no-repeat;
  margin: 0 0 2.5rem;
}

.projectlist-sec-3-item .img {
  width: 100%;
  position: relative;
  display: block;
  /* height: auto; */
  height: 24.0625rem;
}
.projectlist-sec-3-item .project-video-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1200px) {
  .projectlist-sec-3-item .img {
    height: 17rem;
  }
}
@media screen and (max-width: 991px) {
  .projectlist-sec-3-item .img {
    height: auto;
  }
}
/* @media screen and (max-width:576px) {
  .projectlist-sec-3-item .img {
    height: 21rem;
  }
}
@media screen and (max-width:480px) {
  .projectlist-sec-3-item .img {
    height: 17rem;
  }
}
@media screen and (max-width:400px) {
  .projectlist-sec-3-item .img {
    height: 12rem;
  }
} */

.projectlist-sec-3-item img {
  width: 100%;
  height: 100%;
  display: block;
  /* object-fit: cover; */ /*Divakara changed this */
  object-fit: contain;
  position: relative;
  transition: all 0.3s;
}
.projectlist-sec-3-item .img .view-project {
  background-color: #94db16;
  border-radius: 100%;
  width: 7.1875rem;
  height: 7.1875rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
  text-align: center;
  color: #000;
  cursor: pointer;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 1.1rem;
}
.projectlist-sec-3-item .img .view-project {
  text-transform: capitalize;
}
@media screen and (max-width: 768px) {
  .projectlist-sec-3-item .img .view-project {
    width: 6.1875rem;
    height: 6.1875rem;
    font-size: 0.9rem;
  }
}

.projectlist-sec-3-item:hover .img .view-project {
  opacity: 1;
}

.projectlist-sec-3-item .projectlist-s3-dels {
  margin: 1.25rem 0 0.9375rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.projectlist-sec-3-item .projectlist-s3-dels h4 {
  font-family: DrukWide-Medium-Trial;
  font-weight: 500;
  font-size: 1.625rem;
  line-height: 1.875rem;
  margin: 0;
  text-transform: uppercase;
  color: #fff;
}
.projectlist-sec-3-item .projectlist-s3-dels h5,
.projectlist-sec-3-item p {
  color: #aeaeae;
  font-size: 1rem;
  line-height: 1.25rem;
  text-transform: uppercase;
  margin: 0;
  font-weight: 500;
  /* font-family: IBMPlexMono-Medium; */
}
/* project list -sec-3 End*/

/* project list -sec-8 Start*/
.projectlist-sec-8 {
  position: relative;
  padding: 14.3125rem 0 7.8125rem 0;
}
.projectlist-s8-left-dels {
  padding: 0 5.3125rem 0 3.75rem;
}
.projectlist-s8-left-dels h5 {
  color: #fefbf7;
  font-size: 1.25rem;
  line-height: 1.625rem;
  text-transform: uppercase;
  margin: 0;
  font-weight: 500;
  letter-spacing: 0.025em;
  /* font-family: IBMPlexMono-Medium; */
}

.projectlist-s8-left-dels h4 {
  color: #fefbf7;
  font-size: 2.625rem;
  line-height: 3.125rem;
  text-transform: uppercase;
  margin: 0 0 4.0625rem;
  font-weight: 500;
  font-family: DrukWide-Medium-Trial;
}

.projectlist-s8-left-dels .ps-ld-desc {
  position: relative;
  margin: 0 0 1.875rem;
}

.projectlist-s8-left-dels .ps-ld-desc::before {
  content: url(/public/img/quote.svg);
  position: absolute;
  left: -1.875rem;
  z-index: 0;
  top: -1.75rem;
}

.projectlist-s8-left-dels .ps-ld-desc p {
  color: #fefbf7;
  font-size: 1rem;
  line-height: 1.375rem;
  margin: 0 0 3.75rem;
  font-style: italic;
  font-weight: 500;
  z-index: 1;
  position: relative;
  /* font-family: IBMPlexMono-Medium; */
}

.projectlist-s8-left-dels .ps-ld-desc h6::before {
  content: '';
  position: absolute;
  width: 2.1875rem;
  height: 0.0625rem;
  background-color: #94db16;
  display: block;
  top: 0.4375rem;
  left: 0%;
}
.projectlist-s8-left-dels .ps-ld-desc h6 {
  color: #fefbf7;
  font-size: 0.75rem;
  padding-left: 3rem;
  line-height: 0.9375rem;
  margin: 0;
  font-weight: 500;
  text-transform: uppercase;
  z-index: 1;
  position: relative;
  letter-spacing: 0.025em;
  /* font-family: IBMPlexMono-Medium; */
}

.projectlist-s8-right-dels {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 3.75rem;
}

.projectlist-s8-right-dels h3 {
  position: relative;
  color: #fefbf7;
  text-align: center;
  font-size: 5rem;
  line-height: 6.375rem;
  text-transform: uppercase;
  margin: 0 0;
  font-weight: 500;
  font-family: DrukWide-Medium-Trial;
}

.projectlist-s8-right-dels .view-project {
  text-transform: uppercase;
  color: #0c0c0c;
  font-size: 1.25rem;
  line-height: 1.5rem;
  width: 8.75rem;
  height: 8.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 44%;
  left: 30%;
  font-family: IBMPlexMono-SemiBold;
  /* font-family: IBMPlexMono-Medium; */
  font-weight: 500;
}
/* project list -sec-8 End*/

/* Responsive */
@media screen and (max-width: 1600px) {
  .projects-list-page::before {
    background-size: 131% 100%;
  }

  .projectlist-sec-1 h1 {
    font-size: 5rem;
    line-height: 4.875rem;
    margin-left: -3rem;
  }
  .projectlist-sec-1 h1 span.mre-15 {
    margin-right: -5rem;
  }
  /* projectlist-sec-1 end*/

  .projectlist-3-lists-box .projectlist-3-link {
    font-size: 1.3rem;
    line-height: 1.5rem;
    margin: 0 3.375rem 0 0;
  }
  .projectlist-sec-3-item .projectlist-s3-dels h4 {
    font-size: 1.225rem;
    line-height: 1.475rem;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .projects-list-page .container {
    max-width: 880px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .projects-list-page .container {
    max-width: 1080px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1440px) {
  .projects-list-page .container {
    max-width: 1220px;
  }
}

@media screen and (max-width: 1440px) {
  .projectlist-s8-left-dels h5 {
    font-size: 1rem;
    line-height: 1.2rem;
  }
  .projectlist-s8-left-dels h4 {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .projectlist-s8-left-dels .ps-ld-desc p {
    font-size: 0.875rem;
    margin: 0 0 3rem;
  }
  .projectlist-s8-right-dels h3 {
    font-size: 4.5rem;
    line-height: 5rem;
  }
  .projectlist-s8-right-dels .view-project {
    font-size: 1rem;
    line-height: 1.3rem;
    width: 6.5rem;
    height: 6.5rem;
  }
}

@media screen and (max-width: 1199px) {
  .projectlist-sec-1 h1 {
    font-size: 4rem;
  }

  .projectlist-s8-left-dels h5 {
    font-size: 0.875rem;
    line-height: 1rem;
  }
  .projectlist-s8-left-dels h4 {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0 0 3.0625rem;
  }
  .projectlist-s8-right-dels h3 {
    font-size: 3rem;
    line-height: 3.8rem;
  }
  .projectlist-s8-right-dels .view-project {
    font-size: 0.875rem;
    line-height: 1rem;
    width: 5rem;
    height: 5rem;
    top: 44%;
    left: 34%;
  }
}

@media screen and (max-width: 991px) {
  .projectlist-sec-1-heading h1 {
    font-size: 1.875rem;
    line-height: 2.1875rem;
  }
  .projectlist-sec-1 h1 span.mre-15 {
    margin-right: auto;
  }
  .projectlist-s8-left-dels {
    padding: 0 5.3125rem;
  }
  .projectlist-s8-right-dels {
    padding: 5.9375rem 5.3125rem 0;
    margin-top: 3.75rem;
  }
  .projectlist-s8-right-dels .view-project {
    font-size: 1.125rem;
    line-height: 1.5rem;
    width: 7.8125rem;
    height: 7.8125rem;
    top: inherit;
    left: inherit;
    position: inherit;
    margin: 1.125rem 0 0;
  }
}

@media screen and (max-width: 575px) {
  .project-category-subsec-2 .dropdown-content {
    left: inherit;
    transform: inherit;
    right: 0;
  }

  .projects-list-page::before,
  .trial-1,
  .trial-2,
  .projectlist-s8-left-dels h5 {
    display: none;
  }

  .projectlist-sec-1:before {
    content: '';
    position: absolute;
    border-radius: 762.215px;
    background: rgba(162, 193, 196, 0.3);
    filter: blur(75px);
    width: 762.215px;
    height: 117px;
    transform: rotate(22.59deg);
    flex-shrink: 0;
    right: 1.25rem;
    top: 0;
  }
  section.projectlist-sec-3:before {
    content: '';
    opacity: 0.2;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) -2.11%,
      #fff 38.7%,
      rgba(255, 255, 255, 0) 100%
    );
    /* width: 100%; */
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .projectlist-3-lists-box .projectlist-3-link {
    font-size: 0.75rem;
    line-height: 1rem;
    margin: 0 1.875rem 0 0;
  }
  .pagination {
    margin-top: 0;
    margin-bottom: 3.125rem;
  }
  @media screen and (max-width: 576px) {
    .pagination .row {
      margin: 0;
    }
    .pagination .container {
      padding: 0;
    }
  }
  .projectlist-sec-8 {
    padding: 4.375rem 0;
    overflow: hidden;
  }
  section.projectlist-sec-8:before {
    content: '';
    position: absolute;
    opacity: 0.2;
    background: radial-gradient(
      366291159.96% 97.73% at 97.73% 0.46%,
      #fff 0%,
      rgba(255, 255, 255, 0.45) 83.48%,
      #fff 100%
    );
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
  }
  section.projectlist-sec-8:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -130px;
    transform: translateX(-50%);
    border-radius: 272px;
    background: rgba(199, 221, 219, 0.14);
    filter: blur(50px);
    width: 198.901px;
    height: 272px;
    flex-shrink: 0;
  }
  .projectlist-s8-left-dels {
    padding: 0px 1rem;
  }
  .projectlist-s8-left-dels h4 {
    font-size: 1.75rem;
    line-height: 2.125rem;
    margin: 0 0 2rem;
  }
  .projectlist-s8-left-dels .ps-ld-desc p {
    margin: 0 0 2rem;
  }
  .projectlist-s8-left-dels .ps-ld-desc::before {
    left: inherit;
    top: -1.75rem;
    transform: scale(0.7);
    right: 0;
  }
  .projectlist-s8-right-dels {
    padding: 5.9375rem 1rem 0;
    margin-top: 3.125rem;
  }
  .projectlist-s8-right-dels:before {
    content: '';
    position: absolute;
    top: 0;
    left: -15px;
    right: -15px;
    opacity: 0.2;
    background: radial-gradient(
      366291159.96% 97.73% at 97.73% 0.46%,
      #fff 0%,
      rgba(255, 255, 255, 0.45) 83.48%,
      #fff 100%
    );
    height: 1px;
  }
  .projectlist-s8-right-dels h3 {
    font-size: 3.125rem;
    line-height: 3.625rem;
  }
}

/* aryan code */

.pagination a {
  cursor: pointer;
}
.pagination-left-button {
  margin-right: 2rem;
}
.pagination-right-button {
  margin-left: 2rem;
}

#filter1 {
  display: none;
}
#filter1 a {
  cursor: pointer;
}

#filter1 {
  display: none;
}

#filter2 {
  display: none;
}
#filter2 a {
  cursor: pointer;
}
#filter3 {
  display: none;
}

.projectlist-3-lists-box a {
  cursor: pointer;
}
#myDropdown a {
  cursor: pointer;
}

/* projectlist-sec-1 */
.projectlist-sec-1-heading span {
  display: block;
  text-transform: uppercase;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 5rem;
  line-height: 7.12rem;
  font-family: DrukWide-Medium-Trial;
}

@media screen and (max-width: 991px) {
  .projectlist-sec-1-heading span {
    font-size: 7vw;
  }
  .project-heading-container {
    width: 85%;
  }
}

.project-heading-container {
  position: relative;
}
@media screen and (min-width: 1600px) {
  .projectlist-sec-1  .banner_leftborder {
    left: 10% !important;
  }
  }
.projectlist-sec-1  .border_lines {
  position: absolute;
  width: auto;
  background: transparent;
}
.projectlist-sec-1  .banner_leftborder {
  left: 2%;
  top: 2%;
  height: 60rem;
  border-left: 1px solid;
  border-image: linear-gradient(0deg, #0c0c0c 0%, #262626 60%, #0c0c0c 100%) 1;

}
/* @media screen and (min-width: 1600px) {
  .project-heading-container {
    padding-left: 15rem;
  }
}
.project-heading-container .border_lines {
  position: absolute;
  width: auto;
  background: transparent;
}
.project-heading-container .banner_leftborder {
  left: 2%;
  top: 2%;
  height: 60rem;
  border-left: 1px solid;
  border-image: linear-gradient(0deg, #0c0c0c 0%, #262626 60%, #0c0c0c 100%) 1;
} */
@media screen and (min-width: 1600px) {
  .project-heading-container .banner_leftborder {
    left: 10%;
  }
}
.projects-list-page .section8 .verticalLine.left{
  left:10% !important;
  height: 219rem !important;
}
.projects-list-page .section8 .rightcontent .verticalLine.right{
  left:90% !important;
  height: 219rem !important;
}
/* .projectlist-sec-1 .banner_leftborder{
  position: absolute;
  left:10%;
} */
@media screen and (min-width: 991px) {
  .projects-list-page .section8 .verticalLine.left{
    left:7% !important;

  }
  .projects-list-page .section8 .rightcontent .verticalLine.right{
    left:93% !important
  }
}
.projectlist-sec-1 h1 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 5rem;
  line-height: 7.12rem;
  font-family: DrukWide-Medium-Trial;
}
/* projectlist-sec-1 end */

/* project list -sec-2 */
.firstline {
  padding-left: 7rem;
}
.lastline {
  padding-left: 16rem;
}

@media screen and (max-width: 991px) {
  .projectlist-sec-1-heading span {
    font-size: 7vw;
    /* width: 100%; */
    line-height: 1.3;
  }
  .firstline {
    padding-left: 0rem;
  }
  .secondline {
    padding-left: 3rem;
  }
  .lastline {
    padding-left: 1.5rem;
  }
  .row_reverse_lg {
    flex-direction: column-reverse;
  }
  /* .firstline:after {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    visibility: visible;
    content: "Work that";
  }
  .secondline {
    padding-left: 0rem;
    visibility: hidden;
    position: relative;
  }
  .secondline:after {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    visibility: visible;
    content: "speak for";
    padding-left: 3rem;
  }
  .lastline {
    padding-left: 0rem;
    visibility: hidden;
    position: relative;
  }
  .lastline:after {
    width: 100%;
    position: absolute;
    padding-left: 2rem;
    visibility: visible;
    top: 0;
    left: 0;
    content: "itself";
  } */
}
.project-animation-wrap {
  overflow: hidden;
}

.char {
  white-space: nowrap;
}

.mob_display {
  display: none !important;
}
@media screen and (max-width: 991px) {
  .desk_display {
    display: none !important;
  }
}
@media screen and (max-width: 991px) {
  .mob_display {
    display: block !important;
  }
}

.projects-list-page .leftshadow {
  display: block;
  position: absolute;

  width: 100%;
  top: 2%;
  height: 8%;
  background-color: #ffffff57;
  opacity: 0.25;
  right: 40%;
  filter: blur(80px);
  border-radius: 50%;
  transform: rotate(355deg);
  z-index: -1;
}
@media screen and (max-width: 768px) {
  .projects-list-page .leftshadow {
    top: 0%;
    opacity: 0.15;
    width: 25%;
    height: 2%;
  }
}