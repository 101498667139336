.services_page .Headsection {
  padding-top: 10rem;
  justify-content: center;
}
@media screen and (max-width: 991px) {
  .services_page .Headsection {
    flex-wrap: wrap;
    padding: 0;
    padding-top: 6rem;
  }
}
@media screen and (max-width: 991px) and (orientation: portrait) {
  .services_page .head_title {
    width: 100%;
  }
}
.services_page .head_title h1 {
  text-transform: uppercase;
  /* text-align: center; */
  font-weight: 500;
  font-size: 5rem;
  line-height: 1.2;
  font-family: DrukWide-Medium-Trial;
}
@media screen and (max-width: 1300px) {
  .services_page .head_title h1 {
    font-size: 5.5vw;
  }
}
@media screen and (min-width: 992px) {
  .services_page .head_title .service-heading:first-child h1 {
    padding-left: 5rem;
  }
}
@media screen and (max-width: 991px) {
  .services_page .head_title h1 {
    text-align: left;
  }
  .services_page .head_title h1.headTag2 {
    padding-left: 5rem;
  }
}
@media screen and (max-width: 768px) {
  .services_page .head_title h1 {
    font-size: 8vw;
    padding: 0 1rem;
  }
}
.services_page .Headsection .line_before {
  margin-bottom: 0.3rem;
  display: block;
  width: 2.1rem;
  height: 0.1rem;
  opacity: 0.5;
  background: #ffffff;
  margin-right: 0.5rem;
}
@media screen and (max-width: 991px) {
  .services_page .Headsection .line_before {
    display: flex;
    align-self: flex-start;
  }
}
.services_page .Headsection .sub_title {
  padding-left: 9rem;
}
@media screen and (min-width: 1500px) {
  .services_page .Headsection .sub_title {
    padding-left: 15rem;
  }
}
@media screen and (max-width: 991px) {
  .services_page .Headsection .sub_title {
    width: auto;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }
}
.servies_block {
  margin-top: 6rem;
}
@media screen and (max-width: 768px) {
  .servies_block {
    margin-top: 0rem;
  }
}
.servies_block .services_slider.right {
  width: 85%;
  margin-right: 10px;
  margin-left: auto;
}
.servies_block .services_slider.left {
  width: 85%;
  margin-left: 10px;
  margin-right: auto;
}
@media screen and (min-width: 1300px) {
  .servies_block .services_slider {
    width: 80%;
  }
}
@media screen and (max-width: 1200px) {
  .servies_block .services_slider.right {
    width: 95%;
    margin-left: auto;
  }
  .servies_block .services_slider.left {
    width: 95%;
    margin-right: auto;
  }
}
@media screen and (max-width: 991px) {
  .servies_block .services_slider {
    width: auto;
  }
  .servies_block .services_slider.left {
    width: 95%;
    margin-right: unset;
    margin-left: auto;
  }
}
.servies_block .services_bg {
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  padding: 8rem 0 0rem;
}
@media screen and (max-width: 991px) {
  .servies_block .services_bg {
    padding: 5rem 0 5rem;
  }
}
@media screen and (max-width: 768px) {
  .servies_block .services_bg {
    padding: 2rem 0 2rem;
  }
}
.servies_block.rightService .services_bg .servicebg_img {
  position: absolute;
  z-index: -1;
  top: 3%;
  left: 2%;
}
.servies_block.rightService .services_bg .servicebg_img img {
  width: 80%;
}
.servies_block.leftService .services_bg .servicebg_img {
  position: absolute;
  z-index: -1;
  top: -1%;
  right: -2%;
}
.servies_block.leftService .services_bg .servicebg_img img {
  width: 80%;
}

.servies_block.rightService {
  background-image: url(../../../public/img/services/bg-shadow.png);
  background-size: 100% 100%;
  background-position: top;
  /* padding: 0rem 0 5rem; */
  background-repeat: no-repeat;
}
.servies_block.service1 .services_bg {
  /* background-image: url(../../../public/img/services/service1_bg.png);
    background-size: 22%;
    background-position: 6% 10%;
    padding: 11rem 0 5rem; */
}
.servies_block.service2 {
  margin-top: 1rem;
  position: relative;
}
.servies_block.leftService::before {
  /* content: ''; */
  background-image: url(../../../public/img/services/bg-shadow.png);
  background-size: 100% 100%;
  background-position: top;
  padding: 0rem 0 5rem;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(-180deg);
}
.servies_block.service2 .services_bg {
  /* background-image: url(../../../public/img/services/service2_bg.png);
    background-size: 22%;
    background-position: 90% 10%;
    padding: 11rem 0 5rem; */
}
.servies_block.service3 .services_bg {
  /* background-image: url(../../../public/img/services/service3_bg.png);
    background-size: 30rem;
    background-position: 6% -41%;
    padding: 11rem 0 5rem; */
}
/* @media screen and (max-width:991px) {
    .servies_block.service3 .services_bg{
        padding: 5rem 0 5rem;
    }
} */
.servies_block.rightService::before {
  /* content: ''; */
  background-image: url(../../../public/img/services/bg-shadow.png);
  background-size: 100% 100%;
  background-position: top;
  padding: 0rem 0 5rem;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(-180deg);
}
.servies_block.service4 .services_bg {
  /* background-image: url(../../../public/img/services/service4_bg.png);
    background-size: 33rem;
    background-position: 90% -30%;
    padding: 11rem 0 5rem; */
}
/* @media screen and (max-width:991px) {
    .servies_block.service4 .services_bg{
        padding: 5rem 0 5rem;
    }
} */
@media screen and (max-width: 991px) {
  /* .servies_block.service1 .services_bg{
        background-size: 20rem;
        background-position: 35% 15%;
    }
    .servies_block.service2 .services_bg{
        background-size: 30rem;
        background-position: 25% 30%;
        padding-bottom: 0;
    }
    .servies_block.service3 .services_bg{
        background-size: 30rem;
        background-position: 15% -25%;
    }
    .servies_block.service4 .services_bg{
        background-size: 28rem;
        background-position: 35% 5%;
    } */
}
.servies_block .ourteam_swiper {
  position: relative;
}
.servies_block .ourteam_swiper .swiper {
  padding: 10px 10px 10px 0;
}
.servies_block .swiper-slide {
  height: unset;
}

@media screen and (min-width: 1280px) {
  .servies_block .swiper-slide {
    height: unset;
    /* width: 366px !important; */
  }
}
@media screen and (max-width: 991px) {
  .servies_block .swiper-slide {
    border: none !important;
    background-color: transparent !important;
  }
  .servies_block .swiper-slide .swipercontent {
    border: 1px solid #fff !important;
    background-color: #0d0d0d !important;
  }
}
.servies_block .services_bg .services_line1 {
  position: absolute;
  left: -14rem;
  top: 60%;
  z-index: 11;
  width: 30rem;
}
.servies_block.leftService .services_bg .services_line1 {
  left: unset;
  right: -13rem;
  top: 60%;
}
.service-block .swiper-slide-active,
.service-block .swiper-slide-next {
  margin-right: 22px !important ;
}
@media screen and (max-width: 991px) {
  .servies_block.leftService .services_bg .services_line1 {
    left: -14rem;
    right: unset;
    top: 60%;
  }
}
.servies_block .services_slider.left h1 {
  text-align: left;
}
.servies_block .services_slider h1 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 5rem;
  line-height: 1.2;
  font-family: DrukWide-Medium-Trial;
  color: #c7dddb26;
  text-align: end;
  padding-right: 2rem;
  padding-left: 2rem;
  margin-bottom: -1rem;
}
@media screen and (max-width: 991px) {
  .servies_block .services_slider h1 {
    width: 100%;
    /* margin-left: auto; */
    margin-left: -2rem;
    text-align: right !important;
    line-height: 1;
    padding-right: 0rem;
    padding-left: 0rem;
    word-wrap: break-word;
  }
}
@media screen and (max-width: 768px) {
  .servies_block .services_slider h1 {
    width: 100%;
    font-size: 4rem;
    margin-bottom: 1.5rem;
    color: #c7dddb38;
  }
}
.services_line1{
  opacity: 0;
}

@media screen and (max-width: 576px) {
  .servies_block .services_slider.left {
    width: 98%;
    margin-right: unset;
    margin-left: auto;
  }
  .servies_block .services_slider h1 {
    width: 100%;
    font-size: 2.4rem;
    margin-bottom: 1.5rem;
    color: #c7dddb38;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 333px) {
  .servies_block .services_slider h1 {
    width: 100%;
    font-size: 1.5rem !important;
    margin-left: auto;
    margin-right: auto;
  }
  .servies_block .services_slider h5 {
    width: 100%;
    font-size: 1.1rem !important;
  }
  .servies_block .services_slider p {
    width: 100%;
    font-size: 0.8rem !important;
  }
}

.servies_block .services_slider .swipercontent {
  padding: 3rem 3rem 2rem;
  border: none;
  min-height: 24rem;
}
@media screen and (max-width: 991px) {
  .servies_block .services_slider .swipercontent {
    width: 40%;
    margin-left: auto;
  }
}
@media screen and (max-width: 768px) {
  .servies_block .services_slider .swipercontent {
    width: 50%;
  }
}
@media screen and (max-width: 576px) {
  .servies_block .services_slider .swipercontent {
    width: 60%;
  }
}
@media screen and (max-width: 480px) {
  .servies_block .services_slider .swipercontent {
    width: 70%;
  }
}
.servies_block .services_slider .servicesblock_img {
  height: 100%;
  padding-left: 1rem;
  position: relative;
}
@media screen and (max-width: 991px) {
  .servies_block .services_slider .servicesblock_img {
    width: 40%;
    margin-left: auto;
  }
}
@media screen and (max-width: 768px) {
  .servies_block .services_slider .servicesblock_img {
    width: 65%;
  }
}
@media screen and (max-width: 576px) {
  .servies_block .services_slider .servicesblock_img {
    width: 60%;
  }
}
@media screen and (max-width: 480px) {
  .servies_block .services_slider .servicesblock_img {
    width: 90%;
  }
}
.servies_block .services_slider .servicesblock_img img {
  height: 100%;
}
.servies_block .services_slider .swipercontent h5 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.2;
  font-family: DrukWide-Medium-Trial;
  margin-bottom: 2rem;
}
.servies_block .services_slider .swipercontent .subcontent {
  padding-left: 3rem;
  margin-top: 2rem;
  opacity: 0.7;
}

.servies_block .services_slider .swiper_arrows {
  position: absolute;
  z-index: 111;
  top: -2.5rem;
  left: 2rem;
  /* display: none !important; */
}
.servies_block .services_slider.left .swiper_arrows {
  right: 2rem;
  left: unset;
}
@media screen and (max-width: 991px) {
  .servies_block .services_slider .swiper_arrows {
    left: unset;
    right: 2rem;
  }
}
.servies_block .services_slider .swiper_arrows button {
  background-color: var(--primary-color);
  border: unset;
  width: 3rem;
  height: 3rem;
}
.servies_block .services_slider .swiper_arrows .prevarrow {
  margin-right: 1rem;
}
.servies_block .services_slider .borderStatus {
  margin-top: 3rem;
  width: 55%;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 991px) {
  .servies_block .services_slider .borderStatus {
    width: 40%;
    margin-left: auto;
  }
}
@media screen and (max-width: 768px) {
  .servies_block .services_slider .borderStatus {
    width: 50%;
  }
}
@media screen and (max-width: 576px) {
  .servies_block .services_slider .borderStatus {
    width: 60%;
  }
}
@media screen and (max-width: 480px) {
  .servies_block .services_slider .borderStatus {
    width: 70%;
  }
}
.servies_block .services_slider.left .borderStatus {
  margin-left: auto;
  transform: rotate(180deg);
}
@media screen and (max-width: 991px) {
  .servies_block .services_slider.left .borderStatus {
    transform: unset;
  }
}
.servies_block .services_slider .borderStatus .borderline {
  width: 100%;
  border: 1px solid #fff;
}
@media screen and (max-width: 991px) {
  /* .servies_block .services_slider .borderStatus .borderline:first-child{
        display: none;
    } */
}
.servies_block .services_slider .borderStatus .borderline.gradient {
  border-image: linear-gradient(12deg, #ffffff 10%, #0c0c0c 90%) 1;
}
.servies_block .services_slider .borderStatus .borderline.active {
  border: 1px solid var(--primary-color);
  box-shadow: 0 0px 3px var(--primary-color);
}

.servicesblock_img .overlay {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 1.5s ease-in-out;
}

.servicesblock_img .overlay .block {
  border-right: 1.6rem solid #0c0c0c;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 1.5s ease-in-out;
}
.servicesblock_img .overlay .block:last-child {
  /* border-right: unset; */
}

.servicesblock_img .overlay .block span {
  display: block;
  width: 100%;
  background-color: #0c0c0c;
  transition: all 1.5s ease-in-out;
}
.servicesblock_img .trail-block1 span {
  height: 0%;
  animation: 2s;
  transition: all 1.5s ease-in-out;
}
.servicesblock_img .trail-block2 span:first-child {
  height: 20%;
  animation: 2s;
  transition: all 1.5s ease-in-out;
}

.servicesblock_img .trail-block2 span:last-child {
  height: 10%;
  animation: 2s;
  transition: all 1.5s ease-in-out;
}
.servicesblock_img .trail-block3 span:first-child {
  height: 12%;
  animation: 2s;
  transition: all 1.5s ease-in-out;
}
.servicesblock_img .trail-block3 span:last-child {
  height: 20%;
  animation: 2s;
  transition: all 1.5s ease-in-out;
}
.servicesblock_img .trail-block4 span:first-child {
  height: 5%;
  animation: 2s;
  transition: all 1.5s ease-in-out;
}
.servicesblock_img .trail-block4 span:last-child {
  height: 9%;
  animation: 2s;
  transition: all 1.5s ease-in-out;
}
.servicesblock_img .trail-block5 span:first-child {
  height: 0%;
  animation: 2s;
  transition: all 1.5s ease-in-out;
}
.servicesblock_img .trail-block5 span:last-child {
  height: 0%;
  animation: 2s;
  transition: all 1.5s ease-in-out;
}

.services_page .section8 {
  margin-top: 10rem;
}
.services_page .section8 .verticalLine.left {
  border-image: linear-gradient(0deg, #262626 30%, #0c0c0c 50%) 1;
  height: 110rem;
}
.services_page .section8 .leftcontent .verticalLine.center {
  height: 54rem;
}
.services_page .section8 .rightcontent .verticalLine.right {
  height: 50rem;
}
.service-heading {
  overflow: hidden;
}
/* .swiper-slide {
  cursor: pointer;
} */
.servies_block .swiper_arrows button.disabled {
  opacity: 0.5;
  cursor: default;
}

.filldot1 {
  width: 7px;
  min-width: 7px;
  height: 7px;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  border-radius: 50%;
}
.services_page .leftshadow{
    display: block;
    position: absolute;
   
    width: 100%;
    top: 2%;
    height: 8%;
    background-color: #ffffff57;
    opacity: 0.25;
    right:40%;
    filter: blur(80px);
    border-radius: 50%;
    transform: rotate(355deg);
    z-index: -1;
  }
  @media screen and (max-width: 768px) {
    .services_page .leftshadow {
      top:15%;
      opacity: 0.55;
    width:100%;
    height: 20%;
    }
  }