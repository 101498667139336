.sidev2-location-secheader {
  background-image: url(/public/img/Location_BG_1.svg) !important;
  color: transparent !important;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: space-between;
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: white;
  padding: 2rem 13%;
}

.sidev2-location-secheader .content h1 {
  font-size: 5.8rem;
  font-weight: 200;
  text-wrap: nowrap;
}

.sidev2-location-secheader .content .Backimg {
  font-family: DrukWide-Bold-Trial;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-top: 12%;
}

.sidev2-location-secheader .content .Backimg img {
  margin-bottom: -5px;
  width: 1.5rem;
}

.sidev2-location-secheader .content .backtext {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.sidev2-location-secheader .content .backto {
  color: #fff;
  font-family: IBMPlexMono-Regular;
  font-size: 1rem;
  text-transform: uppercase;
}

.sidev2-location-secheader .content .Backimg h1 {
  color: #fff;
  text-shadow: 0px 0px 3px;
  letter-spacing: 0.25rem;
}

.sidev2-location-secheader .content .Backimg h2 {
  color: #fff;
  font-family: DrukWide-Bold-Trial;
  font-size: 5.8rem;
  text-shadow: 0px 0px 3px;
  letter-spacing: 0.25rem;
  margin-top: -5%;
}

/*background: url(../Images/BGWallpaper-1.png) no-repeat;*/
.sidev2-location-sec {
  background-image: url(/public/img/Location_BG_1.svg) !important;
  color: transparent !important;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: space-between;
  padding-top: 0rem;
  display: flex;
  color: white;
  margin-top: -4%;
  padding: 2rem 13%;
}

.sidev2-location-sec .vertical {
  height: 100%;
  float: left;
  position: absolute;
  left: 25px;
  color: #fff;
  font-family: IBMPlexMono-Regular;
  font-size: 12px;
  text-transform: uppercase;
  writing-mode: vertical-lr;
  text-orientation: sideways;
  display: flex;
  flex-direction: column;
  flex-flow: column wrap;
  align-items: center;
  margin-bottom: 55%;
}

.sidev2-location-sec .vertical img {
  margin-left: 2%;
  width: 100%;
}

.sidev2-location-sec .vertical h3 {
  display: flex;
  gap: 1rem;
}

.sidev2-location-sec .vertical .line40 {
  margin-left: 0.5rem;
}

.secver {
  position: absolute;
  margin-top: 5%;
}

.sidev2-location-london {
  display: none;
}

.sidev2-location-sec .maindiv {
  display: flex;
  flex-direction: row;
}

.sidev2-location-sec .secdiv {
  display: flex;
  flex-direction: column;
  position: relative;
}

.sidev2-location-sec .secsubdiv {
  padding-top: 5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  position: relative;
  margin-left: 6%;
}

.sidev2-location-sec .maindiv .secdiv .secsubdiv .content {
  width: 80%;
}

.sidev2-location-sec .maindiv .secdiv .secsubdiv .content p {
  font-size: 1rem;
  margin-top: 1rem;
  margin-left: 4%;
  font-family: IBMPlexMono-Regular;
  color: #fff;
  line-height: 1.5rem;
}

.sidev2-location-sec .service {
  display: block;
  margin-left: 13%;
  margin-top: 3rem;
  font-family: IBMPlexMono-Regular;
  color: #fff;
}

.sidev2-location-sec .service img {
  width: 2rem;
}

.sidev2-location-sec .service h2 {
  display: block;
  margin-left: 16%;
  font-family: IBMPlexMono-Regular;
  line-height: 2;
  font-weight: normal;
  font-size: 1.2rem;
  letter-spacing: 1px;
}

.sidev2-location-sec .addr {
  display: block;
  font-family: IBMPlexMono-Regular;
  line-height: 1.5;
  font-size: medium;
  margin-left: 16%;
  color: #fff;
  font-weight: normal;
}
.sidev2-location-sec .view {
  color: #94db16;
  font-family: IBMPlexMono-Regular;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  margin-top: 2rem;
  margin-left: 13%;
}

.sidev2-location-sec .down {
  height: 20px;
  width: 10px;
  margin-top: 2.5rem;
}

/* background: linear-gradient(silver var(--size), transparent 0 calc(100% - var(--size)), silver 0) 0 0 / 1px 100%, linear-gradient(silver var(--size), transparent 0 calc(100% - var(--size)), silver 0) 100% 0 / 1px 100%, linear-gradient(to right, silver var(--size), transparent 0 calc(100% - var(--size)), silver 0) 0 0 / 100% 1px, linear-gradient(to right, silver var(--size), transparent 0 calc(100% - var(--size)), silver 0) 0 100% / 100% 1px;
   */

.sidev2-location-sliding-active {
  --size: 15px;
  padding: 10px;
  background-repeat: no-repeat;
  display: inline;
  margin-top: -8rem;
  position: relative;
  width: 80%;
}

.sidev2-location-sliding-active::before {
  content: '';
  display: block;
  width: 100%;
  height: 1rem;
  background-image: url(/public/img/topleftborder.svg),
    url(/public/img/toprightborder.svg);
  background-position:
    top left,
    top right;
  position: absolute;
  top: 0rem;
  right: 0;
  background-repeat: no-repeat;
}

.sidev2-location-sliding-active::after {
  content: '';
  display: block;
  width: 100%;
  height: 1rem;
  background-image: url(/public/img/bottomlefttborder.svg),
    url(/public/img/bottomrightborder.svg);
  background-position:
    bottom left,
    bottom right;
  position: absolute;
  bottom: 0rem;
  right: 0;
  background-repeat: no-repeat;
}

.sidev2-location-shadow-images {
  height: 1%;
  margin-left: -3.5%;
}

.sidev2-location-shadowsub-images {
  display: flex;
  flex-direction: row;
  gap: 3.4rem;
  position: relative;
}

.sidev2-location-shadowsub-images .shadowimg1 {
  position: relative;
  height: 35rem;
  margin-top: 45%;
}

.sidev2-location-shadowsub-images .shadowimg2 {
  position: relative;
  height: 28rem;
  margin-top: 85%;
}

.sidev2-location-shadowsub-images .shadowimg3 {
  position: relative;
  height: 39rem;
  margin-top: 25%;
}

.button-s {
  border: none;
  color: black;
  font-size: 1.2rem;
  letter-spacing: 1px;
  outline: none;
  cursor: pointer;
}

.rounded-button-shadow {
  width: 6rem;
  height: 6rem;
  border-radius: 100%;
  background-color: #fff;
}

.shadowbuttons {
  position: absolute;
  margin-top: -25%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  float: right;
  right: 0;
  margin-right: 5%;
  align-items: center;
}

.shadowbuttons button img {
  width: 1.4rem;
  height: 1.4rem;
}

.shadowbuttons span {
  color: #94db16;
  font-family: IBMPlexMono-Regular;
  font-size: 1.2rem;
}

.sidev2-location-sec .allimage {
  display: flex;
  flex-direction: row;
  gap: 0.95rem;
  position: relative;
  margin-top: -10rem;
}

.sidev2-location-sec .allimage .secondimg {
  position: relative;
  top: 80px;
}

.sidev2-location-sec .allimage .thirdimg {
  position: relative;
  top: -80px;
}

.sidev2-location-sec .allimage .fourtimg {
  position: relative;
  top: 40px;
}

.sidev2-location-sec .allimage .fifthimg {
  position: relative;
  top: -15px;
}

.sidev2-location-sec .horizline {
  position: relative;
  margin-left: -10%;
  margin-top: 6rem;
}

.sidev2-location-sec .horizline1 {
  position: absolute;
  float: left;
  margin-left: 70%;
  margin-top: -30%;
}

.sidev2-location-sliding-active-mobile {
  display: none !important;
}

/*
   @media screen and (max-width:991px) {
    section {
      padding: 2% ;
      
    }

    .secver{
      display: none;
    }

    .horizline1{
      display: none;
    }

    .vertical{
      top: 50% !important;
      font-size: 0.6rem;
      left: 1rem;
    }
 
    .allimage img{  
      width: 4rem !important;
      
    }  
    
    .horizline{
      margin-top: 2rem;
    }


    .card-row1{      
      margin-left: 13% !important;
      align-items: center !important;
      
    }

    .clientversub1{
      display: none !important;
    }
 
   

  }
*/

@media (-webkit-min-device-pixel-ratio: 1.1) {
  .sidev2-location-sec .vertical {
    margin-bottom: 65%;
  }

  .sidev2-location-shadowsub-images .shadowimg1 {
    position: relative;
    height: 30rem;
    margin-top: 55%;
  }

  .sidev2-location-shadowsub-images .shadowimg2 {
    position: relative;
    height: 25rem;
    margin-top: 85%;
  }

  .sidev2-location-shadowsub-images .shadowimg3 {
    position: relative;
    height: 35rem;
    margin-top: 35%;
  }

  .shadowbuttons {
    margin-right: 4% !important;
    margin-top: -26% !important;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.25) {
  .sidev2-location-sec .allimage img {
    width: 5rem;
  }

  .sidev2-location-sec .allimage .thirdimg {
    position: relative;
    top: -60px;
  }

  .sidev2-location-sec .allimage .fifthimg {
    position: relative;
    top: -45px;
  }

  .sidev2-location-sec .allimage .fifthimg {
    position: relative;
    top: -10px;
  }

  .sidev2-location-sec .vertical {
    margin-top: -40%;
  }

  .sidev2-location-shadow-images {
    margin-left: 2%;
  }

  .sidev2-location-shadowsub-images {
    margin-left: -35% !important;
  }

  .sidev2-location-shadowsub-images .shadowimg1 {
    position: relative;
    height: 28rem;
    margin-top: 55%;
  }

  .sidev2-location-shadowsub-images .shadowimg2 {
    position: relative;
    height: 22rem;
    margin-top: 85%;
  }

  .sidev2-location-shadowsub-images .shadowimg3 {
    position: relative;
    height: 33rem;
    margin-top: 35%;
  }

  .shadowbuttons {
    margin-top: -28% !important;
    margin-right: 6% !important;
  }

  .sidev2-location-sec .maindiv .secdiv .secsubdiv .content p {
    font-size: 0.85rem;
  }

  .sidev2-location-aboutdownsec
    .aboutdown
    .clientver
    .abuotsection8
    .leftcontent
    h5 {
    font-size: 1.9rem !important;
  }

  .sidev2-location-aboutdownsec .aboutdown .clientver .abuotsection8 p {
    font-size: 0.95rem !important;
    line-height: 1.5rem !important;
  }

  .sidev2-location-aboutdownsec
    .aboutdown
    .clientver
    .abuotsection8
    .rightcontent
    .circular {
    right: 14rem !important;
  }

  .clientpara {
    font-size: 1rem !important;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.5) {
  .sidev2-location-sec .allimage img {
    width: 4.58rem !important;
  }

  .sidev2-location-sec .maindiv .secdiv .secsubdiv .content p {
    font-weight: normal !important;
    font-size: 0.8rem;
  }

  .sidev2-location-sec .allimage .thirdimg {
    position: relative !important;
    top: -30px !important;
  }

  .sidev2-location-sec .allimage .fifthimg {
    position: relative !important;
    top: 1px !important;
  }

  .sidev2-location-sec .vertical {
    margin-top: -85%;
  }

  .sidev2-location-shadowsub-images {
    gap: 2.5rem !important;
    margin-left: -40% !important;
  }

  .sidev2-location-shadowsub-images .shadowimg1 {
    margin-top: 80%;
  }

  .sidev2-location-shadowsub-images .shadowimg2 {
    margin-top: 110%;
  }

  .sidev2-location-shadowsub-images .shadowimg3 {
    position: relative;
    height: 32rem;
    margin-top: 55%;
  }

  .shadowbuttons {
    margin-top: -30% !important;
    margin-right: 6% !important;
  }
}

@media (min-width: 60px) and (max-width: 280px) {
  .sidev2-location-secheader {
    padding: 2%;
    margin-top: 6%;
  }

  .sidev2-location-sec {
    padding: 2%;
  }

  .sidev2-location-secheader .content .Backimg {
    gap: 2rem !important;
  }

  .sidev2-location-secheader .content .backtext {
    margin-left: 5% !important;
  }

  .sidev2-location-about-mobile {
    background-image: url(/public/img/Location_BG_1.svg) !important;
    color: transparent !important;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2%;
  }

  .sidev2-location-aboutdownsec-mobile {
    background-image: url(/public/img/Location_BG_1.svg) !important;
    color: transparent !important;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2%;
  }

  .sidev2-location-sec .allimage {
    gap: 0.6rem !important;
  }

  .sidev2-location-sec .allimage img {
    width: 3rem !important;
  }

  .sidev2-location-sec .allimage .secondimg {
    position: relative !important;
    top: 50px !important;
  }

  .sidev2-location-sec .allimage .thirdimg {
    position: relative !important;
    top: -20px !important;
  }

  .sidev2-location-sec .allimage .fifthimg {
    position: relative !important;
    top: 15px !important;
  }

  .sidev2-location-secheader .content h1 {
    font-size: 1.8rem;
    text-shadow: 0px 0px 1px !important;
    line-height: 25px !important;
  }
  .sidev2-location-secheader .content .Backimg h2 {
    font-size: 1.8rem;
  }

  .sidev2-location-sec .secver {
    display: none;
  }

  .sidev2-location-sec .secdiv {
    margin-top: 30%;
  }

  .sidev2-location-sec .secsubdiv {
    display: flex;
    flex-direction: column-reverse;
    gap: 4rem;
  }

  .sidev2-location-sec .secsubdiv .content {
    margin-left: 2% !important;
    width: 90% !important;
  }

  .sidev2-location-sec .secsubdiv .content p {
    font-size: 1rem !important;
    margin-left: -1rem !important;
  }

  .sidev2-location-shadow-images {
    display: none !important;
  }
  .sidev2-location-london {
    display: flex;
    flex-direction: column;
  }

  .sidev2-location-la {
    display: none !important;
  }

  .sidev2-location-london {
    display: flex;
    flex-direction: column;
    margin-left: 12% !important;
  }

  .sidev2-location-london .london {
    color: #fff;
    font-family: DrukWide-Bold-Trial;
    font-size: 1.8rem;
    text-shadow: 0px 0px 1px;
    letter-spacing: 0.25rem;
    margin-left: -7% !important;
  }

  .sidev2-location-sliding-active {
    display: none !important;
  }
  .sidev2-location-sliding-active-mobile {
    display: flex !important;
    flex-direction: row !important;
  }

  .shadowbuttons-mobile {
    float: left;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-left: 8%;
    margin-top: -20%;
    align-items: center;
  }

  .button-s-m {
    border: none;
    color: black;
    font-size: 1.2rem;
    letter-spacing: 1px;
    outline: none;
    cursor: pointer;
  }

  .rounded-button-shadow-mobile {
    width: 4rem;
    height: 4rem;
    border-radius: 100%;
    background-color: #fff;
  }

  .shadowbuttons-mobile button img {
    width: 1.2rem;
    height: 1.2rem;
  }

  .shadowbuttons-mobile span {
    color: #94db16;
    font-family: IBMPlexMono-Regular;
    font-size: 1rem;
    margin-right: -20%;
  }

  .sidev2-location-Sliding-Images-active {
    width: 70% !important;
    align-items: center;
    float: right;
    right: 0;
    margin-top: -40%;
    margin-right: 8%;
  }

  .shadowbuttons-mobile .button-s {
    width: 3.5rem;
    height: 3.5rem;
  }

  .sidev2-location-sec .addr {
    font-size: 1rem;
  }

  .sidev2-location-sec .view {
    font-size: 0.8rem;
  }

  .sidev2-location-sec .view h2 {
    font-size: 1.2rem;
  }

  .sidev2-location-sec .secsubdiv .content {
    margin-left: -10%;
  }

  .sidev2-location-sec .vertical {
    display: none;
  }

  .sidev2-location-sec .horizline {
    display: none;
  }

  .sidev2-location-sec .service {
    margin-left: -4%;
  }

  .view .down {
    margin-top: 15% !important;
  }

  .sidev2-location-sec .maindiv .secdiv .card-row .card-row1 {
    display: none !important;
  }

  .sidev2-location-sec .maindiv .secdiv .card-row .card-row1-mobile {
    display: flex !important ;
    margin-top: -15% !important;
  }

  .sidev2-location-sec .maindiv .secdiv .card-row .card-row1-mobile .card img {
    width: 30rem;
  }

  .sidev2-location-sec .maindiv .secdiv .card-row .card-row1-mobile .card h4 {
    color: #fff;
    font-family: IBMPlexMono-Regular;
    font-weight: normal;
    font-size: 0.75rem;
    letter-spacing: 0.1rem;
  }

  .sidev2-location-sec .maindiv .secdiv .card-row .card-row1-mobile .card span {
    float: right;
    color: #94db16;
    font-family: IBMPlexMono-Regular;
  }

  .sidev2-location-sec .maindiv .secdiv .card-row .card-row1-mobile .card p {
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    color: white;
    line-height: 0.2rem;
    padding-top: 1.5rem;
    font-family: DrukWide-Bold-Trial;
  }
  .horizline1 {
    display: none !important;
  }

  .sidev2-location-about {
    display: none !important;
  }

  .clienthar1 {
    display: none !important;
  }

  .sidev2-location-about-mobile {
    display: flex !important;
    background-image: url(/public/img/Location_BG_1.svg) !important;
    color: transparent !important;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
  }

  .sidev2-location-about-mobile .about-mobile-sec {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    align-items: center;
    margin-top: 2.5rem;
  }

  .sidev2-location-about-mobile .desc {
    align-items: center;
    align-content: center;
  }

  .sidev2-location-about-mobile .profilescroll {
    color: #fff;
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .button-m img {
    width: 1.25rem;
  }

  .button-t img {
    width: 2rem;
  }

  .button-m {
    border: none;
    color: black;
    font-size: 1.2rem;
    letter-spacing: 1px;
    outline: none;
    cursor: pointer;
  }

  .rounded-button-mobile {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    background-color: #fff;
  }

  .sidev2-location-about-mobile
    .about-mobile-sec
    .desc
    .profilescroll
    .numberdiv
    .num {
    font-family: IBMPlexMono-Regular;
    margin-top: 6%;
  }

  .sidev2-location-about-mobile
    .about-mobile-sec
    .desc
    .profilescroll
    .numberdiv {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  .sidev2-location-about-mobile .about-mobile-sec .roundteams {
    align-items: center;
  }

  .sidev2-location-about-mobile .about-mobile-sec .roundteams .team {
    display: flex;
    flex-direction: row;
    gap: 3rem;
  }

  .button-t {
    border: none;
    color: #fff;
    font-size: 0.7rem;
    font-family: IBMPlexMono-Regular;
    letter-spacing: 1px;
    outline: none;
    cursor: pointer;
    text-align: center;
  }

  .rounded-button-team {
    width: 110px;
    height: 110px;
    border: 1px solid;
    border-color: #fff;
    border-radius: 100%;
    background-color: transparent;
  }

  .sidev2-location-aboutdownsec {
    display: none !important;
  }

  .sidev2-location-aboutdownsec-mobile {
    display: flex !important;
    background-image: url(/public/img/Location_BG_1.svg) !important;
    color: transparent !important;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent {
    margin: 0 2%;
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    h4 {
    color: #fff;
    font-size: 1.5rem;
    font-family: IBMPlexMono-Regular;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    h5 {
    font-family: DrukWide-Bold-Trial;
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 3px;
    line-height: 1.3;
    position: relative;
    display: inline-block;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent {
    margin: 0 2%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    .tagline {
    font-size: 0.875rem;
    margin: 0;
    text-transform: uppercase;
    color: #fff;
    margin-top: 2rem;
    display: flex;
    align-items: center;
  }
  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    .tagline
    span {
    display: block;
    width: 2.5rem;
    height: 0.1rem;
    opacity: 0.5;
    background: #94db16;
    margin-right: 0.5rem;
  }
  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    button {
    background-color: transparent;
    border: unset;
    cursor: pointer;
  }
  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    .arrows {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-top: 0rem;
    margin-left: 40%;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .content
    .greenimg {
    float: right;
    right: 0;
    width: 20%;
    margin-right: 5%;
    align-items: center;
    margin-top: -3.5% !important;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .content
    .clientpara {
    color: #fff;
    font-size: 1rem;
  }

  /* start contact after change */

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    h1 {
    font-family: DrukWide-Bold-Trial;
    font-size: 2.4rem;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 4px;
    line-height: 3rem;
    position: relative;
    display: inline-block;
    text-align: center;
    text-shadow: 0px 0px 3px;
    margin-left: 0.5rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .circular {
    width: 8rem;
    height: 8rem;
    border: unset;
    font-weight: 600;
    margin-left: 5rem;
    margin-bottom: 4rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .circular
    .rounded-button-circular {
    width: 9rem !important;
    height: 9rem !important;
  }

  /* End contact after change */

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    h5 {
    font-family: DrukWide-Bold-Trial;
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 3px;
    line-height: 1.3;
    position: relative;
    display: inline-block;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    h4 {
    color: #fffdfd;
    font-size: 1.5rem;
    font-family: IBMPlexMono-Regular;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .contact
    h2 {
    font-size: 1rem;
    color: #fff;
    margin-left: 0.5rem;
    font-family: IBMPlexMono-Regular;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .contact
    .contactsub {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .contact
    .contactsub
    .exam {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background: none;
    outline: none;
    resize: none;
    border: 0;
    font-family: IBMPlexMono-Regular;
    transition: all 0.3s;
    border-bottom: 2px solid #90d510;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .contact
    .contactsub
    .emailmsg {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background: none;
    outline: none;
    resize: none;
    border: 0;
    font-family: IBMPlexMono-Regular;
    transition: all 0.3s;
    border-bottom: 2px solid #fefbf7;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .submit {
    margin: 1.5rem;
    float: right;
    right: 0;
    margin-left: 5rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .submit
    .btn {
    font-size: 1.1em;
    padding: 15px 80px 15px 40px;
    background-color: transparent;
    font-family: Helvetica-Neue-Regular;
    color: #fff;
    border-radius: 50px;
    margin: auto;
    border: 2px solid #fff;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .submit
    .btn
    .ico {
    transition: all 0.5s;
    background-color: transparent;
    width: 50px;
    height: 50px;
    padding: 5px;
    border-radius: 20px;
    position: absolute;
    margin-left: 26px;
    margin-top: -21px;
    font-size: 1.5em;
  }
}

@media (min-width: 281px) and (max-width: 480px) {
  .sidev2-location-secheader {
    padding: 2%;
    margin-top: 6%;
  }

  .sidev2-location-sec {
    padding: 2%;
  }

  .sidev2-location-secheader .content .Backimg {
    gap: 2rem !important;
  }

  .sidev2-location-secheader .content .backtext {
    margin-left: 5% !important;
  }

  .sidev2-location-about-mobile {
    background-image: url(/public/img/Location_BG_1.svg) !important;
    color: transparent !important;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2%;
  }

  .sidev2-location-aboutdownsec-mobile {
    background-image: url(/public/img/Location_BG_1.svg) !important;
    color: transparent !important;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2%;
  }

  .sidev2-location-sec .allimage img {
    width: 4rem !important;
  }

  .sidev2-location-sec .allimage .thirdimg {
    position: relative !important;
    top: -15px !important;
  }

  .sidev2-location-sec .allimage .fifthimg {
    position: relative !important;
    top: 5px !important;
  }

  .sidev2-location-shadow-images {
    display: none !important;
  }

  .sidev2-location-la {
    display: none !important;
  }

  .sidev2-location-london {
    display: flex;
    flex-direction: column;
    margin-left: 12% !important;
  }

  .sidev2-location-london .london {
    color: #fff;
    font-family: DrukWide-Bold-Trial;
    font-size: 1.8rem;
    text-shadow: 0px 0px 1px;
    letter-spacing: 0.25rem;
    margin-left: -7% !important;
  }

  .sidev2-location-sliding-active {
    display: none !important;
  }
  .sidev2-location-sliding-active-mobile {
    display: flex !important;
    flex-direction: row !important;
    object-fit: cover;
  }

  .sidev2-location-Sliding-Images-active {
    width: 70% !important;
    align-items: center;
    float: right;
    right: 0;
    margin-top: -40%;
    margin-right: 8%;
  }

  .shadowbuttons-mobile {
    float: left;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    margin-left: 12%;
    margin-top: -20%;
    align-items: center;
  }

  .button-s-m {
    border: none;
    color: black;
    font-size: 1.2rem;
    letter-spacing: 1px;
    outline: none;
    cursor: pointer;
  }

  .rounded-button-shadow-mobile {
    width: 4rem;
    height: 4rem;
    border-radius: 100%;
    background-color: #fff;
  }

  .shadowbuttons-mobile button img {
    width: 1.2rem;
    height: 1.2rem;
  }

  .shadowbuttons-mobile span {
    color: #94db16;
    font-family: IBMPlexMono-Regular;
    font-size: 1rem;
    margin-right: -20%;
  }

  .sidev2-location-secheader .content h1 {
    font-size: 1.8rem;
    text-shadow: 0px 0px 1px !important;
    line-height: 25px !important;
  }
  .sidev2-location-secheader .content .Backimg h2 {
    font-size: 1.8rem;
  }

  .secver {
    display: none;
  }

  .sidev2-location-sec .secdiv {
    margin-top: 35%;
  }

  .sidev2-location-sec .secsubdiv {
    display: flex;
    flex-direction: column-reverse;
    margin-left: -5%;
    gap: 4rem;
  }

  .sidev2-location-sec .secsubdiv .content {
    margin-left: 5%;
    width: 95% !important;
  }

  .sidev2-location-sec .secsubdiv .content p {
    font-size: 1rem !important;
    margin-left: 5% !important;
    font-family: IBMPlexMono-Regular !important;
    line-height: 1.2rem !important;
  }

  .sidev2-location-sec .vertical {
    display: none;
  }

  .sidev2-location-sec .horizline {
    display: none;
  }

  .sidev2-location-sec .service {
    margin-left: 5%;
  }

  .sidev2-location-sec .service .view {
    font-size: 1.1rem !important;
  }

  .view .down {
    margin-top: 12% !important;
  }

  .sidev2-location-sec .maindiv .secdiv .card-row .card-row1 {
    display: none !important;
  }

  .sidev2-location-sec .maindiv .secdiv .card-row .card-row1-mobile {
    display: flex !important ;
    margin-top: -10% !important;
  }

  .sidev2-location-sec .maindiv .secdiv .card-row .card-row1-mobile .card img {
    width: 30rem;
  }

  .sidev2-location-sec .maindiv .secdiv .card-row .card-row1-mobile .card h4 {
    color: #fff;
    font-family: IBMPlexMono-Regular;
    font-size: 0.85rem;
    letter-spacing: 0.1rem;
    font-weight: normal;
  }

  .sidev2-location-sec .maindiv .secdiv .card-row .card-row1-mobile .card span {
    float: right;
    color: #94db16;
    font-family: IBMPlexMono-Regular;
    font-size: 0.85rem;
    letter-spacing: 0.1rem;
  }

  .sidev2-location-sec .maindiv .secdiv .card-row .card-row1-mobile .card p {
    font-size: 1.4rem;
    color: white;
    line-height: 0.5rem;
    padding-top: 1.5rem;
    letter-spacing: 0.2rem;
    font-family: DrukWide-Bold-Trial;
  }

  .horizline1 {
    display: none !important;
  }

  .sidev2-location-about {
    display: none !important;
  }

  .clienthar1 {
    display: none !important;
  }

  .sidev2-location-about-mobile {
    display: flex !important;
    background-image: url(/public/img/Location_BG_1.svg) !important;
    color: transparent !important;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center;
    margin-right: 10%;
  }

  .sidev2-location-about-mobile .about-mobile-sec {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    align-items: center;
    margin-top: 2rem;
  }

  .sidev2-location-about-mobile .desc {
    align-items: center;
    align-content: center;
    margin-left: 12%;
  }

  .sidev2-location-about-mobile .profilescroll {
    color: #fff;
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }

  .button-m img {
    width: 1.2rem;
  }

  .button-t img {
    width: 2rem;
  }

  .button-m {
    border: none;
    color: black;
    font-size: 1.2rem;
    letter-spacing: 1px;
    outline: none;
    cursor: pointer;
  }

  .rounded-button-mobile {
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    background-color: #fff;
  }

  .sidev2-location-about-mobile
    .about-mobile-sec
    .desc
    .profilescroll
    .numberdiv
    .num {
    font-family: IBMPlexMono-Regular;
    margin-top: 8%;
  }

  .sidev2-location-about-mobile
    .about-mobile-sec
    .desc
    .profilescroll
    .numberdiv {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  .sidev2-location-about-mobile .about-mobile-sec .roundteams {
    align-items: center;
    margin-left: 12%;
    margin-bottom: 8%;
  }

  .sidev2-location-about-mobile .about-mobile-sec .roundteams .team {
    display: flex;
    flex-direction: row;
    gap: 4rem;
  }

  .button-t {
    border: none;
    color: #fff;
    font-size: 0.9rem;
    font-family: IBMPlexMono-Regular;
    letter-spacing: 1px;
    outline: none;
    cursor: pointer;
  }

  .rounded-button-team {
    width: 130px;
    height: 130px;
    border: 1px solid;
    border-color: #fff;
    border-radius: 100%;
    background-color: transparent;
  }

  .sidev2-location-aboutdownsec {
    display: none !important;
  }

  .sidev2-location-aboutdownsec-mobile {
    display: flex !important;
    background-image: url(/public/img/Location_BG_1.svg) !important;
    color: transparent !important;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent {
    margin: 0 2%;
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    h4 {
    color: #fff;
    font-size: 2rem;
    font-family: IBMPlexMono-Regular;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    h5 {
    font-family: DrukWide-Bold-Trial;
    font-size: 2rem;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 3px;
    line-height: 1.3;
    position: relative;
    display: inline-block;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent {
    margin: 0 2%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    .tagline {
    font-size: 0.875rem;
    margin: 0;
    text-transform: uppercase;
    color: #fff;
    margin-top: 2rem;
    display: flex;
    align-items: center;
  }
  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    .tagline
    span {
    display: block;
    width: 2.5rem;
    height: 0.1rem;
    opacity: 0.5;
    background: #94db16;
    margin-right: 0.5rem;
  }
  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    button {
    background-color: transparent;
    border: unset;
    cursor: pointer;
  }
  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    .arrows {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 2rem;
    margin-top: 0rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .content
    .greenimg {
    float: right;
    right: 0;
    width: 20%;
    margin-right: 5%;
    margin-top: -5% !important;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .content
    .clientpara {
    color: #fff;
    font-size: 1.1rem !important;
  }

  .mobilehorline {
    position: relative;
    width: 100%;
    margin-top: 4% !important;
  }

  /* start contact after change */

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    h1 {
    font-family: DrukWide-Bold-Trial;
    font-size: 3.3rem;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 4px;
    line-height: 4rem;
    position: relative;
    display: inline-block;
    text-align: center;
    text-shadow: 0px 0px 3px;
    margin-left: 1rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .circular {
    width: 8rem;
    height: 8rem;
    border: unset;
    font-weight: 600;
    margin-left: 8.5rem;
    margin-bottom: 4rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .circular
    .rounded-button-circular {
    width: 9rem !important;
    height: 9rem !important;
  }

  /* End contact after change */

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    h5 {
    font-family: DrukWide-Bold-Trial;
    font-size: 2rem;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 3px;
    line-height: 1.3;
    position: relative;
    display: inline-block;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    h4 {
    color: #fff;
    font-size: 2rem;
    font-family: IBMPlexMono-Regular;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .contact
    h2 {
    font-size: 1rem;
    font-family: IBMPlexMono-Regular;
    color: #fff;
    margin-left: 0.5rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .contact
    .contactsub {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .contact
    .contactsub
    .exam {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background: none;
    outline: none;
    resize: none;
    border: 0;
    font-family: IBMPlexMono-Regular;
    transition: all 0.3s;
    border-bottom: 2px solid #90d510;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .contact
    .contactsub
    .emailmsg {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background: none;
    outline: none;
    resize: none;
    border: 0;
    font-family: IBMPlexMono-Regular;
    transition: all 0.3s;
    border-bottom: 2px solid #fefbf7;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .submit {
    margin: 1.5rem;
    float: right;
    right: 0;
    margin-left: 11rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .submit
    .btn {
    font-size: 1.1em;
    padding: 15px 80px 15px 40px;
    background-color: transparent;
    font-family: Helvetica-Neue-Regular;
    color: #fff;
    border-radius: 50px;
    margin: auto;
    border: 2px solid #fff;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .submit
    .btn
    .ico {
    transition: all 0.5s;
    background-color: transparent;
    color: #e94751;
    width: 50px;
    height: 50px;
    padding: 5px;
    border-radius: 20px;
    position: absolute;
    margin-left: 26px;
    margin-top: -21px;
    font-size: 1.5em;
  }
}

@media (min-width: 481px) and (max-width: 759px) {
  .sidev2-location-secheader {
    padding: 2rem 5%;
  }

  .sidev2-location-sec {
    padding: 2rem 5%;
  }

  .sidev2-location-secheader .content .Backimg {
    gap: 2rem !important;
  }

  .sidev2-location-about-mobile {
    background-image: url(/public/img/Location_BG_1.svg) !important;
    color: transparent !important;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2rem 5%;
  }

  .sidev2-location-aboutdownsec-mobile {
    background-image: url(/public/img/Location_BG_1.svg) !important;
    color: transparent !important;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2rem 5%;
  }

  .sidev2-location-secheader .content span {
    font-size: 1.2rem;
  }

  .sidev2-location-secheader .content h1 {
    font-size: 5rem;
  }
  .sidev2-location-secheader .content .Backimg h2 {
    font-size: 5rem;
  }

  .secver {
    display: none;
  }

  .sidev2-location-sec .secdiv {
    margin-top: 30%;
  }

  .sidev2-location-sec .secsubdiv {
    display: flex;
    flex-direction: column-reverse;
    margin-left: -0.2%;
    gap: 4rem;
  }

  .sidev2-location-sec .secsubdiv .content {
    margin-left: 1%;
    width: 90% !important;
  }

  .sidev2-location-sec .secsubdiv .content p {
    font-size: 1.2rem !important;
    margin-left: -4% !important;
  }

  .sidev2-location-sec .vertical {
    display: none;
  }

  .sidev2-location-sec .allimage img {
    width: 6rem;
  }

  .sidev2-location-shadow-images {
    display: none !important;
  }
  .sidev2-location-london {
    display: flex;
  }

  .sidev2-location-la {
    display: none !important;
  }

  .sidev2-location-london {
    display: flex;
    flex-direction: column;
    margin-left: 12% !important;
  }

  .sidev2-location-london .london {
    color: #fff;
    font-family: DrukWide-Bold-Trial;
    font-size: 5rem !important;
    text-shadow: 0px 0px 3px;
    letter-spacing: 0.25rem;
    margin-left: -10% !important;
  }

  .sidev2-location-sliding-active {
    display: none !important;
  }
  .sidev2-location-sliding-active-mobile {
    display: flex !important;
    flex-direction: row !important;
  }

  .sidev2-location-Sliding-Images-active {
    width: 70% !important;
    align-items: center;
    float: right;
    right: 0;
    margin-top: -40%;
    margin-right: -4%;
  }

  /* .sidev2-location-sliding-active-mobile::before {
      content: '';
      display: block;
      width: 100%;
      height: 1rem;
      background-image: url(/public/img/topleftborder.svg), url(/public/img/toprightborder.svg); 
      background-position: top left, top right;
      position: absolute;
      top: 0rem;
      right: 0;
      background-repeat: no-repeat;
    }
    
    .sidev2-location-sliding-active-mobile::after {
      content: '';
      display: block;
      width: 100%;
      height: 1rem;
      background-image: url(/public/img/bottomlefttborder.svg), url(/public/img/bottomrightborder.svg);
      background-position: bottom left, bottom right;
      position: absolute;
      bottom: 0rem;
      right: 0;
      background-repeat: no-repeat;
    } */

  .shadowbuttons-mobile {
    float: left;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    margin-left: 21%;
    margin-top: -15%;
    align-items: center;
  }

  .button-s-m {
    border: none;
    color: black;
    font-size: 1.2rem;
    letter-spacing: 1px;
    outline: none;
    cursor: pointer;
  }

  .rounded-button-shadow-mobile {
    width: 4rem;
    height: 4rem;
    border-radius: 100%;
    background-color: #fff;
  }

  .shadowbuttons-mobile button img {
    width: 1.2rem;
    height: 1.2rem;
  }

  .shadowbuttons-mobile span {
    color: #94db16;
    font-family: IBMPlexMono-Regular;
    font-size: 1rem;
    margin-right: -20%;
  }

  .sidev2-location-sec .horizline {
    display: none;
  }

  .sidev2-location-sec .service {
    margin-left: -2% !important;
  }

  .sidev2-location-sec .service h2 {
    font-size: 1.5rem !important;
  }

  .sidev2-location-sec .service span {
    font-size: 1.3rem !important;
  }

  .sidev2-location-sec .service .view {
    font-size: 1.2rem !important;
  }

  .view .down {
    margin-top: 10% !important;
  }

  .sidev2-location-sec .maindiv .secdiv .card-row .card-row1 {
    display: none !important;
  }

  .sidev2-location-sec .maindiv .secdiv .card-row .card-row1-mobile {
    display: flex !important ;
  }

  .sidev2-location-sec .maindiv .secdiv .card-row .card-row1-mobile .card img {
    width: 45rem;
  }

  .sidev2-location-sec .maindiv .secdiv .card-row .card-row1-mobile .card h4 {
    font-size: 1rem;
    color: #fff;
    font-weight: normal;
    letter-spacing: 1px;
  }

  .sidev2-location-sec .maindiv .secdiv .card-row .card-row1-mobile .card span {
    float: right;
    color: #94db16;
    font-family: IBMPlexMono-Regular;
    font-size: 1rem;
    font-weight: normal;
    letter-spacing: 1px;
  }

  .sidev2-location-sec .maindiv .secdiv .card-row .card-row1-mobile .card p {
    font-size: 1.8rem;
    color: white;
    line-height: 0.2rem;
    padding-top: 1.5rem;
    font-family: DrukWide-Bold-Trial;
    letter-spacing: 1px;
  }

  .horizline1 {
    display: none !important;
  }

  .sidev2-location-about {
    display: none !important;
  }

  .clienthar1 {
    display: none !important;
  }

  .sidev2-location-about-mobile {
    display: flex !important;
    background-image: url(/public/img/Location_BG_1.svg) !important;
    color: transparent !important;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
  }

  .sidev2-location-about-mobile .about-mobile-sec {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    align-items: center;
    margin-top: 2rem;
    width: 100%;
  }

  .sidev2-location-about-mobile .desc {
    align-items: center;
    align-content: center;
  }

  .sidev2-location-about-mobile .profilescroll {
    color: #fff;
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }

  .button-m {
    border: none;
    color: black;
    font-size: 1.2rem;
    letter-spacing: 1px;
    outline: none;
    cursor: pointer;
  }

  .rounded-button-mobile {
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 100%;
    background-color: #fff;
  }

  .sidev2-location-about-mobile
    .about-mobile-sec
    .desc
    .profilescroll
    .numberdiv
    .num {
    font-family: IBMPlexMono-Regular;
    margin-top: 6%;
    font-size: 1.5rem;
  }

  .sidev2-location-about-mobile
    .about-mobile-sec
    .desc
    .profilescroll
    .numberdiv {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .sidev2-location-about-mobile
    .about-mobile-sec
    .desc
    .profilescroll
    .numberdiv
    img {
    width: 12rem;
    height: 12rem;
    margin-top: -22%;
  }

  .sidev2-location-about-mobile .about-mobile-sec .roundteams {
    align-items: center;
  }

  .sidev2-location-about-mobile .about-mobile-sec .roundteams .team {
    display: flex;
    flex-direction: row;
    gap: 6rem;
  }

  .button-m img {
    width: 1.5rem;
  }

  .button-t img {
    width: 2rem;
  }
  .button-t {
    border: none;
    color: #fff;
    font-size: 1rem;
    font-family: IBMPlexMono-Regular;
    letter-spacing: 1px;
    outline: none;
    cursor: pointer;
  }

  .rounded-button-team {
    width: 170px;
    height: 170px;
    border: 1px solid;
    border-color: #fff;
    border-radius: 100%;
    background-color: transparent;
  }

  .sidev2-location-aboutdownsec {
    display: none !important;
  }

  .sidev2-location-aboutdownsec-mobile {
    display: flex !important;
    background-image: url(/public/img/Location_BG_1.svg) !important;
    color: transparent !important;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent {
    margin: 0 2%;
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    h4 {
    color: #fff;
    font-size: 2.5rem;
    font-family: IBMPlexMono-Regular;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    h5 {
    font-family: DrukWide-Bold-Trial;
    font-size: 2.5rem;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 3px;
    line-height: 1.3;
    position: relative;
    display: inline-block;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent {
    margin: 0 2%;
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    .tagline {
    font-size: 1rem !important;
    margin: 0;
    text-transform: uppercase;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    color: #fff;
  }
  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    .tagline
    span {
    display: block;
    width: 2.5rem;
    height: 0.1rem;
    opacity: 0.5;
    background: #94db16;
    margin-right: 0.5rem;
  }
  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    button {
    background-color: transparent;
    border: unset;
    cursor: pointer;
  }
  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    .arrows {
    display: flex;
    gap: 3rem;
    margin-top: 0rem;
    justify-content: center;
    margin-left: 60%;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    .arrows
    img {
    height: 5rem;
    width: 5rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .content
    .greenimg {
    float: right;
    right: 0;
    width: 20%;
    margin-right: 5%;
    margin-top: -5% !important;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .content
    .clientpara {
    color: #fff;
    font-size: 1.1rem !important;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .tagline {
    font-size: 1.2rem !important;
  }

  .mobilehorline {
    position: relative;
    width: 100%;
  }

  /* start contact after change */

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    h1 {
    font-family: DrukWide-Bold-Trial;
    font-size: 4.3rem;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 4px;
    line-height: 4.5rem;
    position: relative;
    display: inline-block;
    text-align: center;
    text-shadow: 0px 0px 3px;
    margin-left: 1rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .circular {
    width: 8rem;
    height: 8rem;
    border: unset;
    font-weight: 600;
    margin-left: 12rem;
    margin-bottom: 4rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .circular
    .rounded-button-circular {
    width: 9rem !important;
    height: 9rem !important;
  }

  /* End contact after change */
  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    h5 {
    font-family: DrukWide-Bold-Trial;
    font-size: 2.5rem;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 3px;
    line-height: 1.3;
    position: relative;
    display: inline-block;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    h4 {
    color: #fffdfd;
    font-size: 2.5rem;
    font-family: IBMPlexMono-Regular;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .contact
    h2 {
    font-size: 1.5rem;
    font-family: IBMPlexMono-Regular;
    color: #fff;
    margin-left: 0.5rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .contact
    .contactsub {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .contact
    .contactsub
    input {
    font-size: 1rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .contact
    .contactsub
    .exam {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background: none;
    outline: none;
    resize: none;
    border: 0;
    font-family: IBMPlexMono-Regular;
    transition: all 0.3s;
    border-bottom: 2px solid #90d510;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .contact
    .contactsub
    .emailmsg {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background: none;
    outline: none;
    resize: none;
    border: 0;
    font-family: IBMPlexMono-Regular;
    transition: all 0.3s;
    border-bottom: 2px solid #fefbf7;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .submit {
    margin-top: 2rem;
    float: right;
    right: 0;
    margin-left: 19rem !important;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .submit
    .btn {
    font-size: 1.5rem;
    padding: 15px 80px 15px 40px;
    background-color: transparent;
    font-family: Helvetica-Neue-Regular;
    color: #fff;
    border-radius: 50px;
    margin: auto;
    border: 2px solid #fff;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .submit
    .btn
    .ico {
    transition: all 0.5s;
    background-color: transparent;
    color: #e94751;
    width: 60px;
    height: 60px;
    padding: 5px;
    border-radius: 20px;
    position: absolute;
    margin-left: 18px;
    margin-top: -23px;
    font-size: 1.5em;
  }
}

@media (min-width: 760px) and (max-width: 1023px) {
  .sidev2-location-secheader {
    padding: 2rem 5%;
  }

  .sidev2-location-sec {
    padding: 2rem 5%;
  }

  .sidev2-location-secheader .content .Backimg {
    gap: 2rem !important;
  }

  .sidev2-location-about-mobile {
    background-image: url(/public/img/Location_BG_1.svg) !important;
    color: transparent !important;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2rem 5%;
  }

  .sidev2-location-aboutdownsec-mobile {
    background-image: url(/public/img/Location_BG_1.svg) !important;
    color: transparent !important;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2rem 5%;
  }

  .sidev2-location-secheader .content .Backimg .rbackimg {
    height: 2rem;
    width: 2rem;
  }

  .sidev2-location-secheader .content .Backimg span {
    font-size: 1.4rem;
  }

  .sidev2-location-secheader .content h1 {
    font-size: 6rem;
    text-shadow: 0px 0px 3px !important;
  }
  .sidev2-location-secheader .content .Backimg h2 {
    font-size: 6rem;
    text-shadow: 0px 0px 5px !important;
  }

  .secver {
    display: none;
  }

  .sidev2-location-sec .secdiv {
    margin-top: 20%;
  }

  .sidev2-location-sec .secsubdiv {
    display: flex;
    flex-direction: column-reverse;
    margin-left: -0.2%;
    gap: 6rem;
  }

  .sidev2-location-sec .secsubdiv .content {
    margin-left: -1%;
    width: 90% !important;
  }

  .sidev2-location-sec .secsubdiv .content p {
    font-size: 1.8rem !important;
    margin-left: -1% !important;
    line-height: 2.2rem !important;
  }

  .sidev2-location-sec .vertical {
    display: none;
  }

  .sidev2-location-sec .allimage img {
    width: 6.5rem;
  }

  .sidev2-location-shadow-images {
    display: none !important;
  }
  .sidev2-location-london {
    display: flex;
    flex-direction: column;
  }

  .sidev2-location-la {
    display: none !important;
  }

  .sidev2-location-london {
    display: flex;
    flex-direction: column;
    margin-left: 12% !important;
  }

  .sidev2-location-london .london {
    color: #fff;
    font-family: DrukWide-Bold-Trial;
    font-size: 6rem !important;
    text-shadow: 0px 0px 3px;
    letter-spacing: 0.25rem;
    margin-left: -12% !important;
  }

  .sidev2-location-sliding-active {
    display: none !important;
  }
  .sidev2-location-sliding-active-mobile {
    display: flex !important;
    flex-direction: row !important;
  }

  .sidev2-location-Sliding-Images-active {
    width: 100% !important;
    align-items: center;
    float: right;
    right: 0;
    margin-top: -40%;
    margin-right: -30%;
  }

  .shadowbuttons-mobile {
    float: left;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    margin-left: 10%;
    align-items: center;
  }

  .button-s-m {
    border: none;
    color: black;
    font-size: 1.2rem;
    letter-spacing: 1px;
    outline: none;
    cursor: pointer;
  }

  .rounded-button-shadow-mobile {
    width: 6rem;
    height: 6rem;
    border-radius: 100%;
    background-color: #fff;
  }

  .shadowbuttons-mobile button img {
    width: 1.8rem;
    height: 1.8rem;
  }

  .shadowbuttons-mobile span {
    color: #94db16;
    font-family: IBMPlexMono-Regular;
    font-size: 1.5rem;
  }

  .sidev2-location-sec .horizline {
    display: none;
  }

  .sidev2-location-sec .service {
    margin-left: -5% !important;
  }

  .sidev2-location-sec .service h2 {
    font-size: 2rem !important;
  }

  .sidev2-location-sec .service span {
    font-size: 1.8rem !important;
  }

  .sidev2-location-sec .service .view {
    font-size: 1.6rem !important;
    gap: 1rem;
  }

  .view .down {
    width: 30px !important;
    height: 30px !important;
    margin-top: 7% !important;
  }

  .sidev2-location-sec .maindiv .secdiv .card-row .card-row1 {
    display: none !important;
  }

  .sidev2-location-sec .maindiv .secdiv .card-row .card-row1-mobile {
    display: flex !important ;
  }

  .sidev2-location-sec .maindiv .secdiv .card-row .card-row1-mobile .card img {
    width: 45rem;
  }

  .sidev2-location-sec .maindiv .secdiv .card-row .card-row1-mobile .card h4 {
    font-size: 1.2rem;
    color: #fff;
    font-weight: normal;
    letter-spacing: 1px;
  }

  .sidev2-location-sec .maindiv .secdiv .card-row .card-row1-mobile .card span {
    float: right;
    color: #94db16;
    font-family: IBMPlexMono-Regular;
    font-size: 1.2rem;
  }

  .sidev2-location-sec .maindiv .secdiv .card-row .card-row1-mobile .card p {
    font-size: 2.4rem;
    color: white;
    line-height: 0.4rem;
    padding-top: 2rem;
    font-family: DrukWide-Bold-Trial;
    font-weight: normal;
    letter-spacing: 1px;
  }

  .horizline1 {
    display: none !important;
  }

  .sidev2-location-about {
    display: none !important;
  }

  .clienthar1 {
    display: none !important;
  }

  .sidev2-location-about-mobile {
    display: flex !important;
    background-image: url(/public/img/Location_BG_1.svg) !important;
    color: transparent !important;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
  }

  .sidev2-location-about-mobile .about-mobile-sec {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    align-items: center;
    margin-top: 2rem;
    width: 100%;
  }

  .sidev2-location-about-mobile .desc {
    align-items: center;
    align-content: center;
  }

  .sidev2-location-about-mobile .profilescroll {
    color: #fff;
    display: flex;
    flex-direction: row;
    gap: 4rem;
  }

  .button-m img {
    width: 2rem;
  }

  .button-t img {
    width: 2rem;
  }

  .button-m {
    border: none;
    color: black;
    font-size: 1.2rem;
    letter-spacing: 1px;
    outline: none;
    cursor: pointer;
  }

  .rounded-button-mobile {
    width: 6rem;
    height: 6rem;
    border-radius: 100%;
    background-color: #fff;
  }

  .sidev2-location-about-mobile
    .about-mobile-sec
    .desc
    .profilescroll
    .numberdiv
    .num {
    font-family: IBMPlexMono-Regular;
    margin-top: 10%;
    font-size: 1.5rem;
  }

  .sidev2-location-about-mobile
    .about-mobile-sec
    .desc
    .profilescroll
    .numberdiv {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .sidev2-location-about-mobile
    .about-mobile-sec
    .desc
    .profilescroll
    .numberdiv
    img {
    width: 15rem;
    height: 15rem;
    margin-top: -22%;
  }

  .sidev2-location-about-mobile .about-mobile-sec .roundteams {
    align-items: center;
  }

  .sidev2-location-about-mobile .about-mobile-sec .roundteams .team {
    display: flex;
    flex-direction: row;
    gap: 8rem;
  }

  .button-t {
    border: none;
    color: #fff;
    font-size: 1.5rem;
    font-family: IBMPlexMono-Regular;
    letter-spacing: 1px;
    outline: none;
    cursor: pointer;
  }

  .rounded-button-team {
    width: 200px;
    height: 200px;
    border: 1px solid;
    border-color: #fff;
    border-radius: 100%;
    background-color: transparent;
  }

  .sidev2-location-aboutdownsec {
    display: none !important;
  }

  .sidev2-location-aboutdownsec-mobile {
    display: flex !important;
    background-image: url(/public/img/Location_BG_1.svg) !important;
    color: transparent !important;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent {
    margin: 0 2%;
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    h4 {
    color: #fff;
    font-size: 3rem;
    font-family: IBMPlexMono-Regular;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    h5 {
    font-family: DrukWide-Bold-Trial;
    font-size: 3rem;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 3px;
    line-height: 1.3;
    position: relative;
    display: inline-block;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent {
    margin: 0 2%;
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    .tagline {
    font-size: 0.875rem;
    margin: 0;
    text-transform: uppercase;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    color: #fff;
  }
  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    .tagline
    span {
    display: block;
    width: 2.5rem;
    height: 0.1rem;
    opacity: 0.5;
    background: #94db16;
    margin-right: 0.5rem;
  }
  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    button {
    background-color: transparent;
    border: unset;
    cursor: pointer;
  }
  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    .arrows {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 3rem;
    margin-top: 0rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .leftcontent
    .arrows
    img {
    height: 5rem;
    width: 5rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .content
    .greenimg {
    float: right;
    right: 0;
    width: 20%;
    margin-right: 5%;
    margin-top: -7% !important;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .content
    .clientpara {
    color: #fff;
    font-size: 2rem !important;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .tagline {
    font-size: 1.4rem !important;
  }

  .mobilehorline {
    position: relative;
    width: 100%;
    margin-top: 10% !important;
  }

  /* start contact after change */

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    h1 {
    font-family: DrukWide-Bold-Trial;
    font-size: 6.3rem;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 4px;
    line-height: 7rem;
    position: relative;
    display: inline-block;
    text-align: center;
    text-shadow: 0px 0px 3px;
    margin-left: 1rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .circular {
    width: 8rem;
    height: 8rem;
    border: unset;
    margin-left: 19rem;
    margin-bottom: 9rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .circular
    .rounded-button-circular {
    width: 14rem !important;
    height: 14rem !important;
    font-size: 2rem !important;
  }

  /* End contact after change */

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    h5 {
    font-family: DrukWide-Bold-Trial;
    font-size: 3rem;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 3px;
    line-height: 1.3;
    position: relative;
    display: inline-block;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    h4 {
    color: #fffdfd;
    font-size: 3rem;
    font-family: IBMPlexMono-Regular;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .contact
    h2 {
    font-size: 2rem;
    font-family: IBMPlexMono-Regular;
    color: #fff;
    margin-left: 0.5rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .contact
    .contactsub {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .contact
    .contactsub
    input {
    font-size: 1.5rem;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .contact
    .contactsub
    .exam {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background: none;
    outline: none;
    resize: none;
    border: 0;
    font-family: IBMPlexMono-Regular;
    transition: all 0.3s;
    border-bottom: 2px solid #90d510;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .contact
    .contactsub
    .emailmsg {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background: none;
    outline: none;
    resize: none;
    border: 0;
    font-family: IBMPlexMono-Regular;
    transition: all 0.3s;
    border-bottom: 2px solid #fefbf7;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .submit {
    margin: 2rem;
    float: right;
    right: 0;
    margin-left: 33rem !important;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .submit
    .btn {
    font-size: 1.5rem !important;
    padding: 15px 80px 15px 40px;
    background-color: transparent;
    font-family: Helvetica-Neue-Regular;
    color: #fff;
    border-radius: 50px;
    margin: auto;
    border: 2px solid #fff;
  }

  .sidev2-location-aboutdownsec-mobile
    .aboutdown-mobile
    .clientver-mobile
    .abuotsection8-mobile
    .rightcontent
    .submit
    .btn
    .ico {
    transition: all 0.5s;
    background-color: transparent;
    color: #e94751;
    width: 60px;
    height: 60px;
    padding: 5px;
    border-radius: 20px;
    position: absolute;
    margin-left: 18px;
    margin-top: -23px;
    font-size: 1.5em;
  }
}

@media (min-width: 1024px) and (max-width: 1024px) {
  .sidev2-location-sec .maindiv .secdiv .secsubdiv .content {
    margin-left: 0% !important;
  }

  .sidev2-location-sec .maindiv .secdiv .secsubdiv .content p {
    font-size: 1rem !important;
  }

  .sidev2-location-sliding-active {
    margin-top: -80% !important;
  }

  .shadowbuttons {
    margin-right: 2% !important;
    margin-top: -38% !important;
  }

  .sidev2-location-shadowsub-images {
    gap: 1.8rem !important;
  }

  .sidev2-location-sec .allimage {
    gap: 0.8rem !important;
  }

  .sidev2-location-sec .allimage img {
    width: 3.65rem !important;
  }

  .sidev2-location-sec .allimage .thirdimg {
    top: -10px !important;
  }

  .sidev2-location-sec .allimage .fifthimg {
    top: -10px !important;
  }

  .sidev2-location-sec .vertical {
    margin-bottom: 145% !important;
  }

  .sidev2-location-aboutdownsec
    .aboutdown
    .clientver
    .abuotsection8
    .leftcontent
    h5 {
    font-size: 1.3rem !important;
  }

  .sidev2-location-aboutdownsec
    .aboutdown
    .clientver
    .abuotsection8
    .leftcontent
    .clientpara {
    font-size: 1.1rem !important;
  }

  .sidev2-location-aboutdownsec
    .aboutdown
    .clientver
    .abuotsection8
    .rightcontent
    .circular {
    top: 10.5rem !important;
    right: 10rem !important;
  }

  .sidev2-location-aboutdownsec
    .aboutdown
    .clientver
    .abuotsection8
    .rightcontent
    h1 {
    font-size: 2.79rem !important;
    line-height: 1.5 !important;
  }
}

.sidev2-location-sec .maindiv .secdiv .card-row .card-row1-mobile {
  display: none;
}

.sidev2-location-about-mobile {
  display: none;
}

.sidev2-location-aboutdownsec-mobile {
  display: none;
}

/*background: url(../Images/BGWallpaper-1.png) no-repeat;*/
.sidev2-location-sec .maindiv .secdiv .card-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 7rem;
  margin-left: 2%;
}

.sidev2-location-sec .maindiv .secdiv .card-row .card-row1 {
  display: grid;
  grid-template-columns: repeat(autofill, 250px);
  grid-template-rows: auto 380px;
  column-gap: 0.5rem;
  grid-auto-flow: column;
  margin: -0.7%;
  align-items: center;
}

.sidev2-location-sec .maindiv .secdiv .card-row .card-row1 .card {
  padding: 0rem;
  margin: 8px;
  background-color: transparent;
  align-items: center;
}

.sidev2-location-sec .maindiv .secdiv .card-row .card-row1 .card > img {
  border-radius: 2px;
  margin-bottom: 10px;
  height: auto;
  object-fit: cover;
}
.card:hover {
  cursor: pointer;
}

.card .cardpfl-1:nth-of-type(2) {
  display: none;
  opacity: 0;
}

.card:hover .cardpfl-1:nth-of-type(1) {
  display: none;
  opacity: 0;
}

.card:hover .cardpfl-1:nth-of-type(2) {
  display: block;
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.card .cardpfl-2:nth-of-type(2) {
  display: none;
}

.card:hover .cardpfl-2:nth-of-type(1) {
  display: none;
}

.card:hover .cardpfl-2:nth-of-type(2) {
  display: block;
}

.card .cardpfl-3:nth-of-type(2) {
  display: none;
}

.card:hover .cardpfl-3:nth-of-type(1) {
  display: none;
}

.card:hover .cardpfl-3:nth-of-type(2) {
  display: block;
}

.card .cardpfl-4:nth-of-type(2) {
  display: none;
}

.card:hover .cardpfl-4:nth-of-type(1) {
  display: none;
}

.card:hover .cardpfl-4:nth-of-type(2) {
  display: block;
}

.card .cardpfl-5:nth-of-type(2) {
  display: none;
}

.card:hover .cardpfl-5:nth-of-type(1) {
  display: none;
}

.card:hover .cardpfl-5:nth-of-type(2) {
  display: block;
}

.card .cardpfl-6:nth-of-type(2) {
  display: none;
}

.card:hover .cardpfl-6:nth-of-type(1) {
  display: none;
}

.card:hover .cardpfl-6:nth-of-type(2) {
  display: block;
}

.card .cardpfl-7:nth-of-type(2) {
  display: none;
}

.card:hover .cardpfl-7:nth-of-type(1) {
  display: none;
}

.card:hover .cardpfl-7:nth-of-type(2) {
  display: block;
}

.card .cardpfl-8:nth-of-type(2) {
  display: none;
}

.card:hover .cardpfl-8:nth-of-type(1) {
  display: none;
}

.card:hover .cardpfl-8:nth-of-type(2) {
  display: block;
}

.card .cardpfl-9:nth-of-type(2) {
  display: none;
}

.card:hover .cardpfl-9:nth-of-type(1) {
  display: none;
}

.card:hover .cardpfl-9:nth-of-type(2) {
  display: block;
}

.card .cardpfl-10:nth-of-type(2) {
  display: none;
}

.card:hover .cardpfl-10:nth-of-type(1) {
  display: none;
}

.card:hover .cardpfl-10:nth-of-type(2) {
  display: block;
}

.sidev2-location-sec .maindiv .secdiv .card-row .card-row1 .card h3 {
  color: #94db16;
  font-family: IBMPlexMono-Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 2 rem;
  text-transform: uppercase;
}

.sidev2-location-sec .maindiv .secdiv .card-row .card-row1 .card h4 {
  color: #fff;
  font-family: IBMPlexMono-Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
}

.sidev2-location-sec .maindiv .secdiv .card-row .card-row1 .card p {
  color: white;
  font-family: DrukWide-Bold-Trial;
  margin-bottom: 3rem;
  letter-spacing: 0.1rem;
}

.sidev2-location-about {
  color: white;
  background-image: url(/public/img/Location_BG_1.svg) !important;
  color: transparent !important;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  align-items: center;
  gap: 8rem;
  padding: 2rem 15%;
}

.sidev2-location-about .content {
  margin-top: 4%;
}

.sidev2-location-about .aboutnext {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  top: -0.5rem;
  position: relative;
  width: 25%;
  justify-content: flex-end;
}

.sidev2-location-about .aboutmaindiv .aboutsubdiv .content span {
  font-family: IBMPlexMono-Regular;
  font-size: 0.8rem;
  color: #fff;
}

.sidev2-location-about .twoimages img {
  width: 2rem;
}

.sidev2-location-about .rbackimg {
  padding-left: 10px;
  width: 0.75rem;
}

.sidev2-location-about .rbackimg1 {
  padding-left: 3rem;
  width: 0.75rem;
}

.sidev2-location-about .button {
  border: none;
  color: black;
  font-size: 1.2rem;
  letter-spacing: 1px;
  outline: none;
  cursor: pointer;
  margin-top: 25px !important;
}

.rounded-button-02 {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: #94db16;
  transition: all 0.5s;
  display: none;
  background-repeat: no-repeat;
}

.sidev2-location-about .twoimages1 {
  right: 2.5rem;
  cursor: pointer;
}

.sidev2-location-about .twoimages {
  position: absolute;
  right: 8rem;
}

.sidev2-location-about
  .aboutmaindiv
  .aboutsubdiv
  .aboutnext:hover
  .rounded-button-02 {
  display: block;
  transition: all 0.5s;
}

.sidev2-location-about .prevnext {
  font-size: smaller;
  font-family: IBMPlexMono-Regular;
  line-height: 2rem;
  padding-left: 10px;
  color: #fff;
}

.sidev2-location-about .city {
  font-family: DrukWide-Bold-Trial;
  color: #fff;
  letter-spacing: 0.1rem;
}

.sidev2-location-about .aboutnext {
  margin-top: 4%;
}

.sidev2-location-about .aboutmaindiv {
  display: flex;
  flex-direction: column;
}

.sidev2-location-about .aboutsubdiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5%;
}

.clientver {
  display: flex;
  flex-direction: row;
}

.clienthar1 {
  color: white;
  background-image: url(/public/img/Location_BG_1.svg) !important;
  color: transparent !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.divline {
  position: relative;
  width: 100%;
}

.clienthar2 {
  background-size: cover;
  color: white;
  background-color: black;
}

.sidev2-location-aboutdownsec {
  color: white;
  background-image: url(/public/img/Location_BG_1.svg) !important;
  color: transparent !important;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2rem 15%;
}

.sidev2-location-aboutdownsec .aboutdown .clientver .clientversub1 img {
  position: relative;
  margin-top: -12rem;
  margin-right: 6rem;
  height: 755px;
}

.sidev2-location-aboutdownsec .aboutdown .clientver .clientversub1 {
  position: relative;
  display: flex;
}

.sidev2-location-aboutdownsec .aboutdown .clientver .clientversub2 {
  position: relative;
  display: flex;
}

.sidev2-location-aboutdownsec .aboutdown .clientver .clientversub2 img {
  position: relative;
  margin-right: 0.2rem;
  margin-top: -11.5rem;
  height: 751px;
}

.sidev2-location-aboutdownsec .aboutdown .clientver .abuotsection8 .midcontent {
  position: relative;
  display: flex;
  margin-left: 6%;
}

.sidev2-location-aboutdownsec
  .aboutdown
  .clientver
  .abuotsection8
  .midcontent
  img {
  position: relative;
  margin-top: -21.8rem;
  margin-right: 2rem;
  height: 915px;
}

.sidev2-location-aboutdownsec .aboutdown {
  margin-top: 8%;
  margin-bottom: -3%;
}

.sidev2-location-aboutdownsec .aboutdown .clientver .abuotsection8 {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  margin-bottom: 7%;
}

.sidev2-location-aboutdownsec
  .aboutdown
  .clientver
  .abuotsection8
  .leftcontent {
  width: 40%;
  float: left;
  left: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  margin-left: 8%;
}
.sidev2-location-aboutdownsec
  .aboutdown
  .clientver
  .abuotsection8
  .rightcontent {
  width: 50%;
  display: flex;
  flex-direction: column;
  position: relative;
  float: right;
  margin-top: 2%;
}

.sidev2-location-aboutdownsec
  .aboutdown
  .clientver
  .abuotsection8
  .leftcontent
  h5 {
  font-family: DrukWide-Bold-Trial;
  font-size: 2.3rem;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 3px;
  line-height: 1.2;
  position: relative;
  display: inline-block;
}
/*@media screen and (max-width:768px) {
  .abuotsection8 .leftcontent h5{
      font-size: 5vw;
  }
} */
.sidev2-location-aboutdownsec .aboutdown .clientver .abuotsection8 p {
  color: #fffdfd;
  line-height: 1.8rem;
  font-size: 1.2rem;
  font-family: IBMPlexMono-Regular;
}
.sidev2-location-aboutdownsec
  .aboutdown
  .clientver
  .abuotsection8
  .leftcontent
  .content {
  margin-top: 3rem;
  position: relative;
}
.sidev2-location-aboutdownsec
  .aboutdown
  .clientver
  .abuotsection8
  .leftcontent
  .content::before {
  content: '';

  background-position: 0 -24px;
  background-repeat: no-repeat;
  display: block;
  width: 7rem;
  height: 3rem;
  position: absolute;
  top: -0.7rem;
  left: -1rem;
}

.sidev2-location-aboutdownsec
  .aboutdown
  .clientver
  .abuotsection8
  .leftcontent
  .clientpara {
  font-size: 1.15rem;
  font-family: IBMPlexMono-Regular;
}

.sidev2-location-aboutdownsec
  .aboutdown
  .clientver
  .abuotsection8
  .leftcontent
  .tagline {
  font-size: 0.875rem;
  margin: 0;
  text-transform: uppercase;
  margin-top: 2rem;
  display: flex;
  align-items: center;
}
.sidev2-location-aboutdownsec
  .aboutdown
  .clientver
  .abuotsection8
  .leftcontent
  .tagline
  span {
  display: block;
  width: 2.5rem;
  height: 0.1rem;
  opacity: 0.5;
  background: #94db16;
  margin-right: 0.5rem;
}
.sidev2-location-aboutdownsec
  .aboutdown
  .clientver
  .abuotsection8
  .leftcontent
  button {
  background-color: transparent;
  border: unset;
  cursor: pointer;
}
.sidev2-location-aboutdownsec
  .aboutdown
  .clientver
  .abuotsection8
  .leftcontent
  .arrows {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 2rem;
  margin-top: 0rem;
  margin-bottom: 2rem;
}

.greenimg {
  position: absolute;
  margin-top: -6%;
  margin-left: -6%;
  width: 7rem;
}

.sidev2-location-aboutdownsec
  .aboutdown
  .clientver
  .abuotsection8
  .rightcontent
  h1 {
  font-family: DrukWide-Bold-Trial;
  font-size: 4.5rem;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 4px;
  line-height: 6rem;
  position: relative;
  display: inline-block;
  text-align: center;
}
.sidev2-location-aboutdownsec
  .aboutdown
  .clientver
  .abuotsection8
  .rightcontent
  .circular {
  width: 8rem;
  height: 8rem;
  border: unset;
  font-weight: 600;
  position: absolute;
  top: 15rem;
  right: 18rem;
}

/*
.sidev2-location-aboutdownsec .aboutdown .clientver  .abuotsection8 .rightcontent .circular p{
    color: black;
  
} */

.circularbutton {
  border: none;
  color: black;
  font-weight: bold;
  font-size: 1.4rem;
  letter-spacing: 1px;
  outline: none;
  cursor: pointer;
  margin-bottom: 0px;
  font-family: IBMPlexMono-Regular;
}

.overlaybutton {
  align-items: center;
  margin-top: -15%;
  margin-left: 1%;
}

.rounded-button-circular {
  width: 9rem;
  height: 9rem;
  border-radius: 100%;
  background-color: #94db16;
}
